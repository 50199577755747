Unit Gadget.Enumerations;

Interface

{$SCOPEDENUMS ON}
{$IFNDEF PAS2JS}

Uses
  System.SysUtils,
  Aurelius.Mapping.Attributes;
{$ENDIF}

Type

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'New,SessionContinued,RetrievedByUser,RetrievedBySession')]
{$ENDIF}
  TSessionKind = (New, SessionContinued, RetrievedByUser, RetrievedBySession);


{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'New,Saved,Completed,Deleted')]
{$ENDIF}
  TBasketStatus = (New, Saved, Completed, Deleted);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,MobilePhone,Laptop,Tablet,VRHeadset,Watch,GamesConsole')]
{$ENDIF}
  TGadgetType = (None, MobilePhone, Laptop, Tablet, VRHeadset, Watch, GamesConsole);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Available,NotAvailable')]
{$ENDIF}
  TLossCoverStatus = (Available, NotAvailable);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Unknown,Deleted,NotActive,New,Saved,Active,Cancelled,Completed')]
{$ENDIF}
  TPolicyStatus = (Unknown, Deleted, NotActive, New, Saved, Active, Cancelled, Completed);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'MonthlyCover,AnnualCover')]
{$ENDIF}
  TPremiumKind = (MonthlyCover, AnnualCover);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,ThirdParty,Customer')]
{$ENDIF}
  TAffiliateKind = (None, ThirdParty, Customer);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,Discount')]
{$ENDIF}
  TBrandOfferKind = (None, Discount);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,Active,Expired,NotExist')]
{$ENDIF}
  TOfferStatus = (None, Active, Expired, NotExist);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Active,Suspended,Deleted')]
{$ENDIF}
  TBasicStatus = (Active, Suspended, Deleted);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'System,Pending,Inactive,Active,Suspended,Deleted,NotExists,Deceased')]
{$ENDIF}
  TUserStatus = (System, Pending, Inactive, Active, Suspended, Deleted, NotExists, Deceased);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Customer,Admin')]
{$ENDIF}
  TUserCategory = (Customer, Admin);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'App,Email,SMS')]
{$ENDIF}
  TMFAPreference = (App, Email, SMS);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,Contact,Primary,Secondary')]
{$ENDIF}
  TCustomerType = (None, Contact, Primary, Secondary);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'PerHour,PerDay,PerWeek,PerMonth,PerYear')]
{$ENDIF}
  TTimePeriod = (PerHour, PerDay, PerWeek, PerMonth, PerYear);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'None,PayPal,Revolut,Stripe,DirectDebit,BACS,Cheque')]
{$ENDIF}
  TPaymentProvider = (None, PayPal, Revolut, Stripe, DirectDebit, BACS, Cheque);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'New,Success,Failed,Cancelled')]
{$ENDIF}
  TPaymentState = (New, Success, Failed, Cancelled);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Payment,Refund')]
{$ENDIF}
  TPaymentType = (Payment, Refund);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Other,Deposit,Balance')]
{$ENDIF}
  TPaymentLevel = (Other, Deposit, Balance);

{$IFNDEF PAS2JS}
  [Enumeration(TEnumMappingType.emString, 'Premium,Other')]
{$ENDIF}
  TCostItemType = (Premium, Other);

Const
  Annual_Premium_Multiplier = 10;
  Device_Age_Validity = 36;

  Yes_No: Array [boolean] Of String = ('No', 'Yes');
  Basket_Status: array [TBasketStatus] of string = ('New', 'Saved', 'Completed', 'Deleted');

  Policy_Status: Array [TPolicyStatus] of string = ('Unknown', 'Deleted', 'NotActive', 'New', 'Saved', 'Active', 'Cancelled',
    'Completed');
  Gadget_Types: Array [TGadgetType] of string = ('None', 'Mobile phone', 'Laptop', 'Tablet', 'VR headset', 'Watch',
    'Games console');

  Gadget_Type: Array [TGadgetType] of string = ('None','MobilePhone','Laptop','Tablet','VRHeadset','Watch','GamesConsole');

  Gadget_Types_Loss_Excluded = [TGadgetType.None, TGadgetType.Laptop];
  Premium_Kind_Descriptor: array [TPremiumKind] of string = ('a month', 'a year');

  Cost_Item_Type: Array [TCostItemType] Of String = ('Premium', 'Other');
  Payment_Provider: Array [TPaymentProvider] Of String = ('None', 'PayPal', 'Revolut', 'Stripe', 'DirectDebit', 'BACS',
    'Cheque');
  Payment_State: Array [TPaymentState] Of String = ('New', 'Success', 'Failed', 'Cancelled');
  Payment_Type: Array [TPaymentType] Of String = ('Payment', 'Refund');
  Payment_Level: Array [TPaymentLevel] Of String = ('Other', 'Deposit', 'Balance');
  Payment_Level_Description: Array [TPaymentLevel] Of String = ('Payment', 'Deposit Payment', 'Balance Payment');

  { TODO : Really needs it's own file }
  //LookUp Consts
  AGREE_TERMS_TEXT = 'AGREE-TERMS-TEXT';
  MISSING_SERIALS = 'MISSING-SERIALS';
  DEVICE_TOO_OLD = 'DEVICE-TOO-OLD';

Implementation

{$SCOPEDENUMS OFF}

End.
