Unit SelectPage;

Interface

Uses
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  WebForm.Core,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Gadget.Web.Consts,
  WEBLib.ExtCtrls,
  WEBLib.DBCtrls,
  Data.DB,
  WEBLib.DB,
  BaseSitePage, WEBLib.WebCtrls;

Type
  TSelectForm = Class(TCoreWebForm)
    SelectType: TComboBox;
    SelectMake: TComboBox;
    AddMyGadget: TButton;
    SelectCancel: TLabel;
    SelectModel: TLookupComboBox;
    Procedure AddMyGadgetClick(Sender: TObject);
    procedure SelectCancelClick(Sender: TObject);
    Procedure SelectMakeChange(Sender: TObject);
    Procedure SelectTypeChange(Sender: TObject);
  Private
    FInitialDisplay: Boolean;
    FCurrentSelection: TSelectData;
    FSelectedPremium: Integer;
    Procedure InitJS;
    { Private declarations }
    Procedure LoadGadgetTypes;
    [async]
    Procedure LoadGadgetMakes; async;
    [async]
    Procedure LoadGadgetModels; async;
    [async]
    Procedure LoadThisPolicy; async;
  Protected
    Function IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean; Override;

  Public
    { Public declarations }

    [async]
    Procedure InitForm; async; Override;
    Property InitialDisplay: Boolean Write FInitialDisplay;
    Property SelectedPremium: Integer read FSelectedPremium;
  protected procedure LoadDFMValues; override; End;

Var
  SelectForm: TSelectForm;

Implementation

{$R *.dfm}

Uses
  Gadget.Enumerations,
  MainDataModule,
  SMX.Web.Document.Utils,
  Gadget.ReturnTypes,
  GadgetPage;

Procedure TSelectForm.AddMyGadgetClick(Sender: TObject);
Begin
  if SelectModel.ItemIndex < 1 then
  begin
    DisplayMessage('Please select your gadget');
    Exit;
  end;

  LoadThisPolicy;
End;

{ TSelectForm }

Procedure TSelectForm.InitForm;
Begin
  Inherited;
  SelectCancel.Visible := MainData.PolicyCount > 0;
  LoadGadgetTypes;
  InitJS;
End;

procedure TSelectForm.InitJS;
begin
  {$IFDEF PAS2JS}
  asm
    (function() {
      let gridCreated = false;

      function createGrid(selectElement) {
        // Inject CSS for the grid dynamically
        const style = document.createElement('style');
        style.innerHTML = `
          .custom-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;
            margin: 25px;
          }
          .grid-item {
            background-color: #f1f1f1;
            padding: 20px;
            border: 1px solid #ddd;
            text-align: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: background-color 0.3s ease;
            border-radius: 8px;
          }
          .grid-item:hover {
            background-color: #ddd;
          }
          .grid-item.selected {
            background-color: purple;
            color: white;
            border-color: purple;
          }
          #SelectType {
            display: none;
          }
        `;
        document.head.appendChild(style);

        const gridContainer = document.createElement('div');
        gridContainer.classList.add('custom-grid');

        const iconMap = {
          'Mobile phone': 'fa-light fa-mobile',
          'Laptop': 'fa-light fa-laptop',
          'Tablet': 'fa-light fa-tablet-alt',
          'VR headset': 'fa-light fa-head-vr',
          'Watch': 'fa-light fa-watch',
          'Games console': 'fa-light fa-gamepad'
        };

        Array.from(selectElement.options).forEach((option, index) => {
          if (index === 0) return;

          const gridItem = document.createElement('div');
          gridItem.classList.add('grid-item');
          gridItem.setAttribute('data-value', option.value);
          gridItem.textContent = option.textContent;

          const icon = document.createElement('i');
          icon.className = iconMap[option.value] || '';
          gridItem.prepend(icon);

          gridItem.addEventListener('click', function() {
            document.querySelectorAll('.custom-grid .grid-item').forEach(item => {
              item.classList.remove('selected');
            });
            gridItem.classList.add('selected');
            selectElement.value = this.getAttribute('data-value');
            const event = new Event('change', { bubbles: true });
            selectElement.dispatchEvent(event);
          });

          gridContainer.appendChild(gridItem);
        });

        selectElement.insertAdjacentElement('beforebegin', gridContainer);
        updateGridFromSelect(selectElement);
      }

      function updateGridFromSelect(selectElement) {
        const selectedValue = selectElement.value;
        document.querySelectorAll('.custom-grid .grid-item').forEach(item => {
          if (item.getAttribute('data-value') === selectedValue) {
            item.classList.add('selected');
          } else {
            item.classList.remove('selected');
          }
        });
      }

      const selectElement = document.querySelector('#SelectType');
      if (!selectElement) {
        console.warn('Select element not found!');
        return;
      }

      if (!gridCreated) {
        createGrid(selectElement);
        gridCreated = true;

        selectElement.addEventListener('change', function() {
          updateGridFromSelect(selectElement);
        });
      }
    })();

  end;
  {$ENDIF}
end;

Function TSelectForm.IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean;
Var
  ControlValid: Boolean;
Begin
  Result := True;

  ControlValid := SelectType.ItemIndex > 0;
  Result := Result And ControlValid;
  HighlightControl(SelectType.ElementID, AElementId, HighlightControls, ControlValid, True);

  If Not Result Then
    Exit;

  ControlValid := SelectMake.Enabled And (SelectMake.ItemIndex > 0);
  Result := Result And ControlValid;
  HighlightControl(SelectMake.ElementID, AElementId, HighlightControls, ControlValid, True);
  If Not Result Then
    Exit;

  ControlValid := SelectModel.Enabled And (SelectModel.ItemIndex > 0);
  Result := Result And ControlValid;
  HighlightControl(SelectModel.ElementID, AElementId, HighlightControls, ControlValid, True);

End;

Procedure TSelectForm.LoadGadgetMakes;
Var
  lMakes: TList<String>;
  S: String;
Begin
  If FCurrentSelection.Gadget = TGadgetType.None Then
    Exit;

  SelectMake.BeginUpdate;
  Try

    SelectMake.Items.Clear;
    lMakes := Await(TList<String>, MainData.GetMakes(FCurrentSelection.Gadget));
    Try
      SelectMake.Items.Add('Please select your brand...');
      For S In lMakes Do
        SelectMake.Items.Add(S);
    Finally
      lMakes.Free;
    End;

  Finally
    SelectMake.Enabled := SelectMake.Items.Count > 0;
    If SelectMake.Enabled Then
      SelectMake.ItemIndex := 0;
    SelectMake.EndUpdate;
  End;
End;

Procedure TSelectForm.LoadGadgetModels;
Var
  lModels: TList<TIdAndName>;
  S: TIdAndName;
Begin
  If FCurrentSelection.Make = '' Then
    Exit;

  SelectModel.LookupValues.BeginUpdate;
  Try

    SelectModel.LookupValues.Clear;
    lModels := Await(TList<TIdAndName>, MainData.GetModels(FCurrentSelection.Gadget, FCurrentSelection.Make));
    Try
      SelectModel.LookupValues.AddPair('0', 'Select your model...');

      For S In lModels Do
        SelectModel.LookupValues.AddPair(S.Id.ToString, S.Name);

    Finally
      lModels.Free;
    End;

  Finally
    SelectModel.Enabled := SelectModel.LookupValues.Count > 0;
    If SelectModel.Enabled Then
      SelectModel.Value := '0';
    SelectModel.LookupValues.EndUpdate;
  End;

End;

Procedure TSelectForm.LoadGadgetTypes;
Var
  lType: TGadgetType;
  I: TGadgetType;
Begin
  SelectType.Items.Add('Please select you gadget...');
  For I := Succ(Low(TGadgetType)) To High(TGadgetType) Do
    SelectType.Items.Add(Gadget_Types[I]);
  SelectType.ItemIndex := 0;
End;

Procedure TSelectForm.LoadThisPolicy;
Begin
  FSelectedPremium := SelectModel.Value.ToInteger;
  ModalResult := mrOK;
End;

procedure TSelectForm.SelectCancelClick(Sender: TObject);
begin
  ModalResult := mrCancel;
end;

Procedure TSelectForm.SelectMakeChange(Sender: TObject);
Begin
  If SelectMake.ItemIndex = 0 Then
    SelectMake.ItemIndex := SelectMake.Items.IndexOf(FCurrentSelection.Make);

  FCurrentSelection.Make := SelectMake.Text;
  LoadGadgetModels;
End;

Procedure TSelectForm.SelectTypeChange(Sender: TObject);
Begin
  If SelectType.ItemIndex = 0 Then
    SelectType.ItemIndex := Integer(FCurrentSelection.Gadget);

  FCurrentSelection.Gadget := TGadgetType(SelectType.ItemIndex);
  LoadGadgetMakes;
End;

procedure TSelectForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SelectCancel := TLabel.Create('SelectCancel');
  SelectType := TComboBox.Create('SelectType');
  SelectMake := TComboBox.Create('SelectMake');
  AddMyGadget := TButton.Create('AddMyGadget');
  SelectModel := TLookupComboBox.Create('SelectModel');

  SelectCancel.BeforeLoadDFMValues;
  SelectType.BeforeLoadDFMValues;
  SelectMake.BeforeLoadDFMValues;
  AddMyGadget.BeforeLoadDFMValues;
  SelectModel.BeforeLoadDFMValues;
  try
    Width := 455;
    Height := 324;
    SelectCancel.SetParentComponent(Self);
    SelectCancel.Name := 'SelectCancel';
    SelectCancel.Left := 184;
    SelectCancel.Top := 272;
    SelectCancel.Width := 36;
    SelectCancel.Height := 15;
    SelectCancel.Cursor := crHandPoint;
    SelectCancel.Caption := 'Cancel';
    SelectCancel.ElementFont := efCSS;
    SelectCancel.ElementPosition := epIgnore;
    SelectCancel.HeightStyle := ssAuto;
    SelectCancel.HeightPercent := 100.000000000000000000;
    SelectCancel.HTMLType := tSPAN;
    SelectCancel.WidthStyle := ssAuto;
    SelectCancel.WidthPercent := 100.000000000000000000;
    SetEvent(SelectCancel, Self, 'OnClick', 'SelectCancelClick');
    SelectType.SetParentComponent(Self);
    SelectType.Name := 'SelectType';
    SelectType.Left := 120;
    SelectType.Top := 96;
    SelectType.Width := 241;
    SelectType.Height := 23;
    SelectType.ElementClassName := 'form-select';
    SelectType.ElementFont := efCSS;
    SelectType.HeightStyle := ssAuto;
    SelectType.HeightPercent := 100.000000000000000000;
    SelectType.WidthPercent := 100.000000000000000000;
    SetEvent(SelectType, Self, 'OnChange', 'SelectTypeChange');
    SelectType.ItemIndex := -1;
    SelectMake.SetParentComponent(Self);
    SelectMake.Name := 'SelectMake';
    SelectMake.Left := 120;
    SelectMake.Top := 136;
    SelectMake.Width := 241;
    SelectMake.Height := 23;
    SelectMake.ElementClassName := 'form-select';
    SelectMake.ElementFont := efCSS;
    SelectMake.Enabled := False;
    SelectMake.HeightStyle := ssAuto;
    SelectMake.HeightPercent := 100.000000000000000000;
    SelectMake.WidthPercent := 100.000000000000000000;
    SetEvent(SelectMake, Self, 'OnChange', 'SelectMakeChange');
    SelectMake.ItemIndex := -1;
    AddMyGadget.SetParentComponent(Self);
    AddMyGadget.Name := 'AddMyGadget';
    AddMyGadget.Left := 169;
    AddMyGadget.Top := 223;
    AddMyGadget.Width := 128;
    AddMyGadget.Height := 25;
    AddMyGadget.Caption := 'Add My Gadget';
    AddMyGadget.ChildOrder := 5;
    AddMyGadget.ElementClassName := 'btn btn-light';
    AddMyGadget.ElementFont := efCSS;
    AddMyGadget.ElementPosition := epIgnore;
    AddMyGadget.HeightStyle := ssAuto;
    AddMyGadget.HeightPercent := 100.000000000000000000;
    AddMyGadget.WidthStyle := ssAuto;
    AddMyGadget.WidthPercent := 100.000000000000000000;
    SetEvent(AddMyGadget, Self, 'OnClick', 'AddMyGadgetClick');
    SelectModel.SetParentComponent(Self);
    SelectModel.Name := 'SelectModel';
    SelectModel.Left := 120;
    SelectModel.Top := 184;
    SelectModel.Width := 241;
    SelectModel.Height := 22;
    SelectModel.ElementClassName := 'form-select';
    SelectModel.ElementFont := efCSS;
    SelectModel.Enabled := False;
    SelectModel.HeightPercent := 100.000000000000000000;
    SelectModel.WidthPercent := 100.000000000000000000;
    SelectModel.ItemIndex := -1;
  finally
    SelectCancel.AfterLoadDFMValues;
    SelectType.AfterLoadDFMValues;
    SelectMake.AfterLoadDFMValues;
    AddMyGadget.AfterLoadDFMValues;
    SelectModel.AfterLoadDFMValues;
  end;
end;

End.
