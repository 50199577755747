{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit Grid.Plugins;

interface

uses
  SysUtils,
  Math,
  DB,
  System.Generics.Collections,
  Web,
  JS,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  WEBLib.Grids,
  WEBLib.Lists,
  XData.Web.Dataset,
  Grid.Settings,
  Dataset.Plugins,
  Paginator.Plugins,
  smx.webcore.types;

type

  TDBTableControl = TDBTableControl;

  TListControl = TListControl;

  TLabel = TLabel;

  TGridPlugin = class
  const
    SORTING_CLASS = 'sorting';
    SORTING_ASC_CLASS = 'sorting_asc';
    SORTING_DESC_CLASS = 'sorting_desc';
  private
    FGrid: TDBTableControl;
    FDatasetPlugin: TDatasetPlugin;
    FPaginatorPlugin: TPaginatorPlugin;
    FPaginationInfo: TLabel;
    FSortedItems: TSortedItems;
    FSortingChanged: Boolean;
    FFilterText: string;
    FSearchFields: TSearchFields;
    FPreparedSearch: string;
    FSpecificSearch: Boolean;
    FInitialSort: TSortPair;
//    FFirstLoad: Boolean;
    function IsOrderableColumn(ACol: integer): Boolean;
    function HasColumnWithOrder: Boolean;
    function GetFieldByColumn(ACol: integer): TField;
    function GetSortedItems: TSortedItems;
    function CalculateSortedItems: TSortedItems;
    function GetPageSize: integer;
    function GetPageNumber: integer;
    function GetFilterText: string;
    function CreateSettings: TGridSettings;
  private
    procedure InitGrid;
    procedure ConfigureHeaderElement(ACol: integer);
    procedure DatasetLoadCallback(ADatasetPlugin: TDatasetPlugin);
    procedure PaginatorItemClickCallback(APaginatorPlugin: TPaginatorPlugin);
  public
    constructor Create(AGrid: TDBTableControl; ADataset: TXDataWebDataSet; APaginator: TListControl;
      APaginationInfo: TLabel; AInitialSort: TSortPair);
    destructor Destroy; override;
    procedure Load;
    procedure NextPage;
    procedure PreviousPage;
    procedure SetPageSize(APageSize: integer; AutoReload: Boolean = False);
    procedure SetFilterText(AFilterText: string; AutoReload: Boolean = False);
    procedure SetDefinedSearch(const AFilterText: string; const ASearchFields: TSearchFields;
      const AutoReload: Boolean = True);
    procedure SetPreparedFilter(const AFilter: string; const AStartPage: integer = 1; const AutoLoad: Boolean = True);
    function IsLastPage: Boolean;
    property SortedItems: TSortedItems read GetSortedItems;
    property FilterText: string read GetFilterText;
    property PageSize: integer read GetPageSize;
    property PageNumber: integer read GetPageNumber;
  end;

implementation

{ TGridPlugin }

constructor TGridPlugin.Create(AGrid: TDBTableControl; ADataset: TXDataWebDataSet; APaginator: TListControl;
  APaginationInfo: TLabel; AInitialSort: TSortPair);
begin
  FGrid := AGrid;
//  FFirstLoad := True;
  FDatasetPlugin := TDatasetPlugin.Create(ADataset, @DatasetLoadCallback);
  FPaginatorPlugin := TPaginatorPlugin.Create(APaginator, @PaginatorItemClickCallback);
  FPaginationInfo := APaginationInfo;
  FInitialSort := AInitialSort;
  SetFilterText('');
  InitGrid;
  FSortingChanged := FInitialSort.Key > -1;
  FSortedItems := CalculateSortedItems;
end;

function TGridPlugin.CreateSettings: TGridSettings;
begin
  if FPreparedSearch <> '' then
    Result := TGridSettings.Create(FGrid, SortedItems, FilterText, [], FPreparedSearch)
  else if FSpecificSearch then
    Result := TGridSettings.Create(FGrid, SortedItems, FilterText, FSearchFields, '')
  else
    Result := TGridSettings.Create(FGrid, SortedItems, FilterText, [], '');
end;

procedure TGridPlugin.DatasetLoadCallback(ADatasetPlugin: TDatasetPlugin);
var
  PageCount: integer;
  FirstRecordOfPage, LastRecordOfPage: integer;
begin
  PageCount := ADatasetPlugin.ServerRecordCount div ADatasetPlugin.PageSize;
  if (ADatasetPlugin.ServerRecordCount mod ADatasetPlugin.PageSize) > 0 then
    Inc(PageCount);
  FPaginatorPlugin.Init(ADatasetPlugin.PageNumber, PageCount);

  FirstRecordOfPage := Min(((ADatasetPlugin.PageNumber - 1) * ADatasetPlugin.PageSize) + 1,
    ADatasetPlugin.ServerRecordCount);

  LastRecordOfPage := Min(ADatasetPlugin.PageNumber * ADatasetPlugin.PageSize, ADatasetPlugin.ServerRecordCount);

  if ADatasetPlugin.ServerRecordCount = 0 then
    FPaginationInfo.Caption := 'No records to display'
  else
    FPaginationInfo.Caption := Format('Showing %s to %s of %s entries', [FormatFloat('#,##0', FirstRecordOfPage),
      FormatFloat('#,##0', LastRecordOfPage), FormatFloat('#,##0', ADatasetPlugin.ServerRecordCount)]);
end;

destructor TGridPlugin.Destroy;
begin
  FPaginatorPlugin.Free;
  FDatasetPlugin.Free;
  inherited;
end;

function TGridPlugin.GetFieldByColumn(ACol: integer): TField;
var
  DataField: string;
begin
  DataField := FGrid.Columns[ACol].DataField;
  if (DataField = '') then
    Exit(nil);
  if FGrid.DataSource = nil then
    Exit(nil);
  if FGrid.DataSource.Dataset = nil then
    Exit(nil);
  Result := FGrid.DataSource.Dataset.FieldByName(DataField);
end;

function TGridPlugin.GetFilterText: string;
begin
  Result := FFilterText;
end;

function TGridPlugin.GetPageNumber: integer;
begin
  Result := FDatasetPlugin.PageNumber;
end;

function TGridPlugin.GetPageSize: integer;
begin
  Result := FDatasetPlugin.PageSize;
end;

function TGridPlugin.GetSortedItems: TSortedItems;
begin
  if FSortingChanged then
  begin
    FSortedItems := CalculateSortedItems;
    FSortingChanged := False;
  end;
  Result := FSortedItems;
end;

function TGridPlugin.HasColumnWithOrder: Boolean;
var
  I: integer;
  CellElement: TJSElement;
begin
  Result := False;
  for I := 0 to FGrid.Columns.Count - 1 do
  begin
    if not IsOrderableColumn(I) then
      Continue;

    CellElement := FGrid.CellElements[I, 0];

    Result := CellElement.classList.contains(SORTING_ASC_CLASS) or CellElement.classList.contains(SORTING_DESC_CLASS);

    if Result then
      Break;
  end;
end;

procedure TGridPlugin.InitGrid;
var
  I: integer;
begin
  for I := 0 to FGrid.Columns.Count - 1 do
    ConfigureHeaderElement(I);
end;

function TGridPlugin.IsLastPage: Boolean;
begin
  Result := FDatasetPlugin.IsLastPage;
end;

function TGridPlugin.IsOrderableColumn(ACol: integer): Boolean;
var
  DataField: string;
  Field: TField;
begin
  DataField := FGrid.Columns[ACol].DataField;
  if (DataField = '') then
    Exit(False);
  if FGrid.DataSource = nil then
    Exit(False);
  if FGrid.DataSource.Dataset = nil then
    Exit(False);
  Field := FGrid.DataSource.Dataset.FieldByName(DataField);
  if Field = nil then
    Exit(False);
  if Field.FieldKind <> fkData then
    Exit(False);
  Result := True;
end;

procedure TGridPlugin.Load;
var
  Settings: TGridSettings;
begin
  Settings := CreateSettings;
  try
    FDatasetPlugin.LoadDataset(Settings);
  finally
    Settings.Free;
  end;
//  FFirstLoad := False;
end;

procedure TGridPlugin.NextPage;
begin
  if FDatasetPlugin.NextPage then
    Load;
end;

procedure TGridPlugin.PaginatorItemClickCallback(APaginatorPlugin: TPaginatorPlugin);
begin
  if APaginatorPlugin.ActivePage <> FDatasetPlugin.PageNumber then
  begin
    FDatasetPlugin.PageNumber := APaginatorPlugin.ActivePage;
    Load;
  end;
end;

procedure TGridPlugin.PreviousPage;
begin
  if FDatasetPlugin.PreviousPage then
    Load;
end;

procedure TGridPlugin.SetDefinedSearch(const AFilterText: string; const ASearchFields: TSearchFields;
  const AutoReload: Boolean = True);
begin
  FSpecificSearch := True;
  FPreparedSearch := '';
  FFilterText := AFilterText;
  FSearchFields := ASearchFields;
  if FDatasetPlugin.PageSize > 0 then
    FDatasetPlugin.PageNumber := 1;
  if AutoReload then
    Load;

end;

procedure TGridPlugin.SetFilterText(AFilterText: string; AutoReload: Boolean);
begin
  FSpecificSearch := False;
  FPreparedSearch := '';
  FFilterText := AFilterText;
  if FDatasetPlugin.PageSize > 0 then
    FDatasetPlugin.PageNumber := 1;
  if AutoReload then
    Load;
end;

procedure TGridPlugin.ConfigureHeaderElement(ACol: integer);
var
  Element: TJSHTMLElement;

  function HeaderClick(Event: TJSMouseEvent): Boolean;
  var
    classList: TJSDOMTokenList;
    CurrentOrderClass, NewOrderClass: string;
    I: integer;
  begin
    NewOrderClass := '';
    classList := FGrid.CellElements[ACol, 0].classList;
    if classList.contains(SORTING_ASC_CLASS) then
    begin
      CurrentOrderClass := SORTING_ASC_CLASS;
      NewOrderClass := SORTING_DESC_CLASS;
    end
    else if classList.contains(SORTING_DESC_CLASS) then
    begin
      CurrentOrderClass := SORTING_DESC_CLASS;
      NewOrderClass := SORTING_ASC_CLASS;
    end
    else if classList.contains(SORTING_CLASS) then
    begin
      CurrentOrderClass := SORTING_CLASS;
      NewOrderClass := SORTING_ASC_CLASS;
    end;

    if NewOrderClass <> '' then
    begin
      classList.replace(CurrentOrderClass, NewOrderClass);

      for I := 0 to FGrid.Columns.Count - 1 do
      begin
        if I = ACol then
          Continue;
        if FGrid.CellElements[I, 0].classList.contains(SORTING_ASC_CLASS) then
          FGrid.CellElements[I, 0].classList.replace(SORTING_ASC_CLASS, SORTING_CLASS)
        else if FGrid.CellElements[I, 0].classList.contains(SORTING_DESC_CLASS) then
          FGrid.CellElements[I, 0].classList.replace(SORTING_DESC_CLASS, SORTING_CLASS);
      end;

      FSortedItems := CalculateSortedItems;
      FSortingChanged := True;

      Load;
    end;

    Result := True;
  end;

begin

  if IsOrderableColumn(ACol) then
  begin
    Element := FGrid.CellElements[ACol, 0];
    Element.onclick := @HeaderClick;
    Element.style.setProperty('cursor', 'pointer');

    if not HasColumnWithOrder then
    begin
      if (not Element.classList.contains(SORTING_ASC_CLASS)) and (not Element.classList.contains(SORTING_DESC_CLASS))
      then
      begin
        if (ACol = FInitialSort.Key) then
        begin
          case FInitialSort.Value of
            sdAsc:
              Element.classList.add(SORTING_ASC_CLASS);
            sdDesc:
              Element.classList.add(SORTING_DESC_CLASS);
          end;
        end
        else
          Element.classList.add(SORTING_ASC_CLASS);
      end;
    end
    else
    begin
      if not Element.classList.contains(SORTING_CLASS) then
        Element.classList.add(SORTING_CLASS);
    end;
  end;

end;

procedure TGridPlugin.SetPageSize(APageSize: integer; AutoReload: Boolean);
begin
  FDatasetPlugin.PageSize := APageSize;
  if AutoReload then
    Load;
end;

procedure TGridPlugin.SetPreparedFilter(const AFilter: string; const AStartPage: integer; const AutoLoad: Boolean);
begin
  FPreparedSearch := AFilter;
  if FDatasetPlugin.PageSize > 0 then
    FDatasetPlugin.PageNumber := AStartPage;
  if AutoLoad then
    Load;
end;

function TGridPlugin.CalculateSortedItems: TSortedItems;
var
  Field: TField;
  classList: TJSDOMTokenList;
  CellElement: TJSElement;
  I: integer;
  Fields: TJSArray;
  OrderInfo: TOrderInfo;
begin
  Fields := TJSArray.new;
  for I := 0 to FGrid.Columns.Count - 1 do
  begin
    if not IsOrderableColumn(I) then
      Continue;
    Field := GetFieldByColumn(I);
    CellElement := FGrid.CellElements[I, 0];
    classList := CellElement.classList;

    if classList.contains(SORTING_ASC_CLASS) then
    begin
      OrderInfo.FieldName := Field.FieldName;
      OrderInfo.Ascending := True;
      Fields.push(OrderInfo);
    end
    else if classList.contains(SORTING_DESC_CLASS) then
    begin
      OrderInfo.FieldName := Field.FieldName;
      OrderInfo.Ascending := False;
      Fields.push(OrderInfo);
    end;
  end;

  SetLength(Result, Fields.Length);
  for I := 0 to Fields.Length - 1 do
    Result[I] := TOrderInfo(Fields[I]);
end;

end.
