{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit Web.Core.Config;

interface

type
  TAppConfig = class
  private
    FBaseUrl: string;
    FAuthURL: string;
    FSphinxClientId: string;
    FRegisterURL: string;
  public
    constructor Create;
    procedure Load(AResponse: string);
    function CleanSphinxRedirectUri(const APath: string): string;
    property BaseUrl: string read FBaseUrl write FBaseUrl;
    /// <summary>
    /// for Sphinx based Systems this points to the Sphinx Server
    /// </summary>
    property AuthURL: string read FAuthURL write FAuthURL;
    property RegisterURL: string read FRegisterURL write FRegisterURL;
    property SphinxClientId: string read FSphinxClientId write FSphinxClientId;
  end;

function AppConfig: TAppConfig;

implementation

uses
  System.SysUtils,
  JS,
  XData.Web.Connection,
  XData.Web.Request;

var
  FAppConfig: TAppConfig;

function AppConfig: TAppConfig;
begin
  if not Assigned(FAppConfig) then
    FAppConfig := TAppConfig.Create;
  Result := FAppConfig;
end;

{ TAppConfig }

function TAppConfig.CleanSphinxRedirectUri(const APath: string): string;
var
  I: Integer;
begin
  Result := APath;
  I := Result.IndexOf('?');
  if I > 0 then
    Result := Result.Substring(0, I);
  I := Result.IndexOf('#');
  if I > 0 then
    Result := Result.Substring(0, I);

//  Result := Result.ToLower;
end;

constructor TAppConfig.Create;
begin
  FBaseUrl := '';
  FAuthURL := '';
end;

procedure TAppConfig.Load(AResponse: string);
var
  Obj: TJSObject;
  S: String;
begin
  Obj := TJSObject(TJSJSON.parse(AResponse));

  S := JS.toString(Obj['BaseUrl']);
  if S <> '' then
    FBaseUrl := S;

  S := JS.toString(Obj['AuthUrl']);
  if S <> '' then
    FAuthURL := S;

  if FAuthURL = '' then
    FAuthURL := FBaseUrl;

  S := JS.toString(Obj['RegisterUrl']);
  if S <> '' then
    FRegisterURL := S
  else
    FRegisterURL := FBaseUrl;

  S := JS.toString(Obj['SphinxId']);
  if S <> '' then
     FSphinxClientId := S;

end;

end.
