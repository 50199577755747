{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

Unit MainDataModule;

Interface

Uses
  System.SysUtils,
  System.Classes,
  System.Generics.Collections,
  JS,
  Web,
  WEBLib.Modules,
  XData.Web.Client,
  XData.Web.Connection,
  Web.Core.Config,
  Web.Core.Consts,
  Data.DB,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  Gadget.Enumerations,
  Gadget.ReturnTypes,
  Gadget.Web.Consts,
  WEBLib.CDS,
  Sphinx.WebLogin,
  WEBLib.Forms,
  WEBLib.DB,
  DB;

{
  Debug Authority: http://alpha:2024/AlphaLogin
  Demo Site: https://alpha.smxi-azure-001.smxi.com/AlphaLogin
}

{

  Emails sent on BookingDataset.AfterApplyUpdates for Confirmations (Cheque, BACS) and Payments.AfterApplyUpdates for successful payments.

  Emails also sent in BookingDataset.AfterApplyUpdates for Waitlist

  Holiday and RoomAllocations are Updated when the Holiday is Confirmed (Cheque, BACS), Deposit Paid or Fully Paid.

}

Type

  TMainData = Class(TDataModule)
    DataConnection: TXDataWebConnection;
    WebClient: TXDataWebClient;
    CustomerData: TXDataWebDataSet;
    DirectDebit: TXDataWebDataSet;
    DummyLookUp: TClientDataSet;
    DummyLookUpSource: TDataSource;
    PolicyData: TXDataWebDataSet;
    PolicyDataLossOption: TStringField;
    DirectDebitDateAdded: TDateTimeField;
    DirectDebitId: TIntegerField;
    DirectDebitAddedBy: TIntegerField;
    DirectDebitLastUpdatedBy: TIntegerField;
    DirectDebitLastUpdated: TDateTimeField;
    DirectDebitSessionId: TIntegerField;
    DirectDebitAccountName: TStringField;
    DirectDebitAccountNumber: TStringField;
    DirectDebitSortCode: TStringField;
    DirectDebitCustomerId: TIntegerField;
    PolicyDataPremiumPeriodDisplay: TStringField;
    CustomerDataId: TIntegerField;
    CustomerDataDateAdded: TDateTimeField;
    CustomerDataAddedBy: TIntegerField;
    CustomerDataLastUpdatedBy: TIntegerField;
    CustomerDataLastUpdated: TDateTimeField;
    CustomerDataTitle: TStringField;
    CustomerDataForename: TStringField;
    CustomerDataInitials: TStringField;
    CustomerDataSurname: TStringField;
    CustomerDataAddress1: TStringField;
    CustomerDataAddress2: TStringField;
    CustomerDataAddress3: TStringField;
    CustomerDataAddress4: TStringField;
    CustomerDataPostCode: TStringField;
    CustomerDataEmail: TStringField;
    CustomerDataMobile: TStringField;
    CustomerDataHomePhone: TStringField;
    CustomerDataCreatedBy: TStringField;
    CustomerDataNoMarketing: TBooleanField;
    CustomerDataNote: TBlobField;
    CustomerDataAddressValidFrom: TDateTimeField;
    CustomerDataAffiliateId: TIntegerField;
    CustomerDataExportDate: TDateTimeField;
    CustomerDataAffiliateKind: TStringField;
    CustomerDataMarketingOK: TBooleanField;

    PolicyDataId: TIntegerField;
    PolicyDataDateAdded: TDateTimeField;
    PolicyDataAddedBy: TIntegerField;
    PolicyDataLastUpdatedBy: TIntegerField;
    PolicyDataLastUpdated: TDateTimeField;
    PolicyDataPremiumId: TIntegerField;
    PolicyDataPremium: TFloatField;
    PolicyDataGadgetType: TStringField;
    PolicyDataModel: TStringField;
    PolicyDataMake: TStringField;
    PolicyDataInstalledMemory: TStringField;
    PolicyDataSerialNumber: TStringField;
    PolicyDataLossPremium: TFloatField;
    PolicyDataDamageExcess: TFloatField;
    PolicyDataTheftExcess: TFloatField;
    PolicyDataStatus: TStringField;
    PolicyDataPremiumPeriod: TStringField;
    PolicyDataPurchasePrice: TFloatField;
    PolicyDataDiscountPercent: TFloatField;
    PolicyDataNetPremium: TFloatField;
    PolicyDataGrossPremium: TFloatField;
    PolicyDataBasketId: TIntegerField;
    PolicyDataLossCover: TBooleanField;
    PolicyDataSummaryText: TStringField;
    PolicyDataLossCoverText: TStringField;

    BasketData: TXDataWebDataSet;
    BasketDataId: TIntegerField;
    BasketDataDateAdded: TDateTimeField;
    BasketDataAddedBy: TIntegerField;
    BasketDataLastUpdatedBy: TIntegerField;
    BasketDataLastUpdated: TDateTimeField;
    BasketDataGrossPremium: TFloatField;
    BasketDataSessionId: TIntegerField;
    BasketDataQuantityDiscountRate: TFloatField;
    BasketDataStatus: TStringField;
    BasketDataAffiliateKind: TStringField;
    BasketDataAffilliateId: TIntegerField;
    BasketDataNumberOfPolicies: TIntegerField;
    BasketDataDateCompleted: TDateTimeField;
    BasketDataCustomerId: TIntegerField;
    BasketDataLatestSessionId: TIntegerField;
    BasketDataBrandCode: TStringField;
    BasketDataNetPremium: TFloatField;
    BasketDataPremiumPeriod: TStringField;
    BasketDataEndDate: TDateTimeField;
    BasketDataStartDate: TDateTimeField;
    BasketDataLossCover: TBooleanField;
    BasketDataExportDate: TDateTimeField;
    BasketDataAffilliateDiscountRate: TFloatField;
    BasketDataBrandOfferKind: TStringField;
    BasketDataBrandOffer: TFloatField;
    BasketDataTotalDiscountRate: TFloatField;
    DirectDebitBasketId: TIntegerField;
    CardData: TXDataWebDataSet;
    CardDataId: TIntegerField;
    CardDataDateAdded: TDateTimeField;
    CardDataAddedBy: TIntegerField;
    CardDataLastUpdatedBy: TIntegerField;
    CardDataLastUpdated: TDateTimeField;
    CardDataSessionId: TIntegerField;
    CardDataCustomerId: TIntegerField;
    CardDataAccountName: TStringField;
    CardDataBasketId: TIntegerField;
    PolicyDataCustomerId: TIntegerField;
    LossCoverData: TXDataWebDataSet;
    LossCoverDataId: TIntegerField;
    LossCoverDataPremium: TFloatField;
    LossCoverDataStatus: TStringField;
    LossCoverDataGadgetType: TStringField;
    BasketDataAcceptTerms: TBooleanField;
    LookUpData: TXDataWebDataSet;
    LookUpDataId: TIntegerField;
    LookUpDataKey: TStringField;
    LookUpDataValueType: TStringField;
    LookUpDataValue: TStringField;
    CustomerDataStripeId: TStringField;
    BasketDataPaymentId: TStringField;
    CardDataStripeSubscriptionID: TStringField;
    CardDataStripeCustomerID: TStringField;
    CardDataStripeStatus: TStringField;
    PolicyDataDateOfPurchase: TDateField;
    procedure BasketDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure BasketDataAfterOpen(Dataset: TDataSet);
    procedure BasketDataCalcFields(Dataset: TDataSet);
    procedure BasketDataNewRecord(Dataset: TDataSet);
    procedure CardDataNewRecord(Dataset: TDataSet);
    Procedure CustomerDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    Procedure CustomerDataAfterOpen(Dataset: TDataSet);
    Procedure CustomerDatasetEmailSetText(Sender: TField; Const Text: String);
    Procedure CustomerDataNewRecord(Dataset: TDataSet);
    Procedure CustomerDatasetPostcodeSetText(Sender: TField; Const Text: String);

    Procedure DataConnectionError(Error: TXDataWebConnectionError);
    Procedure DataConnectionRequest(Args: TXDataWebConnectionRequest);
    Procedure DirectDebitNewRecord(Dataset: TDataSet);
    procedure PolicyDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
    procedure PolicyDataAfterOpen(Dataset: TDataSet);
    Procedure PolicyDataCalcFields(Dataset: TDataSet);
    procedure PolicyDataDamageExcessGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    Procedure PolicyDataNewRecord(Dataset: TDataSet);
    procedure PolicyDataPurchasePriceGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    procedure PolicyDataTheftExcessGetText(Sender: TField; var Text: string; DisplayText: Boolean);
    Procedure WebClientError(Error: TXDataClientError);
    Procedure WebDataModuleDestroy(Sender: TObject);
    [async]
    Procedure WebDataModuleCreate(Sender: TObject); async;
  Private
    FLastError: string;
    FSessionData: TSessionData;

    FInitDone: Boolean;
    FSessionId: Integer;
    FProcessingState: TProcessingState;
    FStoredProcessingState: TProcessingState;
    FMainPage: TMainAppPage;
    FPreviousMainPage: TMainAppPage;

    FModelList: TList<TIdAndName>;
    FPremiums: TList<TPremium>;

    FPaymentProvider: TPaymentProvider;
    FBankDetails: TBankDetails;
    FPaymentAPIKeys: TPaymentAPIKeys;

    // CallBacks
    FCustomerOpenCallBack: TNotifyEvent;
    FOnReadyToProgress: TOnReadyToProgress;
    FAfterPaymentProcessed: TAfterPaymentProcessed;

    FDebugInfo: String;
    FErrorMessage: String;
    FMailingAddress: TMailingAddress;

    FAllDataSaved: TAllDataRequired;
    FAllDataOpened: TAllDataRequired;

    FErrorMessageProc: TErrorMessageProc;
    FPreSelect: TSelectData;
    FAfterPolicyUpdate: TNotifyEvent;
    FLastOutcome: TBasicResponse;
    FAutoShowGadgetSelect: Boolean;
    FReloadOnInit: Boolean;

    Procedure CalcPremiumTotal;
    Procedure ClearFilters;

    Function GetLocalValue(Const Key: String): String;
    Procedure SetLocalValue(Const Key, Value: String);
    Function GetSessionValue(Const Key: String): String;
    Procedure SetSessionValue(Const Key, Value: String);

    Procedure OnApplicationError(Sender: TObject; AError: TApplicationError; Var Handled: Boolean);
    [async]
    Procedure HandleAnException(AException: EGeneralXDataException; Var Handled: Boolean); async;

    Function LocalValueKeyStartsWith(Const Value: String): String;

    [async]
    Procedure ProcessQuery; async;

    [async]
    procedure ReloadBasket(const ABasketCode: String); async;
    Procedure CheckAllLoaded;
    Procedure CloseAllData;

    [async]
    Procedure LoadBankDetails; async;
    [async]
    Procedure LoadPaymentKeys; async;
    [async]
    Procedure LoadMailingAddress; async;
    [async]
    Procedure ConfigInit; async;
    [async]
    Procedure InitModule; async;
    [async]
    Procedure StartSession; async;
    [async]
    Procedure LoadConfig; async;

    [async]
    Function UpdateBasket: Boolean; async;
    [async]
    Procedure UpdatePolicyData(APremium: TPremium); async;

    Procedure SetPaymentProvider(Const Value: TPaymentProvider);
    Procedure CreateGadgetPage;

    Function GetDebugInfo: String;

    Procedure SetProcessingState(Const Value: TProcessingState);

    Procedure SetMainPage(Const Value: TMainAppPage);
    Procedure UnlinkAllCallBacks;
    Function GetPageSwitched: Boolean;

    [async]
    Procedure SaveAllData; async;
    function SaveAllDataCheck: Boolean;

    Function CustomerIsInitiated: Boolean;

    Function SphinxRedirectUri: String;
    Function GetCustomerName: String;
    Function GetPolicyGadget: TGadgetType;
    Function GetPolicyCount: Integer;
    function GetCustomerId: Integer;
    procedure HandleBasketLoaded;

    [async]
    function LoadPreSelect: Boolean; async;

    [async]
    procedure LookUpSessionData; async;

    [async]
    procedure SendSavedForLaterEmail; async;

    procedure SetPolicyStatus(APolicyStatus: TPolicyStatus);
    procedure UpdatePoliciesWithBasketId(ABasketId: Integer);
    procedure UpdatePoliciesWithCustomerId(ACustomerId: Integer);
    function GetChosenPremiumKind: TPremiumKind;

    Property MainPage: TMainAppPage Read FMainPage Write SetMainPage;

  Public
    { Public declarations }

    Procedure RaiseException(Const AMessage: String);

    Procedure SaveData;

    procedure ResetProcessingState;

    procedure DisableAllControls;
    procedure EnableAllControls;

    [async]
    Procedure LoadBasket(const ABasketId); async;

    [async]
    Function GetMakes(Const AGadgetType: TGadgetType): TList<String>; async;

    [async]
    Function GetModels(Const AGadgetType: TGadgetType; Const AMake: String): TList<TIdAndName>; async;

    [async]
    Function FindPremium(Const AModelId: Integer): TPremium; async;

    [async]
    Procedure LoadPolicy(Const APremiumId: Integer); async;

    function LocatePolicy(const APolicyId: Integer): Boolean;

    procedure DeletePolicy(const APolicyId: Integer);

    [async]
    Procedure ChangePremiumKind(Const AKind: TPremiumKind); async;
    [async]
    Procedure ChangeLossOption(Const IncludeLoss: Boolean); async;

    function LossCoverPremium(const AGadgetType: TGadgetType; const APremiumKind:
        TPremiumKind): Double;

    [async]
    procedure ChangeMemoryOption(const AMemIndex: Integer); async;

    function ValidForQuoteSave: Boolean;

    Procedure SwitchToPayment;

    Procedure SwitchMainPage(Const APage: TMainAppPage);

    [async]
    Procedure SaveDirectDebit; async;

    [async]
    Procedure SaveForLater; async;

    Procedure PostAll;

    Procedure ClearFiltersAndReset;

    Function LookUpValue(const AKey: string): String;
    function MissingSerials: Integer;
    [async]
    function StripeSubscription(Token: string; const Callback: TProc<boolean, string>): boolean; async;

    Property AutoShowGadgetSelect: Boolean read FAutoShowGadgetSelect write FAutoShowGadgetSelect;

    Property PreSelect: TSelectData Read FPreSelect Write FPreSelect;

    Property PolicyGadget: TGadgetType Read GetPolicyGadget;
    Property ChosenPremiumKind: TPremiumKind read GetChosenPremiumKind;
    Property PolicyCount: Integer Read GetPolicyCount;

    Property DebugInfo: String Read GetDebugInfo;
    Property CustomerName: String Read GetCustomerName;

    Property LastOutcome: TBasicResponse read FLastOutcome;

    Property ProcessingState: TProcessingState Read FProcessingState Write SetProcessingState;

    Property PaymentProvider: TPaymentProvider Read FPaymentProvider Write SetPaymentProvider;
    Property PaymentKeys: TPaymentAPIKeys Read FPaymentAPIKeys;
    Property BankDetails: TBankDetails Read FBankDetails;
    Property MailingAddress: TMailingAddress Read FMailingAddress;

    Property AfterPolicyUpdate: TNotifyEvent Write FAfterPolicyUpdate;
    Property OnReadyToProgess: TOnReadyToProgress Write FOnReadyToProgress;
    Property AfterPaymentProcessed: TAfterPaymentProcessed Write FAfterPaymentProcessed;
    property CustomerId: Integer read GetCustomerId;
    Property ErrorMessageProc: TErrorMessageProc Read FErrorMessageProc Write FErrorMessageProc;

    Property ErrorMessage: String Read FErrorMessage Write FErrorMessage;
    /// <summary>
    /// Returns True if not Logged In or Customer Id is not present in the JWT
    /// </summary>

    Property LocalValue[Const Key: String]: String Read GetLocalValue Write SetLocalValue;
    Property SessionValue[Const Key: String]: String Read GetSessionValue Write SetSessionValue;
    Property PageSwitched: Boolean Read GetPageSwitched;

    property LastError: string read FLastError write FLastError;

  protected procedure LoadDFMValues; override; End;

Var
  MainData: TMainData;

Implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}

Uses
  System.Rtti,
  System.DateUtils,
  System.StrUtils,
  System.Math,
  XData.Web.Request,
  XData.Web.Response,
  WEBLib.Storage,
  WEBLib.WebTools,
  XData.Dataset.Utils,
  MainSitePage,
  smx.webcore.Types,
  smx.Auth.Shared,
  smx.Web.Document.Utils,
  smx.XDataSvc.Utils,
  Gadget.Web.Utils,
  smx.Web.Utils,
  Gadget.Web.Data.Utils,
  smx.CRM.Utils;

Const
  Process_State = 'ProcessState';
  Last_Section = 'LastSection';
  Customer_Id = 'Customer_Id';
  Storage_Key = 'mgu-';

procedure TMainData.BasketDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
var
  lMessage: String;
  lResolveId: Integer;
  lDateAdded: TDAteTime;
begin
  if not TXDataDSUtils.ResolvedAsExpected(Info, 'Basket', lMessage) then
    raise Exception.Create(lMessage);

  if (Info.Records[0].Status = TUpdateStatus.usInserted) then
  begin
    lResolveId := TXDataDSUtils.UpdateId(BasketData, Info, 'Id', 0);
    lDateAdded := BasketDataDateAdded.Value;
    UpdatePoliciesWithBasketId(lResolveId);
    LocalValue['BasketId'] := lResolveId.ToString + ';' + Double(Now).ToString;
  end;

  case ProcessingState of
    psSavingData:
      begin
        FAllDataSaved.Basket := True;
        if not TXDataDSUtils.PostAndApply(PolicyData) then
        begin
          FAllDataSaved.Policy := True;
          SaveAllDataCheck;
        end;
        Exit;
      end;
  end;

  if TXDataDSUtils.IsModified(PolicyData) then
    TXDataDSUtils.PostAndApply(PolicyData)
  else if Assigned(FOnReadyToProgress) then
    FOnReadyToProgress(True);

end;

procedure TMainData.BasketDataAfterOpen(Dataset: TDataSet);
begin
  if FProcessingState = TProcessingState.psOpeningData then
  begin
    FAllDataOpened.Basket := True;
    CustomerData.QueryString := '$filter=Id eq ' + BasketDataCustomerId.AsString;
    CustomerData.Load;
    PolicyData.QueryString := '$filter=BasketId eq ' + BasketDataId.AsString;
    PolicyData.Load;
  end;
end;

procedure TMainData.BasketDataCalcFields(Dataset: TDataSet);
var
  lValue: Double;
begin
  lValue := BasketDataAffilliateDiscountRate.Value + BasketDataQuantityDiscountRate.Value;
  if TGadgetUtils.StrToBrandOfferKind(BasketDataBrandOfferKind.Value) = TBrandOfferKind.Discount then
    lValue := lValue + BasketDataBrandOffer.Value;
  BasketDataTotalDiscountRate.Value := lValue
end;

procedure TMainData.BasketDataNewRecord(Dataset: TDataSet);
begin

  BasketDataDateAdded.Value := Now;
  BasketDataAddedBy.Value := 0;

  BasketDataGrossPremium.Value := 0.00;
  BasketDataSessionId.Value := FSessionData.SessionId;
  BasketDataQuantityDiscountRate.Value := 0.00;
  BasketDataStatus.Value := Basket_Status[TBasketStatus.New];
  BasketDataAffiliateKind.Value := TGadgetUtils.AffiliateKindToStr(FSessionData.AffiliateKind);
  BasketDataAffilliateId.Value := FSessionData.AffiliateId;
  BasketDataAffilliateDiscountRate.Value := FSessionData.AffilateDiscount;
  BasketDataBrandOfferKind.Value := TGadgetUtils.BrandOfferKindToStr(FSessionData.BrandOfferKind);
  BasketDataBrandOffer.Value := FSessionData.BrandOffer;

  BasketDataNumberOfPolicies.Value := 0;
  BasketDataCustomerId.Value := CustomerId;
  BasketDataLatestSessionId.Value := FSessionData.SessionId;
  BasketDataBrandCode.Value := FSessionData.BrandCode;

  BasketDataNetPremium.Value := 0.00;
  BasketDataPremiumPeriod.Value := TGadgetUtils.PremiumKindToStr(TPremiumKind.MonthlyCover);
  BasketDataLossCover.Value := False;
  BasketDataAcceptTerms.Value := False;

end;

Procedure TMainData.CalcPremiumTotal;
Var
  BM: TBookMark;
  lCount: Integer;
  lTotalPremium, lNetPremium, lDiscount: Double;
Begin
  TXDataDSUtils.DatasetEdit(BasketData);
  BasketDataQuantityDiscountRate.Value := TDataUtils.QuantityDiscount;
  lDiscount := BasketDataTotalDiscountRate.Value;
  PolicyData.DisableControls;
  Try
    lTotalPremium := 0.00;
    lNetPremium := 0.00;
    BM := PolicyData.GetBookmark;
    Try
      lCount := PolicyData.RecordCount;
      PolicyData.First;
      While Not PolicyData.Eof Do
      Begin
        If (PolicyDataDiscountPercent.Value <> lDiscount) Then
        Begin
          PolicyData.Edit;
          PolicyDataDiscountPercent.Value := lDiscount;
        End;

        TDataUtils.CalcPremiumValues(PolicyDataLossCover.Value);
        PolicyData.Post;

        lTotalPremium := lTotalPremium + PolicyDataGrossPremium.Value;
        lNetPremium := lNetPremium + PolicyDataNetPremium.Value;

        PolicyData.Next;
      End;
    Finally
      PolicyData.GotoBookmark(BM);
      PolicyData.FreeBookmark(BM);
    End;

    BasketDataGrossPremium.Value := lTotalPremium;
    BasketDataNetPremium.Value := lNetPremium;

  Finally
    PolicyData.EnableControls;
  End;
End;

procedure TMainData.CardDataNewRecord(Dataset: TDataSet);
begin
  CardDataDateAdded.Value := Now;
  CardDataAddedBy.Value := 0;

  CardDataSessionId.Value := FSessionId;
  CardDataCustomerId.Value := CustomerDataId.Value;
  CardDataBasketId.Value := BasketDataId.Value;

end;

Procedure TMainData.WebDataModuleDestroy(Sender: TObject);
Begin

  If Assigned(FBankDetails) Then
    FBankDetails.Free;
  If Assigned(FPaymentAPIKeys) Then
    FPaymentAPIKeys.Free;
  If Assigned(FMailingAddress) Then
    FMailingAddress.Free;

  FPremiums.Free;

End;

Procedure TMainData.ChangeLossOption(Const IncludeLoss: Boolean);
Var
  BM: TBookMark;
Begin

  if BasketDataLossCover.Value = IncludeLoss Then
    Exit;

  ProcessingState := TProcessingState.psUpdatingPolicy;
  TXDataDSUtils.DatasetEdit(BasketData);
  BasketDataLossCover.Value := IncludeLoss;

  PolicyData.DisableControls;

  BM := PolicyData.GetBookmark;
  Try
    PolicyData.First;
    While Not PolicyData.Eof Do
    Begin

      TDataUtils.CalcPremiumValues(IncludeLoss);
      PolicyData.Post;

      PolicyData.Next;
    End;
  Finally
    PolicyData.GotoBookmark(BM);
    PolicyData.FreeBookmark(BM);
  End;

  Await(UpdateBasket);
  SaveAllData;
End;

procedure TMainData.ChangeMemoryOption(const AMemIndex: Integer);
var
  lCurrentPremium, lNewPremium: TPremium;
  lNewPremiumId: Integer;
  ts: TStringList;
begin
  ProcessingState := TProcessingState.psUpdatingPolicy;
  lCurrentPremium := Await(FindPremium(PolicyDataPremiumId.Value));
  ts := TStringList.Create;
  try
    ts.Delimiter := ';';
    ts.DelimitedText := lCurrentPremium.MemoryOptions;
    { TODO : What if not there? }
    lNewPremiumId := ts.ValueFromIndex[AMemIndex].ToInteger;
  finally
    ts.Free;
  end;

  lNewPremium := Await(FindPremium(lNewPremiumId));

  PolicyData.DisableControls;
  Try
    TXDataDSUtils.DatasetEdit(PolicyData);
    UpdatePolicyData(lNewPremium);
  Finally
    Await(UpdateBasket);
    SaveAllData;
  End;

end;

Procedure TMainData.ChangePremiumKind(Const AKind: TPremiumKind);
Var
  BM: TBookMark;
  lPremium: TPremium;
Begin
  If TGadgetUtils.StrToPremiumKind(BasketDataPremiumPeriod.Value) = AKind Then
    Exit;

  ProcessingState := TProcessingState.psUpdatingPolicy;

  TXDataDSUtils.DatasetEdit(BasketData);
  BasketDataPremiumPeriod.Value := TGadgetUtils.PremiumKindToStr(AKind);

  PolicyData.DisableControls;
  BM := PolicyData.GetBookmark;
  Try
    PolicyData.First;
    While Not PolicyData.Eof Do
    Begin
      lPremium := Await(FindPremium(PolicyDataPremiumId.Value));
      TDataUtils.CalcPremiumValues(AKind, lPremium);
      PolicyData.Next;
    End;
  Finally
    PolicyData.GotoBookmark(BM);
    PolicyData.FreeBookmark(BM);
  End;

  Await(UpdateBasket);
  SaveAllData;

End;

procedure TMainData.CheckAllLoaded;
begin
  if FAllDataOpened.AllDone then
  begin
    ResetProcessingState;
    If Assigned(FOnReadyToProgress) then
      FOnReadyToProgress(True);
  end;
end;

procedure TMainData.ClearFilters;
begin
  BasketData.QueryString := '';
  PolicyData.QueryString := '';
  CustomerData.QueryString := '';
end;

procedure TMainData.ClearFiltersAndReset;
begin
  BasketData.QueryString := '';
  CustomerData.QueryString := '';
  PolicyData.QueryString := '';
  CardData.QueryString := '';
  DirectDebit.QueryString := '';
  CloseAllData;
end;

procedure TMainData.CloseAllData;
begin
  If BasketData.Active then
    BasketData.Close;
  if CustomerData.Active then
    CustomerData.Close;
  if PolicyData.Active then
    PolicyData.Close;
  If CardData.Active then
    CardData.Close;
  If DirectDebit.Active then
    DirectDebit.Close;
end;

Procedure TMainData.ConfigInit;
Var
  lParamValue: String;
  lLoginStarted: TDAteTime;

Begin
  Await(LoadConfig);

  If SessionValue[Process_State] <> '' Then
  Begin
    ProcessingState := TRttiEnumerationType.GetValue<TProcessingState>(SessionValue[Process_State]);
    SessionValue[Process_State] := '';
  End
  Else
    ProcessingState := TProcessingState.psStandard;

  LookUpData.Load;
  LossCoverData.Load;
End;

Procedure TMainData.CreateGadgetPage;
Begin
  MainPage := TMainAppPage.mpSales;
  TMainSiteForm.DisplayPage('content');
End;

Procedure TMainData.CustomerDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
Var
  lResolveId, lSize: Integer;
  lMessage: String;
Begin

  lMessage := '';
  lSize := Length(Info.Records);

  If Not TXDataDSUtils.ResolvedAsExpected(Info, 'Customer', lMessage) Then
    RaiseException(lMessage);

  If (Info.Records[0].Status = TUpdateStatus.usInserted) then
  begin
    lResolveId := TXDataDSUtils.UpdateId(CustomerData, Info, 'Id', 0);
    TXDataDSUtils.DatasetEdit(BasketData);
    BasketDataCustomerId.Value := lResolveId;
    UpdatePoliciesWithCustomerId(lResolveId);
  end;

  if FProcessingState = TProcessingState.psSavingData then
  begin
    FAllDataSaved.Customer := True;
    if not TXDataDSUtils.PostAndApply(BasketData) then
    begin
      FAllDataSaved.Basket := True;
      if not SaveAllDataCheck then
      begin
        if not TXDataDSUtils.PostAndApply(PolicyData) then
        begin
          FAllDataSaved.Policy := True;
          SaveAllDataCheck;
        end;
      end;
    end;
  end
  else If Assigned(FOnReadyToProgress) Then
    FOnReadyToProgress(True);

End;

Procedure TMainData.CustomerDataAfterOpen(Dataset: TDataSet);
Begin
  if FProcessingState = TProcessingState.psOpeningData then
  begin
    FAllDataOpened.Customer := True;
    HandleBasketLoaded;
  end
  else
  begin
    CustomerData.EnableControls;

    If Assigned(FCustomerOpenCallBack) Then
      FCustomerOpenCallBack(Self);
  end;

End;

Procedure TMainData.CustomerDatasetEmailSetText(Sender: TField; Const Text: String);
Begin
  Sender.AsString := Text.ToLower;
End;

Procedure TMainData.CustomerDataNewRecord(Dataset: TDataSet);
Begin
  CustomerDataDateAdded.Value := Now;
  CustomerDataAddedBy.Value := 0;
  CustomerDataAffiliateId.Value := FSessionData.AffiliateId;
  CustomerDataAffiliateKind.Value := TGadgetUtils.AffiliateKindToStr(FSessionData.AffiliateKind);
  CustomerDataMarketingOK.Value := False;
End;

Procedure TMainData.CustomerDatasetPostcodeSetText(Sender: TField; Const Text: String);
Begin
  Sender.AsString := Text.ToUpper;
End;

function TMainData.CustomerIsInitiated: Boolean;
begin
  Result := TXDataDSUtils.IsOpenAndNotEmpty(CustomerData) And
    ((CustomerDataEmail.Value <> '') Or (CustomerDataForename.Value <> '') or (CustomerDataSurname.Value <> '') or
    (CustomerDataMobile.Value <> ''));

end;

Procedure TMainData.DataConnectionError(Error: TXDataWebConnectionError);
Var
  lHandled: Boolean;
Begin
  lHandled := False;
  Self.HandleAnException(EGeneralXDataException.Create(Error), lHandled);
End;

Procedure TMainData.DataConnectionRequest(Args: TXDataWebConnectionRequest);
Begin
  Args.Request.Headers.SetValue('UserSession', FSessionData.SessionId.ToString);
End;

procedure TMainData.DeletePolicy(const APolicyId: Integer);
begin
  if PolicyData.Locate('Id', APolicyId, []) then
  begin
    ProcessingState := psDeletingPolicy;
    PolicyData.Delete;
    CalcPremiumTotal;
    SaveAllData;
  end;
end;

Procedure TMainData.DirectDebitNewRecord(Dataset: TDataSet);
Begin
  DirectDebitDateAdded.Value := Now;
  DirectDebitAddedBy.Value := 0;

  DirectDebitSessionId.Value := FSessionId;
  DirectDebitCustomerId.Value := CustomerDataId.Value;
  DirectDebitBasketId.Value := BasketDataId.Value;
End;

procedure TMainData.DisableAllControls;
begin
  CustomerData.DisableControls;
  BasketData.DisableControls;
  PolicyData.DisableControls;
end;

procedure TMainData.EnableAllControls;
begin
  CustomerData.EnableControls;
  BasketData.EnableControls;
  PolicyData.EnableControls;
end;

function TMainData.GetCustomerId: Integer;
begin
  if TXDataDSUtils.IsOpenAndNotEmpty(CustomerData) then
    Result := CustomerDataId.Value
  else
    Result := 0;
end;

Function TMainData.GetCustomerName: String;
Begin
  If CustomerData.Active And (Not CustomerData.IsEmpty) Then
    Result := Trim(CustomerDataForename.Value + ' ' + CustomerDataSurname.Value)
  Else
    Result := '';
End;

Function TMainData.GetDebugInfo: String;
Begin
{$IFNDEF RELEASE}
  If TXDataDSUtils.IsOpenAndNotEmpty(BasketData) then
    Result := 'Basket - Id: ' + BasketDataId.AsString + '; DateAdded: ' + FormatDateTime('dd-mmm-yyyy hh:nn:ss',
      BasketDataDateAdded.Value)
  else
    Result := 'Basket not open yet';
{$ELSE}
  Result := '';
{$ENDIF}
End;

Function TMainData.GetLocalValue(Const Key: String): String;
Begin
  Result := TLocalStorage.GetValue(Storage_Key + Key);
End;

Function TMainData.GetMakes(Const AGadgetType: TGadgetType): TList<String>;
Const
  makes_svc = 'IProductService.GetMakes'; // (GadgetType)
Var
  lRetval: TXDataClientResponse;
Begin
  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(makes_svc,
    [TRttiEnumerationType.GetName<TGadgetType>(AGadgetType)]));
  Result := TXDataSvcUtils.AsListOfStrings(lRetval);

  If Not Assigned(Result) Then
    Result := TList<String>.Create;
End;

Function TMainData.GetModels(Const AGadgetType: TGadgetType; Const AMake: String): TList<TIdAndName>;
Const
  makes_svc = 'IProductService.GetModels'; // (GadgetType)
Var
  lRetval: TXDataClientResponse;
  lArray: TJSArray;
  I: Integer;
  lObj: TJSObject;
Begin
  Result := TList<TIdAndName>.Create;

  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(makes_svc,
    [TRttiEnumerationType.GetName<TGadgetType>(AGadgetType), AMake]));

  lArray := TXDataSvcUtils.AsArray(lRetval);

  For I := 0 To lArray.Length - 1 Do
  Begin
    lObj := JS.toObject(lArray[I]);
    Result.Add(TIdAndName.Create(lObj));
  End;

End;

Function TMainData.GetPageSwitched: Boolean;
Begin
  Result := (FPreviousMainPage <> TMainAppPage.mpNone) And (FPreviousMainPage <> FMainPage);
End;

Function TMainData.GetPolicyCount: Integer;
Begin
  If TXDataDSUtils.IsOpenAndNotEmpty(PolicyData) Then
    Result := PolicyData.RecordCount
  Else
    Result := 0;
End;

Function TMainData.GetPolicyGadget: TGadgetType;
Begin
  If TXDataDSUtils.IsOpenAndNotEmpty(PolicyData) Then
    Result := TGadgetUtils.StrToGadgetType(PolicyDataGadgetType.Value)
  Else
    Result := TGadgetType.None;
End;

function TMainData.GetChosenPremiumKind: TPremiumKind;
begin
  If TXDataDSUtils.IsOpenAndNotEmpty(PolicyData) Then
    Result := TGadgetUtils.StrToPremiumKind(BasketDataPremiumPeriod.Value)
  Else
    Result := TPremiumKind.MonthlyCover;
end;

Function TMainData.GetSessionValue(Const Key: String): String;
Begin
  Result := TSessionStorage.GetValue(Storage_Key + Key);
End;

Procedure TMainData.HandleAnException(AException: EGeneralXDataException; Var Handled: Boolean);
Const
  support_svc = 'IsmxSupportService';
Begin

  // Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(support_svc, [TJSJSON.Stringify(AException)]));
  Handled := True;
  If Assigned(FErrorMessageProc) Then
    FErrorMessageProc('We''re sorry, there''s been a problem with this. We are looking into it.'
{$IFDEF SHOWERROR} + ': ' + AException.Message{$ENDIF});

End;

Procedure TMainData.InitModule;
Var
  lLastSection: TMainAppPage;
  s: String;
  lOutcome: Boolean;
Begin
  FormatSettings.DateSeparator := '/';
  FormatSettings.ShortDateFormat := 'dd/mm/yyyy';

{$IFDEF DEBUG}
  Application.ErrorType := aeFooter; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := Nil;
  DataConnection.OnError := Nil;
  WebClient.OnError := Nil;
{$ELSE}
  Application.ErrorType := aeSilent; // aeSilent, aeDialog, aeAlert, aeFooter
  Application.OnError := OnApplicationError;
  DataConnection.OnError := DataConnectionError;
  WebClient.OnError := WebClientError;
{$ENDIF}
  { TODO : Accept feed ins from query }
  // if HasQueryParam('holidayid', lHolidayId) then
  // LocalValue['holiday-id'] := lHolidayId;

  ProcessingState := TProcessingState.psStandard;

  Await(DataConnection.OpenAsync);

  // LoadMailingAddress;
  // LoadBankDetails;
  // LoadPaymentKeys;

  if FSessionData.CreateAtStart then
    Await(StartSession);

  If FSessionData.NeedsData then
    Await(LookUpSessionData);

  if FSessionData.BasketCode <> '' then
  begin
    FReloadOnInit := True;
    ReloadBasket(FSessionData.BasketCode);
  end
  else
  begin
    TXDataDSUtils.DatasetEdit(CustomerData);
    if FPreSelect.SelectLevel > TSelectLevel.slNone then
    begin
      lOutcome := Await(LoadPreSelect);
      if not lOutcome then
        CreateGadgetPage;
    end
    else
      CreateGadgetPage;
    FInitDone := True;
  end;

End;

Procedure TMainData.LoadConfig;
Var
  Conn: TXDataWebConnection;
  Response: IHttpResponse;
Begin

  Conn := TXDataWebConnection.Create(Nil);
  Try

    Response := Await(Conn.SendRequestAsync(THttpRequest.Create('config/config.json')));
    If Response.StatusCode = 200 Then
    Begin
      AppConfig.Load(Response.ContentAsText);
      DataConnection.URL := AppConfig.BaseUrl;
    End;

  Finally
    Conn.Free;
  End;

End;

Procedure TMainData.LoadBankDetails;
Const
  bank_details_svc = 'IProductService.BankDetails';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;
Begin
  If FBankDetails = Nil Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(bank_details_svc, []));
    lData := lRetval.ResultAsObject;
    FBankDetails := TBankDetails.Create;
    FBankDetails.Load(lData);
  End;
End;

procedure TMainData.LoadBasket(const ABasketId);
begin

end;

Procedure TMainData.LoadMailingAddress;
Const
  mailing_address_svc = 'IProductService.MailingAddress';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;
Begin
  If Not Assigned(FMailingAddress) Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(mailing_address_svc, []));
    lData := lRetval.ResultAsObject;
    FMailingAddress := TMailingAddress.Create;
    FMailingAddress.Load(lData);
  End;
End;

Procedure TMainData.LoadPaymentKeys;
Const
  payment_keys_svc = 'IProductService.PaymentAPIKeys';
Var
  lRetval: TXDataClientResponse;
  lData: JS.TJSObject;
Begin

  If FPaymentAPIKeys = Nil Then
  Begin
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(payment_keys_svc, []));
    lData := lRetval.ResultAsObject;
    FPaymentAPIKeys := TPaymentAPIKeys.Create;
    FPaymentAPIKeys.Load(lData);
  End;

End;

Procedure TMainData.LoadPolicy(Const APremiumId: Integer);
Var
  lPremium: TPremium;
Begin

  ProcessingState := TProcessingState.psLoadingPolicy;

  If FSessionData.SessionId = 0 then
    Await(StartSession);

  TXDataDSUtils.DatasetEdit(BasketData);
  lPremium := Await(FindPremium(APremiumId));

  PolicyData.DisableControls;

  TXDataDSUtils.DatasetAppend(PolicyData);
  UpdatePolicyData(lPremium);
  Await(UpdateBasket);

  SaveAllData;
End;

Function TMainData.FindPremium(Const AModelId: Integer): TPremium;
Const
  makes_svc = 'IProductService.GetPremium'; // (Id)
Var
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  lPremium: TPremium;
Begin

  For lPremium In FPremiums Do
  Begin
    If lPremium.Id = AModelId Then
      Exit(lPremium)
  End;

  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(makes_svc, [AModelId]));
  lResult := TXDataSvcUtils.AsObject(lRetval);

  lPremium := TPremium.Create;
  lPremium.Assign(lResult);

  FPremiums.Add(lPremium);

  Result := lPremium;
End;

procedure TMainData.HandleBasketLoaded;
begin
  if FAllDataOpened.AllDone then
  begin
    ProcessingState := TProcessingState.psStandard;
    TXDataDSUtils.DatasetEdit(BasketData);
    BasketDataAcceptTerms.Value := False;
    if FReloadOnInit then
    begin
      FReloadOnInit := False;
      CreateGadgetPage;
      FInitDone := True;
    end;
  end;
end;

function TMainData.LoadPreSelect: Boolean;
const
  model_svc = 'IProductService.GetMakeForModel';
var
  lRetval: TXDataClientResponse;
  lResult: TJSObject;
  lGadget: TGadgetGroup;
begin
  Result := False;
  case FPreSelect.SelectLevel of
    slGadget:
      ;
    slMake:
      ;
    slModel:
      begin
        lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(model_svc, [FPreSelect.Model]));
        lGadget := TGadgetGroup.Create(lRetval.ResultAsObject);
        Try
          FPreSelect.Outcome := lGadget.Outcome;
          Result := lGadget.Outcome = 'OK';

          if Result then
          begin
            Result := True;
            Await(LoadPolicy(lGadget.PremiumId));
          end;
        Finally
          lGadget.Free;
        End;

      end;
  end;
end;

Function TMainData.LocalValueKeyStartsWith(Const Value: String): String;
Begin
  // for I := 0 to TWebLocalStorage.Count - 1 do

End;

function TMainData.LocatePolicy(const APolicyId: Integer): Boolean;
begin
  if PolicyDataId.Value = APolicyId then
     Result := False
  else
    Result := PolicyData.Locate('Id', APolicyId, []);
end;

procedure TMainData.LookUpSessionData;
const
  session_svc = 'IProductService.GetSessionInfo'; // (GadgetType)
Var
  lRetval: TXDataClientResponse;
  lObject: TJSObject;
Begin
  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(session_svc,
    [FSessionData.AffiliateId, FSessionData.BrandCode]));
  lObject := lRetval.ResultAsObject;
  FSessionData.AffilateDiscount := Double(lObject['AffiliateDiscount']);
  FSessionData.BrandOfferKind := TGadgetUtils.StrToBrandOfferKind(String(lObject['BrandOfferKind']));
  FSessionData.BrandOffer := Double(lObject['BrandOffer']);
  // FSessionData.BrandOfferStatus := TOfferStatus;

end;

function TMainData.LookUpValue(const AKey: string): String;
begin
  Result := '';
  If LookUpData.Active and LookUpData.Locate('Key', AKey, []) then
    Result := LookUpDataValue.AsString;
end;

function TMainData.LossCoverPremium(const AGadgetType: TGadgetType; const APremiumKind: TPremiumKind): Double;
begin
  if LossCoverData.Locate('GadgetType', TGadgetUtils.GadgetTypeToStr(AGadgetType), []) then
  begin
    Result := LossCoverDataPremium.Value;
    if APremiumKind = TPremiumKind.AnnualCover then
       Result := Result * Annual_Premium_Multiplier;
  end
  else
    Result := 0.00;
end;

function TMainData.MissingSerials: Integer;
begin
  Result := 0;
  PolicyData.First;
  while not PolicyData.Eof do
  begin
    if PolicyDataSerialNumber.Value = '' then
      Inc(Result);
    PolicyData.Next;
  end;
  PolicyData.First;
end;

Procedure TMainData.OnApplicationError(Sender: TObject; AError: TApplicationError; Var Handled: Boolean);
Begin
  HandleAnException(EGeneralXDataException.Create(AError), Handled);
End;

function TMainData.UpdateBasket: Boolean;
begin
  Result := True;
  TXDataDSUtils.DatasetEdit(BasketData);
  CalcPremiumTotal;
end;

procedure TMainData.PolicyDataAfterApplyUpdates(Sender: TDataSet; Info: TResolveResults);
begin
  TXDataDSUtils.UpdateAllIds(PolicyData, Info, 'Id');
  PolicyData.EnableControls;

  case FProcessingState of
    psSaveForLater:
      begin
        FAllDataSaved.Policy := True;
        SaveAllDataCheck;
        Exit;
      end;
    psLoadingPolicy:
      ;
    psUpdatingPolicy:
      ;
    psSwitchToPayment:
      ;
    psPaymentProcessing:
      ;
    psOpeningData:
      ;
    psSavingData:
      begin
        FAllDataSaved.Policy := True;
        SaveAllDataCheck;
        Exit;
      end;
  else
    begin
      if Assigned(FOnReadyToProgress) then
        FOnReadyToProgress(True);
    end;
  end;
end;

procedure TMainData.PolicyDataAfterOpen(Dataset: TDataSet);
begin
  if FProcessingState = TProcessingState.psOpeningData then
  begin
    FAllDataOpened.Policy := True;
    HandleBasketLoaded;
  end
end;

Procedure TMainData.PolicyDataCalcFields(Dataset: TDataSet);
Begin
  If TGadgetUtils.StrToGadgetType(PolicyDataGadgetType.Value) = TGadgetType.Laptop Then
    PolicyDataLossOption.Value := 'Loss cover is not available on laptops'
  Else If Not PolicyDataLossCover.Value Then
    PolicyDataLossOption.Value := 'No loss cover included'
  Else
    PolicyDataLossOption.Value := 'Loss cover included!';

  PolicyDataLossCoverText.Value := YesNo[PolicyDataLossCover.Value];

  PolicyDataPremiumPeriodDisplay.Value := TUtils.SplitOnCaps(PolicyDataPremiumPeriod.Value);

  PolicyDataSummaryText.Value := PolicyDataMake.Value + ' ' + PolicyDataModel.Value;

End;

Procedure TMainData.PolicyDataNewRecord(Dataset: TDataSet);
Begin

  PolicyDataDateAdded.Value := Now;
  PolicyDataAddedBy.Value := 0;

  if TXDataDSUtils.IsOpenAndNotEmpty(BasketData) and (BasketDataId.Value > 0) then
    PolicyDataBasketId.Value := BasketDataId.Value
  else
    PolicyDataBasketId.Value := 0;

  PolicyDataSerialNumber.Value := '';

End;

Procedure TMainData.ProcessQuery;
Var
  lParamValue: String;
Begin
  If HasQueryParam('src', lParamValue, False) Then
  Begin
    FSessionData.AffiliateId := StrToIntDef(lParamValue, 0);
    If FSessionData.AffiliateId > 0 Then
      FSessionData.AffiliateKind := TAffiliateKind.ThirdParty;
  End;

  if HasQueryParam('bc', lParamValue, False) then
    FSessionData.BrandCode := lParamValue;

  If HasQueryParam('utm_source', lParamValue, False) Then
    FSessionData.utm_source := lParamValue;

  If HasQueryParam('utm_medium', lParamValue, False) Then
    FSessionData.utm_medium := lParamValue;

  If HasQueryParam('utm_campaign', lParamValue, False) Then
    FSessionData.utm_campaign := lParamValue;

  If HasQueryParam('utm_content', lParamValue, False) Then
    FSessionData.utm_content := lParamValue;

  If HasQueryParam('utm_term', lParamValue, False) Then
    FSessionData.utm_term := lParamValue;

  If HasQueryParam('gdg', lParamValue, False) Then
    FPreSelect.Gadget := TGadgetUtils.StrToGadgetType(lParamValue.Replace('%20', '', [rfReplaceAll]).Replace('_', '',
      [rfReplaceAll]));

  If HasQueryParam('mk', lParamValue, False) Then
    FPreSelect.Make := lParamValue.Replace('%20', ' ', [rfReplaceAll]).Replace('_', ' ', [rfReplaceAll]);

  If HasQueryParam('mod', lParamValue, False) Then
    FPreSelect.Model := lParamValue.Replace('%20', ' ', [rfReplaceAll]).Replace('_', ' ', [rfReplaceAll])
  else If HasQueryParam('md', lParamValue, False) Then
    FPreSelect.Model := lParamValue.Replace('%20', ' ', [rfReplaceAll]).Replace('_', ' ', [rfReplaceAll]);

  If HasQueryParam('svb', lParamValue, False) then
  begin
    FSessionData.BasketCode := lParamValue;
    FSessionData.BasketSessionKind := TSessionKind.RetrievedByUser;
  end;

End;

Procedure TMainData.RaiseException(Const AMessage: String);
Begin
  TheMainForm.HideWaitMessage;
  Raise Exception.Create(AMessage);
End;

procedure TMainData.ReloadBasket(const ABasketCode: String);
const
  chk_basket_svc = 'IProductService.CheckBasketCanContinue';
var
  lRetval: TXDataClientResponse;
  lResponse: string;
begin
  FAllDataOpened.Clear;
  CloseAllData;

  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(chk_basket_svc, [ABasketCode]));
  lResponse := TXDataSvcUtils.AsString(lRetval);

  FLastOutcome := TBasicResponse.Create(lRetval.ResultAsObject);
  if FLastOutcome.Status = TBasicResponseStatus.rsError then
  begin
    ClearFiltersAndReset;
    if FReloadOnInit then
    begin
      FReloadOnInit := False;
      TXDataDSUtils.DatasetEdit(CustomerData);
      CreateGadgetPage;
      FInitDone := True;
    end;
    Exit;
  end;

  FSessionData.BasketId := FLastOutcome.Retval.ToInteger;
  FStoredProcessingState := FProcessingState;
  ProcessingState := TProcessingState.psOpeningData;

  If BasketData.Active then
    BasketData.Close;

  BasketData.QueryString := '$filter=Id eq ' + FSessionData.BasketId.ToString;
  BasketData.Load;
end;

Procedure TMainData.SaveAllData;
Begin

  FAllDataSaved.Clear;

  FStoredProcessingState := FProcessingState;
  ProcessingState := TProcessingState.psSavingData;

  if (not CustomerIsInitiated) or (not TXDataDSUtils.PostAndApply(CustomerData)) then
  begin
    FAllDataSaved.Customer := True;
    if not TXDataDSUtils.PostAndApply(BasketData) then
    begin
      FAllDataSaved.Basket := True;
      if not TXDataDSUtils.PostAndApply(PolicyData) then
      begin
        FAllDataSaved.Policy := True;
        SaveAllDataCheck;
      end;
    end;
  end;
end;

function TMainData.SaveAllDataCheck: Boolean;
Var
  lState, lBookingState: String;
Begin
  Result := FAllDataSaved.AllDone;
  If Result Then
  Begin
    ResetProcessingState;

    lState := TRttiEnumerationType.GetName<TProcessingState>(ProcessingState);

    Case ProcessingState Of
      psStandard:
        Begin

        End;

      psSaveForLater:
        Begin
          SendSavedForLaterEmail;
          Exit;
        End;

      TProcessingState.psLoadingPolicy, TProcessingState.psUpdatingPolicy, TProcessingState.psDeletingPolicy:
        begin
          PolicyData.EnableControls;
          if MainPage = TMainAppPage.mpNone then
            CreateGadgetPage;

          If Assigned(FAfterPolicyUpdate) Then
            FAfterPolicyUpdate(Self);
        end;

      TProcessingState.psSwitchToPayment:
        Begin

        End;

      psPaymentProcessing:
        Begin

        End;

      psSavingData:
        ;
    End;

    If Assigned(FOnReadyToProgress) Then
      FOnReadyToProgress(True);
  End;
End;

Procedure TMainData.SaveData;
Begin

  // Processingstate := TProcessingState.psSavingData;

  If TXDataDSUtils.IsModified(CustomerData) Then
    CustomerData.ApplyUpdates;

End;

procedure TMainData.SaveDirectDebit;
begin
  DirectDebit.ApplyUpdates;
end;

procedure TMainData.SaveForLater;
begin

  if not ValidForQuoteSave then
    Exit;

  ProcessingState := TProcessingState.psSaveForLater;

  TXDataDSUtils.DatasetEdit(BasketData);
  BasketDataStatus.Value := Basket_Status[TBasketStatus.Saved];

  SetPolicyStatus(TPolicyStatus.Saved);

  SaveAllData;

end;

Procedure TMainData.SetLocalValue(Const Key, Value: String);
Begin
  If Value = '' Then
    TLocalStorage.RemoveKey(Storage_Key + Key)
  Else
    TLocalStorage.SetValue(Storage_Key + Key, Value);
End;

Procedure TMainData.SetMainPage(Const Value: TMainAppPage);
Begin
  FMainPage := Value;
  SessionValue[Last_Section] := TRttiEnumerationType.GetName<TMainAppPage>(Value);
End;

Procedure TMainData.SetPaymentProvider(Const Value: TPaymentProvider);
Begin
  FPaymentProvider := Value;
End;

Procedure TMainData.SetProcessingState(Const Value: TProcessingState);
Begin
  FProcessingState := Value;
End;

Procedure TMainData.SetSessionValue(Const Key, Value: String);
Begin
  if Value = '' then
    TSessionStorage.RemoveKey(Storage_Key + Key)
  else
    TSessionStorage.SetValue(Storage_Key + Key, Value);
End;

Function TMainData.SphinxRedirectUri: String;
Var
  I: Integer;
Begin
  Result := Application.ExeName;
  I := Result.IndexOf('?');
  If I > 0 Then
    Result := Result.Substring(0, I);
  I := Result.IndexOf('#');
  If I > 0 Then
    Result := Result.Substring(0, I);
End;

Procedure TMainData.StartSession;
Const
  session_svc = 'IProductService.StartSession';
Var
  lRetval: TXDataClientResponse;
Begin
  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(session_svc,
    [FSessionData.AffiliateId, TGadgetUtils.AffiliateKindToStr(FSessionData.AffiliateKind), FSessionData.BrandCode,
    FSessionData.utm_source, FSessionData.utm_medium, FSessionData.utm_campaign, FSessionData.utm_content,
    FSessionData.utm_term, TGadgetUtils.SessionKindToStr(FSessionData.SessionKind), FPreSelect.AsString]));

  FSessionData.SessionId := TXDataSvcUtils.AsInteger(lRetval);
End;

function ToCamelCase(const s: string): string;
var
  I: Integer;
  NewWord: Boolean;
begin
  Result := '';
  NewWord := True;
  for I := 1 to Length(s) do
  begin
    if s[I] in ['_', '-', ' '] then
    begin
      Result := Result + s[I];
      NewWord := True;
    end
    else
    begin
      if NewWord then
      begin
        Result := Result + UpperCase(s[I]);
        NewWord := False;
      end
      else
        Result := Result + LowerCase(s[I]);
    end;
  end;
end;



//service returns a TJSONObject - saves having to include gadget return types on the stripe api server side?. (thinking generic API solution)
//The returned TJSONObject can be one of two IStripeOrderInfo, Either OrderInfo object or an Error object.
function TMainData.StripeSubscription(Token: string; const Callback: TProc<boolean, string>): boolean;
const
  IStripeService = 'IStripeService.CreateSubscription';
var
  lRetval: TXDataClientResponse;
  Subscription: TJSObject;
  SubscriptionId, CustomerId, Status: string;
  SalesOrder: TSalesOrder;
  ErrorMessage: string;
begin
  try
    SalesOrder := TSalesOrder.Create;
    SalesOrder.CustomerName := ToCamelCase(CustomerDataTitle.AsString + ' ' + CustomerDataForename.AsString + ' ' +CustomerDataSurname.AsString);
    SalesOrder.CustomerEmail := CustomerDataEmail.AsString;
    SalesOrder.SalesPrice := BasketDataNetPremium.AsFloat/100;
    SalesOrder.SalesToken := Token;
    SalesOrder.RecurringPeriod:= BasketDataPremiumPeriod.AsString;
    lRetval := Await(TXDataClientResponse, WebClient.RawInvokeAsync(IStripeService, [SalesOrder]));
    Subscription := lRetval.ResultAsObject;
    Status := JS.ToString(Subscription['status']);

    if Status = 'error' then
    begin
      //just show a user friendly message.
      ErrorMessage := JS.ToString(Subscription['message']);
      Callback(False, ErrorMessage);
    end
    else
    begin
      SubscriptionId := JS.ToString(Subscription['SubscriptionId']);
      CustomerId := JS.ToString(Subscription['CustomerId']);
      TXDataDSUtils.DatasetAppend(CardData);
      CardDataAccountName.Value:= SalesOrder.CustomerName;
      CardDataStripeSubscriptionId.Value:= SubscriptionId;
      CardDataStripeCustomerID.Value:= CustomerId;
      CardDataCustomerId.Value:= CustomerDataId.Value;
      CardDataStripeStatus.Value:= Status;
      CardData.Post;
      Await(CardData.ApplyUpdates);
      Callback(True, '');
    end;
  finally
    SalesOrder.Free;
  end;
end;

Procedure TMainData.SwitchMainPage(Const APage: TMainAppPage);
Begin

  If FMainPage = APage Then
    Exit;

  FPreviousMainPage := FMainPage;

End;

procedure TMainData.SwitchToPayment;
begin
  ProcessingState := TProcessingState.psSwitchToPayment;
  SaveAllData;
end;

Procedure TMainData.UnlinkAllCallBacks;
Begin
  FCustomerOpenCallBack := Nil;
  FOnReadyToProgress := Nil;
  FAfterPaymentProcessed := Nil;
End;

function TMainData.ValidForQuoteSave: Boolean;
begin
  Result := TCRMUtils.IsEmailValid(CustomerDataEmail.Value);
end;

Procedure TMainData.WebClientError(Error: TXDataClientError);
Var
  lHandled: Boolean;
Begin
  lHandled := False;
  HandleAnException(EGeneralXDataException.Create(Error), lHandled);
End;

Procedure TMainData.WebDataModuleCreate(Sender: TObject);
Begin
  FPreviousMainPage := TMainAppPage.mpNone;
  FInitDone := False;
  FReloadOnInit := False;
  FPremiums := TList<TPremium>.Create;

  ProcessQuery;

  Await(ConfigInit);

  InitModule;
End;

procedure TMainData.PolicyDataPurchasePriceGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  if Sender.AsFloat = 0 then
    Text := 'ADD'
  else
    Text := FormatFloat('£#,##0.00', Sender.AsFloat);
end;

procedure TMainData.PolicyDataDamageExcessGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat('£#,##0', Sender.AsFloat)
end;

procedure TMainData.PolicyDataTheftExcessGetText(Sender: TField; var Text: string; DisplayText: Boolean);
begin
  Text := FormatFloat('£#,##0', Sender.AsFloat)
end;

procedure TMainData.PostAll;
begin
  TXDataDSUtils.Post(BasketData);
  TXDataDSUtils.Post(CustomerData);
  TXDataDSUtils.Post(PolicyData);
end;

procedure TMainData.ResetProcessingState;
begin
  ProcessingState := FStoredProcessingState;
  FStoredProcessingState := TProcessingState.psStandard;
end;

procedure TMainData.SendSavedForLaterEmail;
Const
  save_svc = 'IProductService.SaveForLater';
Var
  lRetval: TXDataClientResponse;
  lId: Integer;
begin
  lId := BasketDataId.Value;
  lRetval := Await(TXDataClientResponse, MainData.WebClient.RawInvokeAsync(save_svc, [lId]));

  FLastOutcome := TBasicResponse.Create(lRetval.ResultAsObject);

  if Assigned(FOnReadyToProgress) then
    FOnReadyToProgress(True);
end;

procedure TMainData.SetPolicyStatus(APolicyStatus: TPolicyStatus);
Var
  BM: TBookMark;
  lCount: Integer;
Begin
  PolicyData.DisableControls;
  TXDataDSUtils.Post(PolicyData);
  Try
    BM := PolicyData.GetBookmark;
    Try
      lCount := PolicyData.RecordCount;
      PolicyData.First;
      While Not PolicyData.Eof Do
      Begin
        if PolicyDataBasketId.Value = 0 then
        begin
          PolicyData.Edit;
          PolicyDataStatus.Value := Policy_Status[APolicyStatus];
          PolicyData.Post;
        end;
        PolicyData.Next;
      End;
    Finally
      PolicyData.GotoBookmark(BM);
      PolicyData.FreeBookmark(BM);
    End;
  Finally
    PolicyData.EnableControls;
  End;
end;

procedure TMainData.UpdatePoliciesWithBasketId(ABasketId: Integer);
Var
  BM: TBookMark;
  lCount: Integer;
Begin
  PolicyData.DisableControls;
  TXDataDSUtils.Post(PolicyData);
  Try
    BM := PolicyData.GetBookmark;
    Try
      lCount := PolicyData.RecordCount;
      PolicyData.First;
      While Not PolicyData.Eof Do
      Begin
        if PolicyDataBasketId.Value = 0 then
        begin
          PolicyData.Edit;
          PolicyDataBasketId.Value := ABasketId;
          PolicyData.Post;
        end;
        PolicyData.Next;
      End;
    Finally
      PolicyData.GotoBookmark(BM);
      PolicyData.FreeBookmark(BM);
    End;
  Finally
    PolicyData.EnableControls;
  End;
end;

procedure TMainData.UpdatePoliciesWithCustomerId(ACustomerId: Integer);
Var
  BM: TBookMark;
  lCount: Integer;
Begin
  PolicyData.DisableControls;
  TXDataDSUtils.Post(PolicyData);
  Try
    BM := PolicyData.GetBookmark;
    Try
      lCount := PolicyData.RecordCount;
      PolicyData.First;
      While Not PolicyData.Eof Do
      Begin
        if PolicyDataBasketId.Value = 0 then
        begin
          PolicyData.Edit;
          PolicyDataCustomerId.Value := ACustomerId;
          PolicyData.Post;
        end;
        PolicyData.Next;
      End;
    Finally
      PolicyData.GotoBookmark(BM);
      PolicyData.FreeBookmark(BM);
    End;
  Finally
    PolicyData.EnableControls;
  End;
end;

procedure TMainData.UpdatePolicyData(APremium: TPremium);
begin
  PolicyDataStatus.Value := Policy_Status[TPolicyStatus.New];
  PolicyDataPremiumId.Value := APremium.Id;
  PolicyDataGadgetType.Value := APremium.Gadget_Type;
  PolicyDataModel.Value := APremium.Model;
  PolicyDataMake.Value := APremium.Make;
  PolicyDataDamageExcess.Value := APremium.DamageExcess;
  PolicyDataTheftExcess.Value := APremium.TheftExcess;
  PolicyDataInstalledMemory.Value := APremium.StandardMemory;

  If (APremium.GadgetType In Gadget_Types_Loss_Excluded) Or (Not BasketDataLossCover.Value) Then
  Begin
    PolicyDataLossCover.Value := False;
    PolicyDataLossPremium.Value := 0.00;
  End
  Else
  Begin
    PolicyDataLossCover.Value := True;
    PolicyDataLossPremium.Value := RoundTo(PolicyDataPremium.Value * (1 - Loss_Premium_Percent), -2);
  End;

  PolicyDataDiscountPercent.Value := BasketDataTotalDiscountRate.Value;

  TDataUtils.CalcPremiumValues(TGadgetUtils.StrToPremiumKind(BasketDataPremiumPeriod.Value), APremium);

  If PolicyData.State In dsEditModes Then
    PolicyData.Post;

end;

procedure TMainData.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DataConnection := TXDataWebConnection.Create(Self);
  WebClient := TXDataWebClient.Create(Self);
  CustomerData := TXDataWebDataSet.Create(Self);
  CustomerDataId := TIntegerField.Create(Self);
  CustomerDataDateAdded := TDateTimeField.Create(Self);
  CustomerDataAddedBy := TIntegerField.Create(Self);
  CustomerDataLastUpdatedBy := TIntegerField.Create(Self);
  CustomerDataLastUpdated := TDateTimeField.Create(Self);
  CustomerDataTitle := TStringField.Create(Self);
  CustomerDataForename := TStringField.Create(Self);
  CustomerDataInitials := TStringField.Create(Self);
  CustomerDataSurname := TStringField.Create(Self);
  CustomerDataAddress1 := TStringField.Create(Self);
  CustomerDataAddress2 := TStringField.Create(Self);
  CustomerDataAddress3 := TStringField.Create(Self);
  CustomerDataAddress4 := TStringField.Create(Self);
  CustomerDataPostCode := TStringField.Create(Self);
  CustomerDataEmail := TStringField.Create(Self);
  CustomerDataMobile := TStringField.Create(Self);
  CustomerDataHomePhone := TStringField.Create(Self);
  CustomerDataCreatedBy := TStringField.Create(Self);
  CustomerDataNoMarketing := TBooleanField.Create(Self);
  CustomerDataNote := TBlobField.Create(Self);
  CustomerDataAddressValidFrom := TDateTimeField.Create(Self);
  CustomerDataAffiliateId := TIntegerField.Create(Self);
  CustomerDataExportDate := TDateTimeField.Create(Self);
  CustomerDataAffiliateKind := TStringField.Create(Self);
  CustomerDataMarketingOK := TBooleanField.Create(Self);
  CustomerDataStripeId := TStringField.Create(Self);
  DirectDebit := TXDataWebDataSet.Create(Self);
  DirectDebitId := TIntegerField.Create(Self);
  DirectDebitAddedBy := TIntegerField.Create(Self);
  DirectDebitDateAdded := TDateTimeField.Create(Self);
  DirectDebitLastUpdatedBy := TIntegerField.Create(Self);
  DirectDebitLastUpdated := TDateTimeField.Create(Self);
  DirectDebitSessionId := TIntegerField.Create(Self);
  DirectDebitAccountName := TStringField.Create(Self);
  DirectDebitAccountNumber := TStringField.Create(Self);
  DirectDebitSortCode := TStringField.Create(Self);
  DirectDebitCustomerId := TIntegerField.Create(Self);
  DirectDebitBasketId := TIntegerField.Create(Self);
  DummyLookUp := TClientDataSet.Create(Self);
  DummyLookUpSource := TDataSource.Create(Self);
  PolicyData := TXDataWebDataSet.Create(Self);
  PolicyDataId := TIntegerField.Create(Self);
  PolicyDataBasketId := TIntegerField.Create(Self);
  PolicyDataCustomerId := TIntegerField.Create(Self);
  PolicyDataDateAdded := TDateTimeField.Create(Self);
  PolicyDataAddedBy := TIntegerField.Create(Self);
  PolicyDataLastUpdatedBy := TIntegerField.Create(Self);
  PolicyDataLastUpdated := TDateTimeField.Create(Self);
  PolicyDataPremiumId := TIntegerField.Create(Self);
  PolicyDataPremium := TFloatField.Create(Self);
  PolicyDataGadgetType := TStringField.Create(Self);
  PolicyDataModel := TStringField.Create(Self);
  PolicyDataMake := TStringField.Create(Self);
  PolicyDataInstalledMemory := TStringField.Create(Self);
  PolicyDataSerialNumber := TStringField.Create(Self);
  PolicyDataDateOfPurchase := TDateField.Create(Self);
  PolicyDataLossPremium := TFloatField.Create(Self);
  PolicyDataDamageExcess := TFloatField.Create(Self);
  PolicyDataTheftExcess := TFloatField.Create(Self);
  PolicyDataStatus := TStringField.Create(Self);
  PolicyDataPremiumPeriod := TStringField.Create(Self);
  PolicyDataPurchasePrice := TFloatField.Create(Self);
  PolicyDataDiscountPercent := TFloatField.Create(Self);
  PolicyDataNetPremium := TFloatField.Create(Self);
  PolicyDataGrossPremium := TFloatField.Create(Self);
  PolicyDataLossOption := TStringField.Create(Self);
  PolicyDataPremiumPeriodDisplay := TStringField.Create(Self);
  PolicyDataLossCover := TBooleanField.Create(Self);
  PolicyDataSummaryText := TStringField.Create(Self);
  PolicyDataLossCoverText := TStringField.Create(Self);
  BasketData := TXDataWebDataSet.Create(Self);
  BasketDataId := TIntegerField.Create(Self);
  BasketDataDateAdded := TDateTimeField.Create(Self);
  BasketDataAddedBy := TIntegerField.Create(Self);
  BasketDataLastUpdatedBy := TIntegerField.Create(Self);
  BasketDataLastUpdated := TDateTimeField.Create(Self);
  BasketDataGrossPremium := TFloatField.Create(Self);
  BasketDataSessionId := TIntegerField.Create(Self);
  BasketDataQuantityDiscountRate := TFloatField.Create(Self);
  BasketDataStatus := TStringField.Create(Self);
  BasketDataAffiliateKind := TStringField.Create(Self);
  BasketDataAffilliateId := TIntegerField.Create(Self);
  BasketDataNumberOfPolicies := TIntegerField.Create(Self);
  BasketDataDateCompleted := TDateTimeField.Create(Self);
  BasketDataCustomerId := TIntegerField.Create(Self);
  BasketDataLatestSessionId := TIntegerField.Create(Self);
  BasketDataBrandCode := TStringField.Create(Self);
  BasketDataNetPremium := TFloatField.Create(Self);
  BasketDataPremiumPeriod := TStringField.Create(Self);
  BasketDataEndDate := TDateTimeField.Create(Self);
  BasketDataStartDate := TDateTimeField.Create(Self);
  BasketDataLossCover := TBooleanField.Create(Self);
  BasketDataExportDate := TDateTimeField.Create(Self);
  BasketDataAffilliateDiscountRate := TFloatField.Create(Self);
  BasketDataBrandOfferKind := TStringField.Create(Self);
  BasketDataBrandOffer := TFloatField.Create(Self);
  BasketDataAcceptTerms := TBooleanField.Create(Self);
  BasketDataTotalDiscountRate := TFloatField.Create(Self);
  BasketDataPaymentId := TStringField.Create(Self);
  CardData := TXDataWebDataSet.Create(Self);
  CardDataId := TIntegerField.Create(Self);
  CardDataDateAdded := TDateTimeField.Create(Self);
  CardDataAddedBy := TIntegerField.Create(Self);
  CardDataLastUpdatedBy := TIntegerField.Create(Self);
  CardDataLastUpdated := TDateTimeField.Create(Self);
  CardDataSessionId := TIntegerField.Create(Self);
  CardDataCustomerId := TIntegerField.Create(Self);
  CardDataAccountName := TStringField.Create(Self);
  CardDataBasketId := TIntegerField.Create(Self);
  CardDataStripeSubscriptionID := TStringField.Create(Self);
  CardDataStripeCustomerID := TStringField.Create(Self);
  CardDataStripeStatus := TStringField.Create(Self);
  LossCoverData := TXDataWebDataSet.Create(Self);
  LossCoverDataId := TIntegerField.Create(Self);
  LossCoverDataPremium := TFloatField.Create(Self);
  LossCoverDataStatus := TStringField.Create(Self);
  LossCoverDataGadgetType := TStringField.Create(Self);
  LookUpData := TXDataWebDataSet.Create(Self);
  LookUpDataId := TIntegerField.Create(Self);
  LookUpDataKey := TStringField.Create(Self);
  LookUpDataValueType := TStringField.Create(Self);
  LookUpDataValue := TStringField.Create(Self);

  DataConnection.BeforeLoadDFMValues;
  WebClient.BeforeLoadDFMValues;
  CustomerData.BeforeLoadDFMValues;
  CustomerDataId.BeforeLoadDFMValues;
  CustomerDataDateAdded.BeforeLoadDFMValues;
  CustomerDataAddedBy.BeforeLoadDFMValues;
  CustomerDataLastUpdatedBy.BeforeLoadDFMValues;
  CustomerDataLastUpdated.BeforeLoadDFMValues;
  CustomerDataTitle.BeforeLoadDFMValues;
  CustomerDataForename.BeforeLoadDFMValues;
  CustomerDataInitials.BeforeLoadDFMValues;
  CustomerDataSurname.BeforeLoadDFMValues;
  CustomerDataAddress1.BeforeLoadDFMValues;
  CustomerDataAddress2.BeforeLoadDFMValues;
  CustomerDataAddress3.BeforeLoadDFMValues;
  CustomerDataAddress4.BeforeLoadDFMValues;
  CustomerDataPostCode.BeforeLoadDFMValues;
  CustomerDataEmail.BeforeLoadDFMValues;
  CustomerDataMobile.BeforeLoadDFMValues;
  CustomerDataHomePhone.BeforeLoadDFMValues;
  CustomerDataCreatedBy.BeforeLoadDFMValues;
  CustomerDataNoMarketing.BeforeLoadDFMValues;
  CustomerDataNote.BeforeLoadDFMValues;
  CustomerDataAddressValidFrom.BeforeLoadDFMValues;
  CustomerDataAffiliateId.BeforeLoadDFMValues;
  CustomerDataExportDate.BeforeLoadDFMValues;
  CustomerDataAffiliateKind.BeforeLoadDFMValues;
  CustomerDataMarketingOK.BeforeLoadDFMValues;
  CustomerDataStripeId.BeforeLoadDFMValues;
  DirectDebit.BeforeLoadDFMValues;
  DirectDebitId.BeforeLoadDFMValues;
  DirectDebitAddedBy.BeforeLoadDFMValues;
  DirectDebitDateAdded.BeforeLoadDFMValues;
  DirectDebitLastUpdatedBy.BeforeLoadDFMValues;
  DirectDebitLastUpdated.BeforeLoadDFMValues;
  DirectDebitSessionId.BeforeLoadDFMValues;
  DirectDebitAccountName.BeforeLoadDFMValues;
  DirectDebitAccountNumber.BeforeLoadDFMValues;
  DirectDebitSortCode.BeforeLoadDFMValues;
  DirectDebitCustomerId.BeforeLoadDFMValues;
  DirectDebitBasketId.BeforeLoadDFMValues;
  DummyLookUp.BeforeLoadDFMValues;
  DummyLookUpSource.BeforeLoadDFMValues;
  PolicyData.BeforeLoadDFMValues;
  PolicyDataId.BeforeLoadDFMValues;
  PolicyDataBasketId.BeforeLoadDFMValues;
  PolicyDataCustomerId.BeforeLoadDFMValues;
  PolicyDataDateAdded.BeforeLoadDFMValues;
  PolicyDataAddedBy.BeforeLoadDFMValues;
  PolicyDataLastUpdatedBy.BeforeLoadDFMValues;
  PolicyDataLastUpdated.BeforeLoadDFMValues;
  PolicyDataPremiumId.BeforeLoadDFMValues;
  PolicyDataPremium.BeforeLoadDFMValues;
  PolicyDataGadgetType.BeforeLoadDFMValues;
  PolicyDataModel.BeforeLoadDFMValues;
  PolicyDataMake.BeforeLoadDFMValues;
  PolicyDataInstalledMemory.BeforeLoadDFMValues;
  PolicyDataSerialNumber.BeforeLoadDFMValues;
  PolicyDataDateOfPurchase.BeforeLoadDFMValues;
  PolicyDataLossPremium.BeforeLoadDFMValues;
  PolicyDataDamageExcess.BeforeLoadDFMValues;
  PolicyDataTheftExcess.BeforeLoadDFMValues;
  PolicyDataStatus.BeforeLoadDFMValues;
  PolicyDataPremiumPeriod.BeforeLoadDFMValues;
  PolicyDataPurchasePrice.BeforeLoadDFMValues;
  PolicyDataDiscountPercent.BeforeLoadDFMValues;
  PolicyDataNetPremium.BeforeLoadDFMValues;
  PolicyDataGrossPremium.BeforeLoadDFMValues;
  PolicyDataLossOption.BeforeLoadDFMValues;
  PolicyDataPremiumPeriodDisplay.BeforeLoadDFMValues;
  PolicyDataLossCover.BeforeLoadDFMValues;
  PolicyDataSummaryText.BeforeLoadDFMValues;
  PolicyDataLossCoverText.BeforeLoadDFMValues;
  BasketData.BeforeLoadDFMValues;
  BasketDataId.BeforeLoadDFMValues;
  BasketDataDateAdded.BeforeLoadDFMValues;
  BasketDataAddedBy.BeforeLoadDFMValues;
  BasketDataLastUpdatedBy.BeforeLoadDFMValues;
  BasketDataLastUpdated.BeforeLoadDFMValues;
  BasketDataGrossPremium.BeforeLoadDFMValues;
  BasketDataSessionId.BeforeLoadDFMValues;
  BasketDataQuantityDiscountRate.BeforeLoadDFMValues;
  BasketDataStatus.BeforeLoadDFMValues;
  BasketDataAffiliateKind.BeforeLoadDFMValues;
  BasketDataAffilliateId.BeforeLoadDFMValues;
  BasketDataNumberOfPolicies.BeforeLoadDFMValues;
  BasketDataDateCompleted.BeforeLoadDFMValues;
  BasketDataCustomerId.BeforeLoadDFMValues;
  BasketDataLatestSessionId.BeforeLoadDFMValues;
  BasketDataBrandCode.BeforeLoadDFMValues;
  BasketDataNetPremium.BeforeLoadDFMValues;
  BasketDataPremiumPeriod.BeforeLoadDFMValues;
  BasketDataEndDate.BeforeLoadDFMValues;
  BasketDataStartDate.BeforeLoadDFMValues;
  BasketDataLossCover.BeforeLoadDFMValues;
  BasketDataExportDate.BeforeLoadDFMValues;
  BasketDataAffilliateDiscountRate.BeforeLoadDFMValues;
  BasketDataBrandOfferKind.BeforeLoadDFMValues;
  BasketDataBrandOffer.BeforeLoadDFMValues;
  BasketDataAcceptTerms.BeforeLoadDFMValues;
  BasketDataTotalDiscountRate.BeforeLoadDFMValues;
  BasketDataPaymentId.BeforeLoadDFMValues;
  CardData.BeforeLoadDFMValues;
  CardDataId.BeforeLoadDFMValues;
  CardDataDateAdded.BeforeLoadDFMValues;
  CardDataAddedBy.BeforeLoadDFMValues;
  CardDataLastUpdatedBy.BeforeLoadDFMValues;
  CardDataLastUpdated.BeforeLoadDFMValues;
  CardDataSessionId.BeforeLoadDFMValues;
  CardDataCustomerId.BeforeLoadDFMValues;
  CardDataAccountName.BeforeLoadDFMValues;
  CardDataBasketId.BeforeLoadDFMValues;
  CardDataStripeSubscriptionID.BeforeLoadDFMValues;
  CardDataStripeCustomerID.BeforeLoadDFMValues;
  CardDataStripeStatus.BeforeLoadDFMValues;
  LossCoverData.BeforeLoadDFMValues;
  LossCoverDataId.BeforeLoadDFMValues;
  LossCoverDataPremium.BeforeLoadDFMValues;
  LossCoverDataStatus.BeforeLoadDFMValues;
  LossCoverDataGadgetType.BeforeLoadDFMValues;
  LookUpData.BeforeLoadDFMValues;
  LookUpDataId.BeforeLoadDFMValues;
  LookUpDataKey.BeforeLoadDFMValues;
  LookUpDataValueType.BeforeLoadDFMValues;
  LookUpDataValue.BeforeLoadDFMValues;
  try
    Name := 'MainData';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 497;
    Width := 571;
    DataConnection.SetParentComponent(Self);
    DataConnection.Name := 'DataConnection';
    DataConnection.URL := 'http://localhost:2025/GadgetServer';
    SetEvent(DataConnection, Self, 'OnError', 'DataConnectionError');
    SetEvent(DataConnection, Self, 'OnRequest', 'DataConnectionRequest');
    DataConnection.Left := 64;
    DataConnection.Top := 32;
    WebClient.SetParentComponent(Self);
    WebClient.Name := 'WebClient';
    WebClient.Connection := DataConnection;
    SetEvent(WebClient, Self, 'OnError', 'WebClientError');
    WebClient.Left := 64;
    WebClient.Top := 104;
    CustomerData.SetParentComponent(Self);
    CustomerData.Name := 'CustomerData';
    CustomerData.AfterApplyUpdates := CustomerDataAfterApplyUpdates;
    CustomerData.AfterOpen := CustomerDataAfterOpen;
    SetEvent(CustomerData, Self, 'OnNewRecord', 'CustomerDataNewRecord');
    CustomerData.EntitySetName := 'Customer';
    CustomerData.Connection := DataConnection;
    CustomerData.Left := 376;
    CustomerData.Top := 176;
    CustomerDataId.SetParentComponent(CustomerData);
    CustomerDataId.Name := 'CustomerDataId';
    CustomerDataId.FieldName := 'Id';
    CustomerDataDateAdded.SetParentComponent(CustomerData);
    CustomerDataDateAdded.Name := 'CustomerDataDateAdded';
    CustomerDataDateAdded.FieldName := 'DateAdded';
    CustomerDataAddedBy.SetParentComponent(CustomerData);
    CustomerDataAddedBy.Name := 'CustomerDataAddedBy';
    CustomerDataAddedBy.FieldName := 'AddedBy';
    CustomerDataLastUpdatedBy.SetParentComponent(CustomerData);
    CustomerDataLastUpdatedBy.Name := 'CustomerDataLastUpdatedBy';
    CustomerDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    CustomerDataLastUpdated.SetParentComponent(CustomerData);
    CustomerDataLastUpdated.Name := 'CustomerDataLastUpdated';
    CustomerDataLastUpdated.FieldName := 'LastUpdated';
    CustomerDataTitle.SetParentComponent(CustomerData);
    CustomerDataTitle.Name := 'CustomerDataTitle';
    CustomerDataTitle.FieldName := 'Title';
    CustomerDataTitle.Size := 4;
    CustomerDataForename.SetParentComponent(CustomerData);
    CustomerDataForename.Name := 'CustomerDataForename';
    CustomerDataForename.FieldName := 'Forename';
    CustomerDataForename.Size := 25;
    CustomerDataInitials.SetParentComponent(CustomerData);
    CustomerDataInitials.Name := 'CustomerDataInitials';
    CustomerDataInitials.FieldName := 'Initials';
    CustomerDataInitials.Size := 4;
    CustomerDataSurname.SetParentComponent(CustomerData);
    CustomerDataSurname.Name := 'CustomerDataSurname';
    CustomerDataSurname.FieldName := 'Surname';
    CustomerDataSurname.Size := 30;
    CustomerDataAddress1.SetParentComponent(CustomerData);
    CustomerDataAddress1.Name := 'CustomerDataAddress1';
    CustomerDataAddress1.FieldName := 'Address1';
    CustomerDataAddress1.Size := 25;
    CustomerDataAddress2.SetParentComponent(CustomerData);
    CustomerDataAddress2.Name := 'CustomerDataAddress2';
    CustomerDataAddress2.FieldName := 'Address2';
    CustomerDataAddress2.Size := 25;
    CustomerDataAddress3.SetParentComponent(CustomerData);
    CustomerDataAddress3.Name := 'CustomerDataAddress3';
    CustomerDataAddress3.FieldName := 'Address3';
    CustomerDataAddress3.Size := 25;
    CustomerDataAddress4.SetParentComponent(CustomerData);
    CustomerDataAddress4.Name := 'CustomerDataAddress4';
    CustomerDataAddress4.FieldName := 'Address4';
    CustomerDataAddress4.Size := 25;
    CustomerDataPostCode.SetParentComponent(CustomerData);
    CustomerDataPostCode.Name := 'CustomerDataPostCode';
    CustomerDataPostCode.FieldName := 'PostCode';
    CustomerDataPostCode.Size := 9;
    CustomerDataEmail.SetParentComponent(CustomerData);
    CustomerDataEmail.Name := 'CustomerDataEmail';
    CustomerDataEmail.FieldName := 'Email';
    CustomerDataEmail.Size := 75;
    CustomerDataMobile.SetParentComponent(CustomerData);
    CustomerDataMobile.Name := 'CustomerDataMobile';
    CustomerDataMobile.FieldName := 'Mobile';
    CustomerDataMobile.Size := 25;
    CustomerDataHomePhone.SetParentComponent(CustomerData);
    CustomerDataHomePhone.Name := 'CustomerDataHomePhone';
    CustomerDataHomePhone.FieldName := 'HomePhone';
    CustomerDataHomePhone.Size := 25;
    CustomerDataCreatedBy.SetParentComponent(CustomerData);
    CustomerDataCreatedBy.Name := 'CustomerDataCreatedBy';
    CustomerDataCreatedBy.FieldName := 'CreatedBy';
    CustomerDataCreatedBy.Size := 8;
    CustomerDataNoMarketing.SetParentComponent(CustomerData);
    CustomerDataNoMarketing.Name := 'CustomerDataNoMarketing';
    CustomerDataNoMarketing.FieldName := 'NoMarketing';
    CustomerDataNote.SetParentComponent(CustomerData);
    CustomerDataNote.Name := 'CustomerDataNote';
    CustomerDataNote.FieldName := 'Note';
    CustomerDataAddressValidFrom.SetParentComponent(CustomerData);
    CustomerDataAddressValidFrom.Name := 'CustomerDataAddressValidFrom';
    CustomerDataAddressValidFrom.FieldName := 'AddressValidFrom';
    CustomerDataAffiliateId.SetParentComponent(CustomerData);
    CustomerDataAffiliateId.Name := 'CustomerDataAffiliateId';
    CustomerDataAffiliateId.FieldName := 'AffiliateId';
    CustomerDataExportDate.SetParentComponent(CustomerData);
    CustomerDataExportDate.Name := 'CustomerDataExportDate';
    CustomerDataExportDate.FieldName := 'ExportDate';
    CustomerDataAffiliateKind.SetParentComponent(CustomerData);
    CustomerDataAffiliateKind.Name := 'CustomerDataAffiliateKind';
    CustomerDataAffiliateKind.FieldName := 'AffiliateKind';
    CustomerDataAffiliateKind.Size := 10;
    CustomerDataMarketingOK.SetParentComponent(CustomerData);
    CustomerDataMarketingOK.Name := 'CustomerDataMarketingOK';
    CustomerDataMarketingOK.FieldName := 'MarketingOK';
    CustomerDataStripeId.SetParentComponent(CustomerData);
    CustomerDataStripeId.Name := 'CustomerDataStripeId';
    CustomerDataStripeId.FieldName := 'StripeId';
    CustomerDataStripeId.Size := 50;
    DirectDebit.SetParentComponent(Self);
    DirectDebit.Name := 'DirectDebit';
    SetEvent(DirectDebit, Self, 'OnNewRecord', 'DirectDebitNewRecord');
    DirectDebit.EntitySetName := 'DirectDebitInfo';
    DirectDebit.Connection := DataConnection;
    DirectDebit.Left := 376;
    DirectDebit.Top := 320;
    DirectDebitId.SetParentComponent(DirectDebit);
    DirectDebitId.Name := 'DirectDebitId';
    DirectDebitId.FieldName := 'Id';
    DirectDebitAddedBy.SetParentComponent(DirectDebit);
    DirectDebitAddedBy.Name := 'DirectDebitAddedBy';
    DirectDebitAddedBy.FieldName := 'AddedBy';
    DirectDebitDateAdded.SetParentComponent(DirectDebit);
    DirectDebitDateAdded.Name := 'DirectDebitDateAdded';
    DirectDebitDateAdded.FieldName := 'DateAdded';
    DirectDebitLastUpdatedBy.SetParentComponent(DirectDebit);
    DirectDebitLastUpdatedBy.Name := 'DirectDebitLastUpdatedBy';
    DirectDebitLastUpdatedBy.FieldName := 'LastUpdatedBy';
    DirectDebitLastUpdated.SetParentComponent(DirectDebit);
    DirectDebitLastUpdated.Name := 'DirectDebitLastUpdated';
    DirectDebitLastUpdated.FieldName := 'LastUpdated';
    DirectDebitSessionId.SetParentComponent(DirectDebit);
    DirectDebitSessionId.Name := 'DirectDebitSessionId';
    DirectDebitSessionId.FieldName := 'SessionId';
    DirectDebitSessionId.Required := True;
    DirectDebitAccountName.SetParentComponent(DirectDebit);
    DirectDebitAccountName.Name := 'DirectDebitAccountName';
    DirectDebitAccountName.FieldName := 'AccountName';
    DirectDebitAccountName.Required := True;
    DirectDebitAccountName.Size := 50;
    DirectDebitAccountNumber.SetParentComponent(DirectDebit);
    DirectDebitAccountNumber.Name := 'DirectDebitAccountNumber';
    DirectDebitAccountNumber.FieldName := 'AccountNumber';
    DirectDebitAccountNumber.Required := True;
    DirectDebitAccountNumber.Size := 12;
    DirectDebitSortCode.SetParentComponent(DirectDebit);
    DirectDebitSortCode.Name := 'DirectDebitSortCode';
    DirectDebitSortCode.FieldName := 'SortCode';
    DirectDebitSortCode.Required := True;
    DirectDebitSortCode.Size := 10;
    DirectDebitCustomerId.SetParentComponent(DirectDebit);
    DirectDebitCustomerId.Name := 'DirectDebitCustomerId';
    DirectDebitCustomerId.FieldName := 'CustomerId';
    DirectDebitCustomerId.Required := True;
    DirectDebitBasketId.SetParentComponent(DirectDebit);
    DirectDebitBasketId.Name := 'DirectDebitBasketId';
    DirectDebitBasketId.FieldName := 'BasketId';
    DirectDebitBasketId.Required := True;
    DummyLookUp.SetParentComponent(Self);
    DummyLookUp.Name := 'DummyLookUp';
    DummyLookUp.Left := 256;
    DummyLookUp.Top := 48;
    DummyLookUpSource.SetParentComponent(Self);
    DummyLookUpSource.Name := 'DummyLookUpSource';
    DummyLookUpSource.DataSet := DummyLookUp;
    DummyLookUpSource.Left := 376;
    DummyLookUpSource.Top := 48;
    PolicyData.SetParentComponent(Self);
    PolicyData.Name := 'PolicyData';
    PolicyData.Filter := 'Status <> '#39'Deleted'#39;
    PolicyData.AfterApplyUpdates := PolicyDataAfterApplyUpdates;
    PolicyData.AfterOpen := PolicyDataAfterOpen;
    SetEvent(PolicyData, Self, 'OnCalcFields', 'PolicyDataCalcFields');
    SetEvent(PolicyData, Self, 'OnNewRecord', 'PolicyDataNewRecord');
    PolicyData.EntitySetName := 'Policy';
    PolicyData.Connection := DataConnection;
    PolicyData.Left := 376;
    PolicyData.Top := 240;
    PolicyDataId.SetParentComponent(PolicyData);
    PolicyDataId.Name := 'PolicyDataId';
    PolicyDataId.FieldName := 'Id';
    PolicyDataBasketId.SetParentComponent(PolicyData);
    PolicyDataBasketId.Name := 'PolicyDataBasketId';
    PolicyDataBasketId.FieldName := 'BasketId';
    PolicyDataCustomerId.SetParentComponent(PolicyData);
    PolicyDataCustomerId.Name := 'PolicyDataCustomerId';
    PolicyDataCustomerId.FieldName := 'CustomerId';
    PolicyDataDateAdded.SetParentComponent(PolicyData);
    PolicyDataDateAdded.Name := 'PolicyDataDateAdded';
    PolicyDataDateAdded.FieldName := 'DateAdded';
    PolicyDataAddedBy.SetParentComponent(PolicyData);
    PolicyDataAddedBy.Name := 'PolicyDataAddedBy';
    PolicyDataAddedBy.FieldName := 'AddedBy';
    PolicyDataLastUpdatedBy.SetParentComponent(PolicyData);
    PolicyDataLastUpdatedBy.Name := 'PolicyDataLastUpdatedBy';
    PolicyDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    PolicyDataLastUpdated.SetParentComponent(PolicyData);
    PolicyDataLastUpdated.Name := 'PolicyDataLastUpdated';
    PolicyDataLastUpdated.FieldName := 'LastUpdated';
    PolicyDataPremiumId.SetParentComponent(PolicyData);
    PolicyDataPremiumId.Name := 'PolicyDataPremiumId';
    PolicyDataPremiumId.FieldName := 'PremiumId';
    PolicyDataPremium.SetParentComponent(PolicyData);
    PolicyDataPremium.Name := 'PolicyDataPremium';
    PolicyDataPremium.FieldName := 'Premium';
    PolicyDataGadgetType.SetParentComponent(PolicyData);
    PolicyDataGadgetType.Name := 'PolicyDataGadgetType';
    PolicyDataGadgetType.FieldName := 'GadgetType';
    PolicyDataGadgetType.Size := 12;
    PolicyDataModel.SetParentComponent(PolicyData);
    PolicyDataModel.Name := 'PolicyDataModel';
    PolicyDataModel.FieldName := 'Model';
    PolicyDataModel.Size := 50;
    PolicyDataMake.SetParentComponent(PolicyData);
    PolicyDataMake.Name := 'PolicyDataMake';
    PolicyDataMake.FieldName := 'Make';
    PolicyDataMake.Size := 100;
    PolicyDataInstalledMemory.SetParentComponent(PolicyData);
    PolicyDataInstalledMemory.Name := 'PolicyDataInstalledMemory';
    PolicyDataInstalledMemory.FieldName := 'InstalledMemory';
    PolicyDataInstalledMemory.Size := 10;
    PolicyDataSerialNumber.SetParentComponent(PolicyData);
    PolicyDataSerialNumber.Name := 'PolicyDataSerialNumber';
    PolicyDataSerialNumber.FieldName := 'SerialNumber';
    PolicyDataSerialNumber.Size := 75;
    PolicyDataDateOfPurchase.SetParentComponent(PolicyData);
    PolicyDataDateOfPurchase.Name := 'PolicyDataDateOfPurchase';
    PolicyDataDateOfPurchase.FieldName := 'DateOfPurchase';
    PolicyDataLossPremium.SetParentComponent(PolicyData);
    PolicyDataLossPremium.Name := 'PolicyDataLossPremium';
    PolicyDataLossPremium.FieldName := 'LossPremium';
    PolicyDataDamageExcess.SetParentComponent(PolicyData);
    PolicyDataDamageExcess.Name := 'PolicyDataDamageExcess';
    PolicyDataDamageExcess.FieldName := 'DamageExcess';
    SetEvent(PolicyDataDamageExcess, Self, 'OnGetText', 'PolicyDataDamageExcessGetText');
    PolicyDataTheftExcess.SetParentComponent(PolicyData);
    PolicyDataTheftExcess.Name := 'PolicyDataTheftExcess';
    PolicyDataTheftExcess.FieldName := 'TheftExcess';
    SetEvent(PolicyDataTheftExcess, Self, 'OnGetText', 'PolicyDataTheftExcessGetText');
    PolicyDataStatus.SetParentComponent(PolicyData);
    PolicyDataStatus.Name := 'PolicyDataStatus';
    PolicyDataStatus.FieldName := 'Status';
    PolicyDataStatus.Size := 9;
    PolicyDataPremiumPeriod.SetParentComponent(PolicyData);
    PolicyDataPremiumPeriod.Name := 'PolicyDataPremiumPeriod';
    PolicyDataPremiumPeriod.FieldName := 'PremiumPeriod';
    PolicyDataPremiumPeriod.Size := 12;
    PolicyDataPurchasePrice.SetParentComponent(PolicyData);
    PolicyDataPurchasePrice.Name := 'PolicyDataPurchasePrice';
    PolicyDataPurchasePrice.FieldName := 'PurchasePrice';
    SetEvent(PolicyDataPurchasePrice, Self, 'OnGetText', 'PolicyDataPurchasePriceGetText');
    PolicyDataDiscountPercent.SetParentComponent(PolicyData);
    PolicyDataDiscountPercent.Name := 'PolicyDataDiscountPercent';
    PolicyDataDiscountPercent.FieldName := 'DiscountPercent';
    PolicyDataNetPremium.SetParentComponent(PolicyData);
    PolicyDataNetPremium.Name := 'PolicyDataNetPremium';
    PolicyDataNetPremium.FieldName := 'NetPremium';
    PolicyDataNetPremium.DisplayFormat := #163'#,##0.00';
    PolicyDataGrossPremium.SetParentComponent(PolicyData);
    PolicyDataGrossPremium.Name := 'PolicyDataGrossPremium';
    PolicyDataGrossPremium.FieldName := 'GrossPremium';
    PolicyDataLossOption.SetParentComponent(PolicyData);
    PolicyDataLossOption.Name := 'PolicyDataLossOption';
    PolicyDataLossOption.FieldKind := fkCalculated;
    PolicyDataLossOption.FieldName := 'LossOption';
    PolicyDataLossOption.Size := 50;
    PolicyDataLossOption.Calculated := True;
    PolicyDataPremiumPeriodDisplay.SetParentComponent(PolicyData);
    PolicyDataPremiumPeriodDisplay.Name := 'PolicyDataPremiumPeriodDisplay';
    PolicyDataPremiumPeriodDisplay.FieldKind := fkCalculated;
    PolicyDataPremiumPeriodDisplay.FieldName := 'PremiumPeriodDisplay';
    PolicyDataPremiumPeriodDisplay.Size := 25;
    PolicyDataPremiumPeriodDisplay.Calculated := True;
    PolicyDataLossCover.SetParentComponent(PolicyData);
    PolicyDataLossCover.Name := 'PolicyDataLossCover';
    PolicyDataLossCover.FieldName := 'LossCover';
    PolicyDataSummaryText.SetParentComponent(PolicyData);
    PolicyDataSummaryText.Name := 'PolicyDataSummaryText';
    PolicyDataSummaryText.FieldKind := fkCalculated;
    PolicyDataSummaryText.FieldName := 'SummaryText';
    PolicyDataSummaryText.Size := 255;
    PolicyDataSummaryText.Calculated := True;
    PolicyDataLossCoverText.SetParentComponent(PolicyData);
    PolicyDataLossCoverText.Name := 'PolicyDataLossCoverText';
    PolicyDataLossCoverText.FieldKind := fkCalculated;
    PolicyDataLossCoverText.FieldName := 'LossCoverText';
    PolicyDataLossCoverText.Size := 3;
    PolicyDataLossCoverText.Calculated := True;
    BasketData.SetParentComponent(Self);
    BasketData.Name := 'BasketData';
    BasketData.AfterApplyUpdates := BasketDataAfterApplyUpdates;
    BasketData.AfterOpen := BasketDataAfterOpen;
    SetEvent(BasketData, Self, 'OnCalcFields', 'BasketDataCalcFields');
    SetEvent(BasketData, Self, 'OnNewRecord', 'BasketDataNewRecord');
    BasketData.EntitySetName := 'WebBasket';
    BasketData.Connection := DataConnection;
    BasketData.Left := 264;
    BasketData.Top := 208;
    BasketDataId.SetParentComponent(BasketData);
    BasketDataId.Name := 'BasketDataId';
    BasketDataId.FieldName := 'Id';
    BasketDataDateAdded.SetParentComponent(BasketData);
    BasketDataDateAdded.Name := 'BasketDataDateAdded';
    BasketDataDateAdded.FieldName := 'DateAdded';
    BasketDataAddedBy.SetParentComponent(BasketData);
    BasketDataAddedBy.Name := 'BasketDataAddedBy';
    BasketDataAddedBy.FieldName := 'AddedBy';
    BasketDataLastUpdatedBy.SetParentComponent(BasketData);
    BasketDataLastUpdatedBy.Name := 'BasketDataLastUpdatedBy';
    BasketDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    BasketDataLastUpdated.SetParentComponent(BasketData);
    BasketDataLastUpdated.Name := 'BasketDataLastUpdated';
    BasketDataLastUpdated.FieldName := 'LastUpdated';
    BasketDataGrossPremium.SetParentComponent(BasketData);
    BasketDataGrossPremium.Name := 'BasketDataGrossPremium';
    BasketDataGrossPremium.FieldName := 'GrossPremium';
    BasketDataSessionId.SetParentComponent(BasketData);
    BasketDataSessionId.Name := 'BasketDataSessionId';
    BasketDataSessionId.FieldName := 'SessionId';
    BasketDataQuantityDiscountRate.SetParentComponent(BasketData);
    BasketDataQuantityDiscountRate.Name := 'BasketDataQuantityDiscountRate';
    BasketDataQuantityDiscountRate.FieldName := 'QuantityDiscountRate';
    BasketDataStatus.SetParentComponent(BasketData);
    BasketDataStatus.Name := 'BasketDataStatus';
    BasketDataStatus.FieldName := 'Status';
    BasketDataStatus.Size := 9;
    BasketDataAffiliateKind.SetParentComponent(BasketData);
    BasketDataAffiliateKind.Name := 'BasketDataAffiliateKind';
    BasketDataAffiliateKind.FieldName := 'AffiliateKind';
    BasketDataAffiliateKind.Size := 10;
    BasketDataAffilliateId.SetParentComponent(BasketData);
    BasketDataAffilliateId.Name := 'BasketDataAffilliateId';
    BasketDataAffilliateId.FieldName := 'AffilliateId';
    BasketDataNumberOfPolicies.SetParentComponent(BasketData);
    BasketDataNumberOfPolicies.Name := 'BasketDataNumberOfPolicies';
    BasketDataNumberOfPolicies.FieldName := 'NumberOfPolicies';
    BasketDataDateCompleted.SetParentComponent(BasketData);
    BasketDataDateCompleted.Name := 'BasketDataDateCompleted';
    BasketDataDateCompleted.FieldName := 'DateCompleted';
    BasketDataCustomerId.SetParentComponent(BasketData);
    BasketDataCustomerId.Name := 'BasketDataCustomerId';
    BasketDataCustomerId.FieldName := 'CustomerId';
    BasketDataLatestSessionId.SetParentComponent(BasketData);
    BasketDataLatestSessionId.Name := 'BasketDataLatestSessionId';
    BasketDataLatestSessionId.FieldName := 'LatestSessionId';
    BasketDataBrandCode.SetParentComponent(BasketData);
    BasketDataBrandCode.Name := 'BasketDataBrandCode';
    BasketDataBrandCode.FieldName := 'BrandCode';
    BasketDataBrandCode.Size := 255;
    BasketDataNetPremium.SetParentComponent(BasketData);
    BasketDataNetPremium.Name := 'BasketDataNetPremium';
    BasketDataNetPremium.FieldName := 'NetPremium';
    BasketDataPremiumPeriod.SetParentComponent(BasketData);
    BasketDataPremiumPeriod.Name := 'BasketDataPremiumPeriod';
    BasketDataPremiumPeriod.FieldName := 'PremiumPeriod';
    BasketDataPremiumPeriod.Size := 12;
    BasketDataEndDate.SetParentComponent(BasketData);
    BasketDataEndDate.Name := 'BasketDataEndDate';
    BasketDataEndDate.FieldName := 'EndDate';
    BasketDataStartDate.SetParentComponent(BasketData);
    BasketDataStartDate.Name := 'BasketDataStartDate';
    BasketDataStartDate.FieldName := 'StartDate';
    BasketDataLossCover.SetParentComponent(BasketData);
    BasketDataLossCover.Name := 'BasketDataLossCover';
    BasketDataLossCover.FieldName := 'LossCover';
    BasketDataExportDate.SetParentComponent(BasketData);
    BasketDataExportDate.Name := 'BasketDataExportDate';
    BasketDataExportDate.FieldName := 'ExportDate';
    BasketDataAffilliateDiscountRate.SetParentComponent(BasketData);
    BasketDataAffilliateDiscountRate.Name := 'BasketDataAffilliateDiscountRate';
    BasketDataAffilliateDiscountRate.FieldName := 'AffilliateDiscountRate';
    BasketDataBrandOfferKind.SetParentComponent(BasketData);
    BasketDataBrandOfferKind.Name := 'BasketDataBrandOfferKind';
    BasketDataBrandOfferKind.FieldName := 'BrandOfferKind';
    BasketDataBrandOfferKind.Size := 8;
    BasketDataBrandOffer.SetParentComponent(BasketData);
    BasketDataBrandOffer.Name := 'BasketDataBrandOffer';
    BasketDataBrandOffer.FieldName := 'BrandOffer';
    BasketDataAcceptTerms.SetParentComponent(BasketData);
    BasketDataAcceptTerms.Name := 'BasketDataAcceptTerms';
    BasketDataAcceptTerms.FieldName := 'AcceptTerms';
    BasketDataAcceptTerms.Required := True;
    BasketDataTotalDiscountRate.SetParentComponent(BasketData);
    BasketDataTotalDiscountRate.Name := 'BasketDataTotalDiscountRate';
    BasketDataTotalDiscountRate.FieldKind := fkCalculated;
    BasketDataTotalDiscountRate.FieldName := 'TotalDiscountRate';
    BasketDataTotalDiscountRate.Calculated := True;
    BasketDataPaymentId.SetParentComponent(BasketData);
    BasketDataPaymentId.Name := 'BasketDataPaymentId';
    BasketDataPaymentId.FieldName := 'PaymentId';
    BasketDataPaymentId.Size := 255;
    CardData.SetParentComponent(Self);
    CardData.Name := 'CardData';
    SetEvent(CardData, Self, 'OnNewRecord', 'CardDataNewRecord');
    CardData.EntitySetName := 'CardInfo';
    CardData.Connection := DataConnection;
    CardData.Left := 272;
    CardData.Top := 320;
    CardDataId.SetParentComponent(CardData);
    CardDataId.Name := 'CardDataId';
    CardDataId.FieldName := 'Id';
    CardDataDateAdded.SetParentComponent(CardData);
    CardDataDateAdded.Name := 'CardDataDateAdded';
    CardDataDateAdded.FieldName := 'DateAdded';
    CardDataAddedBy.SetParentComponent(CardData);
    CardDataAddedBy.Name := 'CardDataAddedBy';
    CardDataAddedBy.FieldName := 'AddedBy';
    CardDataLastUpdatedBy.SetParentComponent(CardData);
    CardDataLastUpdatedBy.Name := 'CardDataLastUpdatedBy';
    CardDataLastUpdatedBy.FieldName := 'LastUpdatedBy';
    CardDataLastUpdated.SetParentComponent(CardData);
    CardDataLastUpdated.Name := 'CardDataLastUpdated';
    CardDataLastUpdated.FieldName := 'LastUpdated';
    CardDataSessionId.SetParentComponent(CardData);
    CardDataSessionId.Name := 'CardDataSessionId';
    CardDataSessionId.FieldName := 'SessionId';
    CardDataSessionId.Required := True;
    CardDataCustomerId.SetParentComponent(CardData);
    CardDataCustomerId.Name := 'CardDataCustomerId';
    CardDataCustomerId.FieldName := 'CustomerId';
    CardDataCustomerId.Required := True;
    CardDataAccountName.SetParentComponent(CardData);
    CardDataAccountName.Name := 'CardDataAccountName';
    CardDataAccountName.FieldName := 'AccountName';
    CardDataAccountName.Size := 50;
    CardDataBasketId.SetParentComponent(CardData);
    CardDataBasketId.Name := 'CardDataBasketId';
    CardDataBasketId.FieldName := 'BasketId';
    CardDataBasketId.Required := True;
    CardDataStripeSubscriptionID.SetParentComponent(CardData);
    CardDataStripeSubscriptionID.Name := 'CardDataStripeSubscriptionID';
    CardDataStripeSubscriptionID.FieldName := 'StripeSubscriptionID';
    CardDataStripeSubscriptionID.Required := True;
    CardDataStripeSubscriptionID.Size := 50;
    CardDataStripeCustomerID.SetParentComponent(CardData);
    CardDataStripeCustomerID.Name := 'CardDataStripeCustomerID';
    CardDataStripeCustomerID.FieldName := 'StripeCustomerID';
    CardDataStripeCustomerID.Required := True;
    CardDataStripeCustomerID.Size := 50;
    CardDataStripeStatus.SetParentComponent(CardData);
    CardDataStripeStatus.Name := 'CardDataStripeStatus';
    CardDataStripeStatus.FieldName := 'StripeStatus';
    CardDataStripeStatus.Required := True;
    CardDataStripeStatus.Size := 12;
    LossCoverData.SetParentComponent(Self);
    LossCoverData.Name := 'LossCoverData';
    LossCoverData.EntitySetName := 'LossCover';
    LossCoverData.Connection := DataConnection;
    LossCoverData.Left := 56;
    LossCoverData.Top := 200;
    LossCoverDataId.SetParentComponent(LossCoverData);
    LossCoverDataId.Name := 'LossCoverDataId';
    LossCoverDataId.FieldName := 'Id';
    LossCoverDataId.Required := True;
    LossCoverDataPremium.SetParentComponent(LossCoverData);
    LossCoverDataPremium.Name := 'LossCoverDataPremium';
    LossCoverDataPremium.FieldName := 'Premium';
    LossCoverDataStatus.SetParentComponent(LossCoverData);
    LossCoverDataStatus.Name := 'LossCoverDataStatus';
    LossCoverDataStatus.FieldName := 'Status';
    LossCoverDataStatus.Required := True;
    LossCoverDataStatus.Size := 12;
    LossCoverDataGadgetType.SetParentComponent(LossCoverData);
    LossCoverDataGadgetType.Name := 'LossCoverDataGadgetType';
    LossCoverDataGadgetType.FieldName := 'GadgetType';
    LossCoverDataGadgetType.Required := True;
    LossCoverDataGadgetType.Size := 12;
    LookUpData.SetParentComponent(Self);
    LookUpData.Name := 'LookUpData';
    LookUpData.EntitySetName := 'SMXLookUp';
    LookUpData.Connection := DataConnection;
    LookUpData.Left := 171;
    LookUpData.Top := 75;
    LookUpDataId.SetParentComponent(LookUpData);
    LookUpDataId.Name := 'LookUpDataId';
    LookUpDataId.FieldName := 'Id';
    LookUpDataId.Required := True;
    LookUpDataKey.SetParentComponent(LookUpData);
    LookUpDataKey.Name := 'LookUpDataKey';
    LookUpDataKey.FieldName := 'Key';
    LookUpDataKey.Size := 25;
    LookUpDataValueType.SetParentComponent(LookUpData);
    LookUpDataValueType.Name := 'LookUpDataValueType';
    LookUpDataValueType.FieldName := 'ValueType';
    LookUpDataValueType.Required := True;
    LookUpDataValueType.Size := 8;
    LookUpDataValue.SetParentComponent(LookUpData);
    LookUpDataValue.Name := 'LookUpDataValue';
    LookUpDataValue.FieldName := 'Value';
    LookUpDataValue.Size := 1000;
  finally
    DataConnection.AfterLoadDFMValues;
    WebClient.AfterLoadDFMValues;
    CustomerData.AfterLoadDFMValues;
    CustomerDataId.AfterLoadDFMValues;
    CustomerDataDateAdded.AfterLoadDFMValues;
    CustomerDataAddedBy.AfterLoadDFMValues;
    CustomerDataLastUpdatedBy.AfterLoadDFMValues;
    CustomerDataLastUpdated.AfterLoadDFMValues;
    CustomerDataTitle.AfterLoadDFMValues;
    CustomerDataForename.AfterLoadDFMValues;
    CustomerDataInitials.AfterLoadDFMValues;
    CustomerDataSurname.AfterLoadDFMValues;
    CustomerDataAddress1.AfterLoadDFMValues;
    CustomerDataAddress2.AfterLoadDFMValues;
    CustomerDataAddress3.AfterLoadDFMValues;
    CustomerDataAddress4.AfterLoadDFMValues;
    CustomerDataPostCode.AfterLoadDFMValues;
    CustomerDataEmail.AfterLoadDFMValues;
    CustomerDataMobile.AfterLoadDFMValues;
    CustomerDataHomePhone.AfterLoadDFMValues;
    CustomerDataCreatedBy.AfterLoadDFMValues;
    CustomerDataNoMarketing.AfterLoadDFMValues;
    CustomerDataNote.AfterLoadDFMValues;
    CustomerDataAddressValidFrom.AfterLoadDFMValues;
    CustomerDataAffiliateId.AfterLoadDFMValues;
    CustomerDataExportDate.AfterLoadDFMValues;
    CustomerDataAffiliateKind.AfterLoadDFMValues;
    CustomerDataMarketingOK.AfterLoadDFMValues;
    CustomerDataStripeId.AfterLoadDFMValues;
    DirectDebit.AfterLoadDFMValues;
    DirectDebitId.AfterLoadDFMValues;
    DirectDebitAddedBy.AfterLoadDFMValues;
    DirectDebitDateAdded.AfterLoadDFMValues;
    DirectDebitLastUpdatedBy.AfterLoadDFMValues;
    DirectDebitLastUpdated.AfterLoadDFMValues;
    DirectDebitSessionId.AfterLoadDFMValues;
    DirectDebitAccountName.AfterLoadDFMValues;
    DirectDebitAccountNumber.AfterLoadDFMValues;
    DirectDebitSortCode.AfterLoadDFMValues;
    DirectDebitCustomerId.AfterLoadDFMValues;
    DirectDebitBasketId.AfterLoadDFMValues;
    DummyLookUp.AfterLoadDFMValues;
    DummyLookUpSource.AfterLoadDFMValues;
    PolicyData.AfterLoadDFMValues;
    PolicyDataId.AfterLoadDFMValues;
    PolicyDataBasketId.AfterLoadDFMValues;
    PolicyDataCustomerId.AfterLoadDFMValues;
    PolicyDataDateAdded.AfterLoadDFMValues;
    PolicyDataAddedBy.AfterLoadDFMValues;
    PolicyDataLastUpdatedBy.AfterLoadDFMValues;
    PolicyDataLastUpdated.AfterLoadDFMValues;
    PolicyDataPremiumId.AfterLoadDFMValues;
    PolicyDataPremium.AfterLoadDFMValues;
    PolicyDataGadgetType.AfterLoadDFMValues;
    PolicyDataModel.AfterLoadDFMValues;
    PolicyDataMake.AfterLoadDFMValues;
    PolicyDataInstalledMemory.AfterLoadDFMValues;
    PolicyDataSerialNumber.AfterLoadDFMValues;
    PolicyDataDateOfPurchase.AfterLoadDFMValues;
    PolicyDataLossPremium.AfterLoadDFMValues;
    PolicyDataDamageExcess.AfterLoadDFMValues;
    PolicyDataTheftExcess.AfterLoadDFMValues;
    PolicyDataStatus.AfterLoadDFMValues;
    PolicyDataPremiumPeriod.AfterLoadDFMValues;
    PolicyDataPurchasePrice.AfterLoadDFMValues;
    PolicyDataDiscountPercent.AfterLoadDFMValues;
    PolicyDataNetPremium.AfterLoadDFMValues;
    PolicyDataGrossPremium.AfterLoadDFMValues;
    PolicyDataLossOption.AfterLoadDFMValues;
    PolicyDataPremiumPeriodDisplay.AfterLoadDFMValues;
    PolicyDataLossCover.AfterLoadDFMValues;
    PolicyDataSummaryText.AfterLoadDFMValues;
    PolicyDataLossCoverText.AfterLoadDFMValues;
    BasketData.AfterLoadDFMValues;
    BasketDataId.AfterLoadDFMValues;
    BasketDataDateAdded.AfterLoadDFMValues;
    BasketDataAddedBy.AfterLoadDFMValues;
    BasketDataLastUpdatedBy.AfterLoadDFMValues;
    BasketDataLastUpdated.AfterLoadDFMValues;
    BasketDataGrossPremium.AfterLoadDFMValues;
    BasketDataSessionId.AfterLoadDFMValues;
    BasketDataQuantityDiscountRate.AfterLoadDFMValues;
    BasketDataStatus.AfterLoadDFMValues;
    BasketDataAffiliateKind.AfterLoadDFMValues;
    BasketDataAffilliateId.AfterLoadDFMValues;
    BasketDataNumberOfPolicies.AfterLoadDFMValues;
    BasketDataDateCompleted.AfterLoadDFMValues;
    BasketDataCustomerId.AfterLoadDFMValues;
    BasketDataLatestSessionId.AfterLoadDFMValues;
    BasketDataBrandCode.AfterLoadDFMValues;
    BasketDataNetPremium.AfterLoadDFMValues;
    BasketDataPremiumPeriod.AfterLoadDFMValues;
    BasketDataEndDate.AfterLoadDFMValues;
    BasketDataStartDate.AfterLoadDFMValues;
    BasketDataLossCover.AfterLoadDFMValues;
    BasketDataExportDate.AfterLoadDFMValues;
    BasketDataAffilliateDiscountRate.AfterLoadDFMValues;
    BasketDataBrandOfferKind.AfterLoadDFMValues;
    BasketDataBrandOffer.AfterLoadDFMValues;
    BasketDataAcceptTerms.AfterLoadDFMValues;
    BasketDataTotalDiscountRate.AfterLoadDFMValues;
    BasketDataPaymentId.AfterLoadDFMValues;
    CardData.AfterLoadDFMValues;
    CardDataId.AfterLoadDFMValues;
    CardDataDateAdded.AfterLoadDFMValues;
    CardDataAddedBy.AfterLoadDFMValues;
    CardDataLastUpdatedBy.AfterLoadDFMValues;
    CardDataLastUpdated.AfterLoadDFMValues;
    CardDataSessionId.AfterLoadDFMValues;
    CardDataCustomerId.AfterLoadDFMValues;
    CardDataAccountName.AfterLoadDFMValues;
    CardDataBasketId.AfterLoadDFMValues;
    CardDataStripeSubscriptionID.AfterLoadDFMValues;
    CardDataStripeCustomerID.AfterLoadDFMValues;
    CardDataStripeStatus.AfterLoadDFMValues;
    LossCoverData.AfterLoadDFMValues;
    LossCoverDataId.AfterLoadDFMValues;
    LossCoverDataPremium.AfterLoadDFMValues;
    LossCoverDataStatus.AfterLoadDFMValues;
    LossCoverDataGadgetType.AfterLoadDFMValues;
    LookUpData.AfterLoadDFMValues;
    LookUpDataId.AfterLoadDFMValues;
    LookUpDataKey.AfterLoadDFMValues;
    LookUpDataValueType.AfterLoadDFMValues;
    LookUpDataValue.AfterLoadDFMValues;
  end;
end;

End.
