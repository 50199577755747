{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit MainForm.Base;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  WEBLib.Toast,
  Web.Core.Consts,
  WEBLib.ExtCtrls;

type
  TMainBaseForm = class(TForm)
    MainToast: TToast;
    MainMessageDlg: TMessageDlg;
    Whirligig: TWaitMessage;
    MainInputQuery: TInputMessageDlg;
    DebugPanel: TPanel;
    procedure WebFormBeforeUnload(Sender: TObject; var AMessage: string);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private declarations }
    FMainPagePopUpForm: TForm;
    FConsoleLoggingEnabled: Boolean;
    procedure PopStateEvent(Sender: TObject; State: JSValue);
  protected
    FDisplayedForm: TForm;
    procedure OnFormSelfClose(Sender: TObject); virtual;
    [async]
    function ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil): TModalResult; async;
    procedure ErrorHandler(AMessage: string);
    [async]
    procedure ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc = nil; AlwaysClose: Boolean = false); async; virtual;
    function CloseDisplayedForm(const SkipValidation: Boolean = True): Boolean; virtual;
    [async]
    procedure InitForm; async; virtual;
    procedure HandlePopState(State: TJSObject; const AURL, AHash: string); virtual;
    function GetDebugInfo: String; virtual;

  public
    { Public declarations }
    /// <param name="AIcon">
    /// A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    procedure LogToConsole(const Value: string);

    [async]
    function ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons): TModalResult; async;
    procedure ShowWaitMessage;
    procedure HideWaitMessage;

    Procedure UpdateDebugInfo; virtual;

    procedure CloseMainPage; virtual;

    procedure ClosePopUp; virtual;

    property ConsoleLoggingEnabled: Boolean read FConsoleLoggingEnabled write FConsoleLoggingEnabled;

    class procedure DisplayPage(const AElementId: string); virtual; abstract;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  smx.webcore.Types,
  WebLib.WebTools,
  WebForm.Core;

function TMainBaseForm.CloseDisplayedForm(const SkipValidation: Boolean): Boolean;
begin

  if not Assigned(FDisplayedForm) then
    Exit(True);

  if FDisplayedForm.ClassType.InheritsFrom(TCoreWebForm) then
  begin
    if SkipValidation then
      Result := True
    else
      Result := TCoreWebForm(FDisplayedForm).CanClose;

    if Result then
      TCoreWebForm(FDisplayedForm).TearDown;
  end;

  if not Result then
    Exit;

  FDisplayedForm.Free;
  FDisplayedForm := nil;
end;

procedure TMainBaseForm.CloseMainPage;
begin
  CloseDisplayedForm(True);
end;

procedure TMainBaseForm.ClosePopUp;
begin
  if Assigned(FMainPagePopUpForm) then
  begin
    FMainPagePopUpForm.Close;
    FMainPagePopUpForm.Free;
    FMainPagePopUpForm := Nil;
  end;
end;

function TMainBaseForm.ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons)
  : TModalResult;
var
  mr: TModalResult;
begin
  mr := await(TModalResult, MainMessageDlg.ShowDialog(AMessage, DlgType, Buttons));
  Result := mr;
end;

procedure TMainBaseForm.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  MainMessageDlg.ShowDialog(AMessage, DlgType, [mbOK]);
end;

procedure TMainBaseForm.ErrorHandler(AMessage: string);
begin
  // Not sure if we need this here
end;

function TMainBaseForm.GetDebugInfo: String;
begin
  Result := '';
end;

procedure TMainBaseForm.HandlePopState(State: TJSObject; const AURL, AHash: string);
begin
  // For Descendants
end;

procedure TMainBaseForm.HideWaitMessage;
begin
  if Whirligig.Visible then
    Whirligig.Hide;
end;

procedure TMainBaseForm.InitForm;
begin
  Application.OnPopState := PopStateEvent;
  {.$IFNDEF DEBUG}
  DebugPanel.Visible := false;
  {.$ENDIF}

end;

procedure TMainBaseForm.LogToConsole(const Value: string);
begin
  if FConsoleLoggingEnabled then
     OutputDebugString(Value);
end;

procedure TMainBaseForm.OnFormSelfClose(Sender: TObject);
begin
  //
end;

procedure TMainBaseForm.PopStateEvent(Sender: TObject; State: JSValue);
var
  lState: TJSObject;
begin
  if not isNull(State) then
    lState := JS.toObject(State);
  HandlePopState(lState, Application.ExeName, Application.Hash);
end;

procedure TMainBaseForm.ShowAToast(const ATitle, AMessage, AIcon: string);
const
  MESSAGE_TEMPLATE = '<span class="ToastMessage">%s</span>';
  MESSAGE_TITLE = '<i class="%s fa-fw fa-lg"></i> %s';
var
  lToast: TToastItem;
begin
  lToast := MainToast.Items.Add(Format(MESSAGE_TITLE, [AIcon, ATitle]), Format(MESSAGE_TEMPLATE, [AMessage]));
  lToast.Show;
end;

procedure TMainBaseForm.ShowForm(AFormClass: TFormClass; AProc: TFormCreatedProc; AlwaysClose: Boolean);

  procedure AfterFormCreated(AForm: TObject);
  begin

    if AFormClass.InheritsFrom(TCoreWebForm) then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    if AFormClass.InheritsFrom(TCoreWebForm) then
    begin
      TCoreWebForm(AForm).AfterCreated;
      HideWaitMessage;
    end;

    if Assigned(AProc) then
      AProc(AForm);

  end;

begin
  if Assigned(FDisplayedForm) and (FDisplayedForm.ClassType = AFormClass) and (AlwaysClose = false) then
    Exit;

  if not CloseDisplayedForm(True) then
    Exit;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated);

end;

function TMainBaseForm.ShowPopUp(AFormClass: TFormClass; OnSuccessProc: TSuccessProc = nil): TModalResult;
var
  lRetval: TModalResult;
begin
  if Assigned(FMainPagePopUpForm) then
  begin
    Console.error('A popup form (' + FMainPagePopUpForm.ClassName + ') is already created, so another (' +
      AFormClass.ClassName + ') can''t be created here');
    Exit;
  end;

  Result := mrClose;
  FMainPagePopUpForm := AFormClass.Create(Self);
  try
    FMainPagePopUpForm.Popup := True;
    FMainPagePopUpForm.ElementClassName := 'PopUpForm PopUp';
    FMainPagePopUpForm.PopupOpacity := 1;
    await(TForm, FMainPagePopUpForm.Load());
    lRetval := await(TModalResult, FMainPagePopUpForm.Execute);
    if (lRetval = mrOK) and (OnSuccessProc <> nil) then
      OnSuccessProc();
    Result := lRetval;
  finally
    FMainPagePopUpForm.Free;
    FMainPagePopUpForm := nil;
  end;
end;

procedure TMainBaseForm.ShowWaitMessage;
begin
  if not Whirligig.Visible then
    Whirligig.Show;
end;

procedure TMainBaseForm.UpdateDebugInfo;
begin
   {$IFDEF DEBUG}
   DebugPanel.Caption := GetDebugInfo;
   {$ENDIF}
end;

procedure TMainBaseForm.WebFormBeforeUnload(Sender: TObject; var AMessage: string);
begin
  //
end;

procedure TMainBaseForm.WebFormCreate(Sender: TObject);
begin
  TheMainForm := Self;
  InitForm;
end;

procedure TMainBaseForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MainMessageDlg := TMessageDlg.Create(Self);
  Whirligig := TWaitMessage.Create(Self);
  MainInputQuery := TInputMessageDlg.Create(Self);
  DebugPanel := TPanel.Create('DebugPanel');
  MainToast := TToast.Create(Self);

  MainMessageDlg.BeforeLoadDFMValues;
  Whirligig.BeforeLoadDFMValues;
  MainInputQuery.BeforeLoadDFMValues;
  DebugPanel.BeforeLoadDFMValues;
  MainToast.BeforeLoadDFMValues;
  try
    Name := 'MainBaseForm';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnBeforeUnload', 'WebFormBeforeUnload');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    MainMessageDlg.SetParentComponent(Self);
    MainMessageDlg.Name := 'MainMessageDlg';
    MainMessageDlg.Left := 56;
    MainMessageDlg.Top := 96;
    MainMessageDlg.Width := 24;
    MainMessageDlg.Height := 24;
    MainMessageDlg.HeightStyle := ssAuto;
    MainMessageDlg.WidthStyle := ssAuto;
    MainMessageDlg.Buttons := [];
    MainMessageDlg.Opacity := 0.200000000000000000;
    MainMessageDlg.ElementButtonClassName := 'btn btn-primary';
    MainMessageDlg.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    MainMessageDlg.ElementTitleClassName := 'text-body';
    MainMessageDlg.ElementContentClassName := 'text-body';
    Whirligig.SetParentComponent(Self);
    Whirligig.Name := 'Whirligig';
    Whirligig.Left := 48;
    Whirligig.Top := 152;
    Whirligig.Width := 24;
    Whirligig.Height := 24;
    Whirligig.HeightStyle := ssAuto;
    Whirligig.WidthStyle := ssAuto;
    Whirligig.Opacity := 0.200000000000000000;
    Whirligig.PictureURL := 'images/Whirligig.gif';
    MainInputQuery.SetParentComponent(Self);
    MainInputQuery.Name := 'MainInputQuery';
    MainInputQuery.Left := 112;
    MainInputQuery.Top := 96;
    MainInputQuery.Width := 24;
    MainInputQuery.Height := 24;
    MainInputQuery.HeightStyle := ssAuto;
    MainInputQuery.WidthStyle := ssAuto;
    MainInputQuery.Buttons := [];
    MainInputQuery.Opacity := 0.200000000000000000;
    MainInputQuery.ElementButtonClassName := 'btn';
    MainInputQuery.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    MainInputQuery.ElementTitleClassName := 'text-body';
    MainInputQuery.ElementContentClassName := 'text-body';
    DebugPanel.SetParentComponent(Self);
    DebugPanel.Name := 'DebugPanel';
    DebugPanel.Left := 48;
    DebugPanel.Top := 412;
    DebugPanel.Width := 225;
    DebugPanel.Height := 60;
    DebugPanel.ElementClassName := 'card';
    DebugPanel.HeightStyle := ssAuto;
    DebugPanel.WidthStyle := ssAuto;
    DebugPanel.ChildOrder := 5;
    DebugPanel.ElementBodyClassName := 'card-body';
    DebugPanel.ElementFont := efCSS;
    DebugPanel.ElementPosition := epIgnore;
    DebugPanel.TabOrder := 3;
    MainToast.SetParentComponent(Self);
    MainToast.Name := 'MainToast';
    MainToast.Position := tpTopLeft;
    MainToast.Left := 48;
    MainToast.Top := 32;
  finally
    MainMessageDlg.AfterLoadDFMValues;
    Whirligig.AfterLoadDFMValues;
    MainInputQuery.AfterLoadDFMValues;
    DebugPanel.AfterLoadDFMValues;
    MainToast.AfterLoadDFMValues;
  end;
end;

end.
