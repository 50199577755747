{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit ThankYouPage;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, BaseSitePage, Data.DB, WEBLib.DB, WEBLib.WebCtrls, Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Controls,
  WEBLib.ExtCtrls;

type
  TThankYouForm = class(TBaseSiteForm)
  private
    { Private declarations }
  public
    { Public declarations }
    [async]
    Procedure InitForm; async; override;
  protected procedure LoadDFMValues; override; end;

var
  ThankYouForm: TThankYouForm;

implementation

{$R *.dfm}

{ TThankYouForm }

procedure TThankYouForm.InitForm;
begin
  inherited;
end;

procedure TThankYouForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
  finally
  end;
end;

end.