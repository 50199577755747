Unit Gadget.ReturnTypes;

Interface

Uses
  System.SysUtils,
  System.Generics.Collections,
  Gadget.Enumerations {$IFDEF PAS2JS},
  JS{$ENDIF};

Type

  TBasicResponseStatus = (rsOK, rsError);

  TBasicResponse = class
  private
    function GetStatus: TBasicResponseStatus;
    procedure SetStatus(const Value: TBasicResponseStatus);
  public
    StatusStr: String;
    Entity: String;
    Action: String;
    Description: String;
    Retval: String;
    property Status: TBasicResponseStatus read GetStatus write SetStatus;
{$IFDEF PAS2JS}
    Constructor Create(Value: TJSObject);
{$ENDIF}
  end;

  TIdAndName = Class
    Id: Integer;
    Name: String;
{$IFDEF PAS2JS}
    Procedure Assign(Value: TJSObject);
    Constructor Create(Value: TJSObject);
{$ENDIF}
  End;

  TGadgetGroup = Class
    Gadget_Type: String;
    Make: String;
    Model: String;
    Outcome: String;
    PremiumId: Integer;
  Private
    Function GetGadgetType: TGadgetType;
    Procedure SetGadgetType(Const Value: TGadgetType);
  Public
{$IFDEF PAS2JS}
    constructor Create(Value: TJSObject);
    Procedure Assign(Value: TJSObject);
{$ENDIF}
    Property GadgetType: TGadgetType Read GetGadgetType Write SetGadgetType;

  End;

  TPremium = Class
    Id: Integer;
    Gadget_Type: String;
    Make: String;
    Model: String;
    Premium: Double;
    AnnualPremium: Double;
    StandardMemory: String;
    MemoryOptions: String;
    DamageExcess: Double;
    TheftExcess: Double;
    Image: String;
  Private
    Function GetGadgetType: TGadgetType;
    Procedure SetGadgetType(Const Value: TGadgetType);
  Public
{$IFDEF PAS2JS}
    Procedure Assign(Value: TJSObject);
{$ENDIF}
    Property GadgetType: TGadgetType Read GetGadgetType Write SetGadgetType;
  End;

  TExcess = Class
    Id: Integer;
    Premium: Double;
    DamageExcess: Double;
    TheftExcess: Double;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
{$ENDIF}
  End;

  TSessionData = class
    AffiliateId: Integer;
    AffiliateName: string;
    AffiliateDiscount: Double;
    AffiliateStatus: TOfferStatus;
    BrandCode: string;
    BrandId: Integer;
    BrandName: string;
    BrandOfferKind: TBrandOfferKind;
    BrandOffer: Double;
    BrandOfferStatus: TOfferStatus;
  end;

  TBankDetails = Class
    AccountName: String;
    TradingName: String;
    SortCode: String;
    AccountNumber: String;
    IBAN: String;
    Swift: String;
    ChequeLimit: Integer;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
{$ENDIF}
  End;

  TPaymentAPIKeys = Class
    PayPal: String;
    RevolutMode: String;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
{$ENDIF}
  End;

  TMailingAddress = Class
    Name: String;
    Add1: String;
    Add2: String;
    Add3: String;
    City: String;
    County: String;
    PostCode: String;
    Country: String;
    Function FormatAddress(Const ALineBreak: String): String;
    Function AsHTML: String;
{$IFDEF PAS2JS}
    Procedure Load(Value: TJSObject);
{$ENDIF}
  End;

  TCustomerDocument = Class
    Location: String;
    DisplayName: String;
  End;

  TSalesOrder = class
    CustomerName: string;
    CustomerEmail: string;
    CustomerAddress: TMailingAddress;
    SalesPrice: currency;
    SalesToken: string;
    RecurringPeriod: string;
  end;


Implementation

Uses
  System.StrUtils,
  System.Rtti,
  System.DateUtils;

{$IFDEF PAS2JS}

Constructor TBasicResponse.Create(Value: TJSObject);
begin
  StatusStr := String(Value['StatusStr']);
  Entity := String(Value['Entity']);
  Action := String(Value['Action']);
  Description := String(Value['Description']);
  Retval := String(Value['Retval']);
end;

Procedure TIdAndName.Assign(Value: TJSObject);
Begin
  Id := Integer(Value['Id']);
  Name := String(Value['Name']);
End;

Constructor TIdAndName.Create(Value: TJSObject);
Begin
  Assign(Value);
End;

Procedure TBankDetails.Load(Value: TJSObject);
Begin
  AccountName := String(Value['AccountName']);
  TradingName := String(Value['TradingName']);
  SortCode := String(Value['SortCode']);
  AccountNumber := String(Value['AccountNumber']);
  IBAN := String(Value['IBAN']);
  Swift := String(Value['Swift']);
  ChequeLimit := Integer(Value['ChequeLimit']);
End;

Procedure TPaymentAPIKeys.Load(Value: TJSObject);
Begin
  PayPal := String(Value['PayPal']);
  RevolutMode := String(Value['RevolutMode']);
End;

Procedure TMailingAddress.Load(Value: TJSObject);
Begin
  Name := String(Value['Name']);
  Add1 := String(Value['Add1']);
  Add2 := String(Value['Add2']);
  Add3 := String(Value['Add3']);
  City := String(Value['City']);
  County := String(Value['County']);
  PostCode := String(Value['PostCode']);
  Country := String(Value['Country']);
End;

Procedure TPremium.Assign(Value: TJSObject);
Begin
  Id := Integer(Value['Id']);
  Gadget_Type := String(Value['Gadget_Type']);
  Make := String(Value['Make']);
  Model := String(Value['Model']);
  StandardMemory := String(Value['StandardMemory']);
  MemoryOptions := String(Value['MemoryOptions']);
  DamageExcess := Double(Value['DamageExcess']);
  TheftExcess := Double(Value['TheftExcess']);
  Premium := Double(Value['Premium']);
  AnnualPremium := Double(Value['AnnualPremium']);
  Image := String(Value['Image']);
End;

Procedure TGadgetGroup.Assign(Value: TJSObject);
Begin
  Gadget_Type := String(Value['Gadget_Type']);
  Make := String(Value['Make']);
  Model := String(Value['Model']);
  Outcome := String(Value['Outcome']);
  PremiumId := Integer(Value['PremiumId']);
End;

Constructor TGadgetGroup.Create(Value: TJSObject);
Begin
  Assign(Value);
End;


{ TWebExcess }

Procedure TExcess.Load(Value: TJSObject);
Begin
  Id := Integer(Value['Id']);
  Premium := Double(Value['Premium']);
  DamageExcess := Double(Value['DamageExcess']);
  TheftExcess := Double(Value['TheftExcess']);
End;

{$ENDIF}
{ TMailingAddress }

Function TMailingAddress.FormatAddress(Const ALineBreak: String): String;
Begin
  Result := Name;
  If Add1 <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Add1;
  If Add2 <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Add2;
  If Add3 <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Add3;
  If City <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + City;
  If County <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + County;
  If PostCode <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + PostCode;
  If Country <> '' Then
    Result := Result + IfThen(Result <> '', ALineBreak) + Country;
End;

Function TMailingAddress.AsHTML: String;
Begin
  Result := FormatAddress('<br />');
End;

{ TWebPremium }

Function TPremium.GetGadgetType: TGadgetType;
Begin
  Result := TRttiEnumerationType.GetValue<TGadgetType>(Gadget_Type);
End;

Procedure TPremium.SetGadgetType(Const Value: TGadgetType);
Begin
  Gadget_Type := TRttiEnumerationType.GetName<TGadgetType>(Value);
End;

{ TGadgetGroup }

function TGadgetGroup.GetGadgetType: TGadgetType;
begin
  Result := TRttiEnumerationType.GetValue<TGadgetType>(Gadget_Type);
end;

procedure TGadgetGroup.SetGadgetType(const Value: TGadgetType);
begin
  Gadget_Type := TRttiEnumerationType.GetName<TGadgetType>(Value);
end;

{ TBasicResponse }

function TBasicResponse.GetStatus: TBasicResponseStatus;
begin
  Result := TRttiEnumerationType.GetValue<TBasicResponseStatus>(StatusStr);
end;

procedure TBasicResponse.SetStatus(const Value: TBasicResponseStatus);
begin
  StatusStr := TRttiEnumerationType.GetName<TBasicResponseStatus>(Value);
end;

End.
