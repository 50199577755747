Unit BaseSitePage;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.ExtCtrls,
  WebForm.Core,
  Gadget.Enumerations,
  Gadget.Web.Consts,
  Data.DB,
  WEBLib.DB,
  WEBLib.DBCtrls;

Type
  TBaseSiteForm = Class(TCoreWebForm)
    PolicySource: TDataSource;
    CustomerSource: TDataSource;
    InputQueryDlg: TInputMessageDlg;
    Procedure WebFormCreate(Sender: TObject);
  Private
    FPageLoading: Boolean;
    FOnSitePageChange: TOnSitePageChange;
    FDisplayingAs: TGadgetPage;
    FNavDirection: TNavDirection;
    FDoChangeSitePage: TChangeSitePage;
    Function GetPageHash: String;

    { Private declarations }
  Protected
    Procedure ReadyToProgress(Const CanProgress: Boolean); virtual;

    Procedure SetPageLoading(Const Value: Boolean); Virtual;

    Procedure SetDisplayingAs(Const Value: TGadgetPage); Virtual;
    Procedure UpdateMainForm;
    Function IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean; Override;

    Procedure UpdatePolicyCosts; Virtual;
    Procedure ChangeSitePage(NewPage: TGadgetPage);
  Public
    { Public declarations }

    [async]
    Procedure InitForm; async; Override;
    [async]
    Procedure LoadData; async; Virtual;
    Procedure AfterLoaded; Virtual;

    [async]
    Function PreviewClose: Boolean; async; Virtual;
    Function CanClosePage: TCanProgress; Virtual;
    Procedure SaveThis; Virtual;

    Property OnSitePageChange: TOnSitePageChange Read FOnSitePageChange Write FOnSitePageChange;
    Property DoChangeSitePage: TChangeSitePage Read FDoChangeSitePage Write FDoChangeSitePage;
    Property DisplayingAs: TGadgetPage Read FDisplayingAs Write SetDisplayingAs;
    Property PageLoading: Boolean Read FPageLoading Write SetPageLoading;
    Property NavDirection: TNavDirection Read FNavDirection Write FNavDirection;
    Property PageHash: String Read GetPageHash;
  protected procedure LoadDFMValues; override; End;

Implementation

{$R *.dfm}

Uses
  System.DateUtils,
  System.Rtti,
  MainDataModule,
  SMX.Web.Table.Simple,
  SMX.Web.Utils,
  SMX.Web.Document.Utils,
  SMX.CRM.Utils,
  Gadget.Web.Utils,
  XData.Dataset.Utils,
  DirectDebitPage;


Procedure TBaseSiteForm.WebFormCreate(Sender: TObject);
Begin
  FPageLoading := True;
  FNavDirection := TNavDirection.ndNone;
  MainData.OnReadyToProgess := ReadyToProgress;
End;

{ TBaseBookingForm }

Procedure TBaseSiteForm.AfterLoaded;
Begin
  FPageLoading := False;
End;

Function TBaseSiteForm.CanClosePage: TCanProgress;
Begin
  If IsValid('', True) Then
    Result := TCanProgress.ccImmediate
  Else
    Result := TCanProgress.ccNo;
End;

Procedure TBaseSiteForm.ChangeSitePage(NewPage: TGadgetPage);
Begin
  If Assigned(FDoChangeSitePage) Then
    FDoChangeSitePage(NewPage, TNavDirection.ndNone);
End;

Function TBaseSiteForm.GetPageHash: String;
Begin
  Result := TRttiEnumerationType.GetName<TGadgetPage>(FDisplayingAs);
End;

Procedure TBaseSiteForm.InitForm;
Var
  lHash: String;
Begin
  Inherited;
  lHash := GetPageHash;
  If lHash <> Application.Hash Then
    window.location.Hash := lHash; // self.ClassName.Substring(1);

  UpdatePolicyCosts;
End;

Function TBaseSiteForm.IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean;
Begin
  Result := Inherited IsValid(AElementId, HighlightControls);
End;

Procedure TBaseSiteForm.LoadData;
Begin
  // for descendants
End;

Function TBaseSiteForm.PreviewClose: Boolean;
Begin
  Result := True;
End;

procedure TBaseSiteForm.ReadyToProgress(const CanProgress: Boolean);
begin
  //for descendants, if needed
end;

Procedure TBaseSiteForm.SaveThis;
Begin
  MainData.SaveData;
End;

Procedure TBaseSiteForm.SetDisplayingAs(Const Value: TGadgetPage);
Begin
  FDisplayingAs := Value;
End;

Procedure TBaseSiteForm.SetPageLoading(Const Value: Boolean);
Begin
  FPageLoading := Value;
End;

Procedure TBaseSiteForm.UpdatePolicyCosts;
Begin

End;

Procedure TBaseSiteForm.UpdateMainForm;
Begin
  If Assigned(FOnSitePageChange) Then
    FOnSitePageChange(Self);
End;

procedure TBaseSiteForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  InputQueryDlg := TInputMessageDlg.Create(Self);
  PolicySource := TDataSource.Create(Self);
  CustomerSource := TDataSource.Create(Self);

  InputQueryDlg.BeforeLoadDFMValues;
  PolicySource.BeforeLoadDFMValues;
  CustomerSource.BeforeLoadDFMValues;
  try
    Width := 1077;
    Height := 657;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    InputQueryDlg.SetParentComponent(Self);
    InputQueryDlg.Name := 'InputQueryDlg';
    InputQueryDlg.Left := 26;
    InputQueryDlg.Top := 24;
    InputQueryDlg.Width := 24;
    InputQueryDlg.Height := 24;
    InputQueryDlg.HeightStyle := ssAuto;
    InputQueryDlg.WidthStyle := ssAuto;
    InputQueryDlg.Buttons := [];
    InputQueryDlg.Opacity := 0.200000000000000000;
    InputQueryDlg.ElementButtonClassName := 'btn';
    InputQueryDlg.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    InputQueryDlg.ElementTitleClassName := 'text-body';
    InputQueryDlg.ElementContentClassName := 'text-body';
    PolicySource.SetParentComponent(Self);
    PolicySource.Name := 'PolicySource';
    PolicySource.Left := 32;
    PolicySource.Top := 136;
    CustomerSource.SetParentComponent(Self);
    CustomerSource.Name := 'CustomerSource';
    CustomerSource.Left := 32;
    CustomerSource.Top := 64;
  finally
    InputQueryDlg.AfterLoadDFMValues;
    PolicySource.AfterLoadDFMValues;
    CustomerSource.AfterLoadDFMValues;
  end;
end;

End.
