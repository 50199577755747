{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit PaymentPage;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  CustomerPage,
  Data.DB,
  WEBLib.DB,
  WEBLib.WebCtrls,
  WEBLib.Grids,
  WEBLib.DBCtrls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.ExtCtrls;

type
  TPaymentForm = class(TCustomerForm)
    MarketingOKPanel: TPanel;
    MarketingOK: TDBCheckBox;
    PlaceOrderButton: TButton;
    PaybyAltLabel: TLabel;
    AddMoreGadgets: TLabel;
    MissingNumbers: THTMLSpan;
    BasketSource: TDataSource;
    PolicyTable: TDBTableControl;
    DiscountLabel: THTMLSpan;
    NetPremiumLabel: TDBLabel;
    TermsOK: TCheckBox;
    procedure AddMoreGadgetsClick(Sender: TObject);
    procedure PlaceOrderButtonClick(Sender: TObject);
    procedure PolicyTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
      AElement: TJSHTMLElementRecord);
    procedure TermsOKClick(Sender: TObject);
  private
    { Private declarations }
    FMarketingOKHidden: Boolean;
    procedure AddRowActions(const ARecordId: Integer; AParentElement: TJSHTMLElement);
    procedure AddNumber(const ARecordId: Integer);
    [async]
    Procedure AddSerialNumberProc(const ARecordId: Integer); async;
    Procedure SetDiscountLabel;
    Procedure SetMissingNumbersMessage;
  protected
    [async]
    Procedure PayNow; async; virtual;
  public
    { Public declarations }
    [async]
    Procedure InitForm; async; override;

  protected procedure LoadDFMValues; override; end;

var
  PaymentForm: TPaymentForm;

implementation

{$R *.dfm}

uses
  MainDataModule,
  SMX.Web.Document.Utils,
  Gadget.Enumerations,
  SMX.Web.Layout.Utils,
  XData.Dataset.Utils,
  Gadget.Web.Utils,
  Gadget.Web.Consts;

procedure TPaymentForm.AddMoreGadgetsClick(Sender: TObject);
begin
  MainData.AutoShowGadgetSelect := True;
  ChangeSitePage(TGadgetPage.gpMyGadget);
end;

{ TPaymentForm }

procedure TPaymentForm.AddNumber(const ARecordId: Integer);
begin
  if not MainData.PolicyData.Locate('Id', ARecordId, []) then
    exit;
  AddSerialNumberProc(ARecordId);
end;

procedure TPaymentForm.AddRowActions(const ARecordId: Integer; AParentElement: TJSHTMLElement);

  procedure NumberClick(Sender: TObject);
  begin
    AddNumber(ARecordId);
  end;

var
  Span: THTMLSpan;
  s: String;
  lGadgetType: TGadgetType;
begin
  if MainData.PolicyDataSerialNumber.Value = '' then
  begin
    lGadgetType := TGadgetUtils.StrToGadgetType(MainData.PolicyDataGadgetType.Value);
    if lGadgetType = TGadgetType.MobilePhone then
      s := 'IMEI'
    else
      s := 'Serial';
    Span := TLayoutUtils.RowActionSpan(AParentElement, 'fad fa-input-numeric fa-fw fa-lg', 'Add your ' + s + ' number');
    Span.OnClick := @NumberClick;
  end;
end;

procedure TPaymentForm.AddSerialNumberProc(const ARecordId: Integer);
Var
  lRetval: TModalResult;
  lValue, lText: String;
  I: Integer;
  AInputType: WEBLib.Controls.TInputType;
  lGadgetType: TGadgetType;
Begin
  If TXDataDSUtils.IsOpenAndNotEmpty(MainData.PolicyData) Then
    lValue := MainData.PolicyDataSerialNumber.Value
  Else
    lValue := '';

  lGadgetType := TGadgetUtils.StrToGadgetType(MainData.PolicyDataGadgetType.Value);

  InputQueryDlg.InputType := itText;
  InputQueryDlg.InputValue := lValue;

  If lGadgetType = TGadgetType.MobilePhone Then
    lText := 'Please enter your device''s IMEI'
  Else
    lText := 'Please enter your device''s serial number';

  I := 0;
  Repeat
    lRetval := await(TModalResult, InputQueryDlg.ShowDialog(lText, WEBLib.Dialogs.mtConfirmation, [mbOK, mbCancel]));

    If lRetval = mrOK Then
    Begin
      lValue := InputQueryDlg.InputValue;
      If (lGadgetType = TGadgetType.MobilePhone) And (Not TGadgetUtils.ValidateIMEI(lValue)) Then
      Begin
        lRetval := mrRetry;
        If I = 0 Then
        Begin
          lText := 'Please check your IMEI and try again';
          Inc(I);
        End;
      End;
    End;

  Until lRetval <> mrRetry;

  If lRetval = mrOK Then
  Begin
    TXDataDSUtils.DatasetEdit(MainData.PolicyData);
    MainData.PolicyDataSerialNumber.Value := lValue.ToUpper;
    MainData.PolicyData.Post;
    SetMissingNumbersMessage;
  End;

end;

procedure TPaymentForm.InitForm;
var
  lTermsText: String;
begin
  inherited;
  PolicyTable.HideColumn(0);
  SetDiscountLabel;

  if MainData.PolicyCount > 1 then
    PlaceOrderButton.Caption := 'Insure my gadgets'
  else
    PlaceOrderButton.Caption := 'Insure my gadget';

  TermsOK.Checked := MainData.BasketDataAcceptTerms.Value;
  PlaceOrderButton.Enabled := MainData.BasketDataAcceptTerms.Value;

  FMarketingOKHidden := MainData.CustomerDataMarketingOK.Value;
  if FMarketingOKHidden then
    MarketingOKPanel.ElementClassName := smx_hide_class;

  lTermsText := MainData.LookUpValue(AGREE_TERMS_TEXT);
  if lTermsText <> '' then
    TDocUtils.replaceInnerText('terms-text', lTermsText);

  SetMissingNumbersMessage;

end;

procedure TPaymentForm.PayNow;
begin
//
end;

procedure TPaymentForm.PlaceOrderButtonClick(Sender: TObject);
begin
  LogToConsole('PlaceOrderButton clicked');
  MainData.PostAll;

  if not MainData.BasketDataAcceptTerms.Value then
  begin
    DisplayMessage('Please confirm that you accept our terms and conditions', mtWarning);
    TXDataDSUtils.DatasetEdit(MainData.PolicyData);
    TermsOK.SetFocus;
    exit;
  end;

  if isValid('', True) then
  begin
    PayNow;
  end
  else
  begin
    DisplayMessage('Please check your details', mtWarning);
    TXDataDSUtils.DatasetEdit(MainData.PolicyData);
  end;
end;

procedure TPaymentForm.PolicyTableGetCellChildren(Sender: TObject; ACol, ARow: Integer; AField: TField; AValue: string;
  AElement: TJSHTMLElementRecord);
begin
  if ARow = 0 then
    exit;

  if PolicyTable.Columns[ACol].Title = '' then
  begin
    AddRowActions(MainData.PolicyDataId.Value, AElement.Element);
  end;

end;

procedure TPaymentForm.SetDiscountLabel;
var
  lCount: Integer;
  lDiscount: Double;
begin
  lCount := MainData.PolicyCount;
  lDiscount := MainData.BasketDataTotalDiscountRate.Value;
  if lDiscount = 0 then
  begin
    DiscountLabel.HTML.Clear;
    DiscountLabel.Visible := False;
  end
  else
    DiscountLabel.HTML.Text := Format('You have a %s&percnt; discount', [FormatFloat('#0', lDiscount)]);
end;

procedure TPaymentForm.SetMissingNumbersMessage;
var
  lMissingNumbers: Integer;
begin
  lMissingNumbers := MainData.MissingSerials;

  if lMissingNumbers > 0 then
  begin
    if (lMissingNumbers = 1) and (MainData.PolicyCount = 1) then
      MissingNumbers.HTML.Text :=
        'Your gadget is missing it''s IMEI/Serial number. Please add it by clicking the icon next to it in the table'
    else if (lMissingNumbers = 1) and (MainData.PolicyCount > 1) then
      MissingNumbers.HTML.Text :=
        'One of your gadgets is missing it''s IMEI/Serial number. Please add it by clicking the icon next to it in the table'
    else
      MissingNumbers.HTML.Text :=
        'Some of your gadgets are missing their IMEI/Serial numbers. Please add them by clicking the icon next to them in the table';
    MissingNumbers.ElementClassName := smx_show_class;
  end
  else
  begin
    MissingNumbers.HTML.Text := '';
    MissingNumbers.ElementClassName := smx_hide_class;
  end;
end;

procedure TPaymentForm.TermsOKClick(Sender: TObject);
begin
  if not PageLoading then
  begin
    TXDataDSUtils.DatasetEdit(MainData.BasketData);
    MainData.BasketDataAcceptTerms.Value := TermsOK.Checked;
    TXDataDSUtils.Post(MainData.BasketData);
    PlaceOrderButton.Enabled := MainData.BasketDataAcceptTerms.Value;
  end;
end;

procedure TPaymentForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PaybyAltLabel := TLabel.Create('PayByAltLabel');
  AddMoreGadgets := TLabel.Create('AddMoreGadgets');
  NetPremiumLabel := TDBLabel.Create('NetPremiumLabel');
  MarketingOKPanel := TPanel.Create('MarketingOKPanel');
  MarketingOK := TDBCheckBox.Create('MarketingOK');
  PlaceOrderButton := TButton.Create('PlaceOrderButton');
  MissingNumbers := THTMLSpan.Create('missing-numbers');
  PolicyTable := TDBTableControl.Create('PolicyTable');
  DiscountLabel := THTMLSpan.Create('DiscountLabel');
  TermsOK := TCheckBox.Create('TermsOK');
  BasketSource := TDataSource.Create(Self);

  PaybyAltLabel.BeforeLoadDFMValues;
  AddMoreGadgets.BeforeLoadDFMValues;
  NetPremiumLabel.BeforeLoadDFMValues;
  MarketingOKPanel.BeforeLoadDFMValues;
  MarketingOK.BeforeLoadDFMValues;
  PlaceOrderButton.BeforeLoadDFMValues;
  MissingNumbers.BeforeLoadDFMValues;
  PolicyTable.BeforeLoadDFMValues;
  DiscountLabel.BeforeLoadDFMValues;
  TermsOK.BeforeLoadDFMValues;
  BasketSource.BeforeLoadDFMValues;
  try
    PaybyAltLabel.SetParentComponent(Self);
    PaybyAltLabel.Name := 'PaybyAltLabel';
    PaybyAltLabel.Left := 88;
    PaybyAltLabel.Top := 551;
    PaybyAltLabel.Width := 176;
    PaybyAltLabel.Height := 18;
    PaybyAltLabel.Caption := 'Pay by Direct Debit instead';
    PaybyAltLabel.ElementFont := efCSS;
    PaybyAltLabel.ElementPosition := epIgnore;
    PaybyAltLabel.HeightStyle := ssAuto;
    PaybyAltLabel.HeightPercent := 100.000000000000000000;
    PaybyAltLabel.WidthStyle := ssAuto;
    PaybyAltLabel.WidthPercent := 100.000000000000000000;
    AddMoreGadgets.SetParentComponent(Self);
    AddMoreGadgets.Name := 'AddMoreGadgets';
    AddMoreGadgets.Left := 104;
    AddMoreGadgets.Top := 608;
    AddMoreGadgets.Width := 121;
    AddMoreGadgets.Height := 18;
    AddMoreGadgets.Caption := 'Add more gadgets';
    AddMoreGadgets.ElementFont := efCSS;
    AddMoreGadgets.HeightStyle := ssAuto;
    AddMoreGadgets.HeightPercent := 100.000000000000000000;
    AddMoreGadgets.HTMLType := tSPAN;
    AddMoreGadgets.WidthPercent := 100.000000000000000000;
    SetEvent(AddMoreGadgets, Self, 'OnClick', 'AddMoreGadgetsClick');
    NetPremiumLabel.SetParentComponent(Self);
    NetPremiumLabel.Name := 'NetPremiumLabel';
    NetPremiumLabel.Left := 240;
    NetPremiumLabel.Top := 302;
    NetPremiumLabel.Width := 113;
    NetPremiumLabel.Height := 18;
    NetPremiumLabel.Caption := 'NetPremiumLabel';
    NetPremiumLabel.ElementFont := efCSS;
    NetPremiumLabel.ElementPosition := epIgnore;
    NetPremiumLabel.HeightStyle := ssAuto;
    NetPremiumLabel.HeightPercent := 100.000000000000000000;
    NetPremiumLabel.WidthStyle := ssAuto;
    NetPremiumLabel.WidthPercent := 100.000000000000000000;
    NetPremiumLabel.DataField := 'NetPremium';
    NetPremiumLabel.DataSource := BasketSource;
    MarketingOKPanel.SetParentComponent(Self);
    MarketingOKPanel.Name := 'MarketingOKPanel';
    MarketingOKPanel.Left := 227;
    MarketingOKPanel.Top := 440;
    MarketingOKPanel.Width := 113;
    MarketingOKPanel.Height := 33;
    MarketingOKPanel.ElementClassName := 'card';
    MarketingOKPanel.HeightStyle := ssAuto;
    MarketingOKPanel.WidthStyle := ssAuto;
    MarketingOKPanel.BorderColor := clWhite;
    MarketingOKPanel.BorderStyle := bsNone;
    MarketingOKPanel.ChildOrder := 22;
    MarketingOKPanel.Color := clWhite;
    MarketingOKPanel.ElementBodyClassName := 'card-body';
    MarketingOKPanel.ElementFont := efCSS;
    MarketingOKPanel.ElementPosition := epIgnore;
    MarketingOKPanel.TabOrder := 13;
    MarketingOK.SetParentComponent(MarketingOKPanel);
    MarketingOK.Name := 'MarketingOK';
    MarketingOK.Left := 3;
    MarketingOK.Top := 3;
    MarketingOK.Width := 113;
    MarketingOK.Height := 22;
    MarketingOK.ChildOrder := 12;
    MarketingOK.ElementClassName := 'form-check-input';
    MarketingOK.ElementLabelClassName := 'custom-control-label';
    MarketingOK.ElementFont := efCSS;
    MarketingOK.ElementPosition := epIgnore;
    MarketingOK.HeightStyle := ssAuto;
    MarketingOK.HeightPercent := 100.000000000000000000;
    MarketingOK.WidthStyle := ssAuto;
    MarketingOK.WidthPercent := 100.000000000000000000;
    MarketingOK.DataField := 'MarketingOK';
    MarketingOK.DataSource := CustomerSource;
    MarketingOK.ValueChecked := 'True';
    MarketingOK.ValueUnChecked := 'False';
    PlaceOrderButton.SetParentComponent(Self);
    PlaceOrderButton.Name := 'PlaceOrderButton';
    PlaceOrderButton.Left := 88;
    PlaceOrderButton.Top := 520;
    PlaceOrderButton.Width := 201;
    PlaceOrderButton.Height := 25;
    PlaceOrderButton.Caption := 'Insure My Gadget';
    PlaceOrderButton.ChildOrder := 12;
    PlaceOrderButton.ElementClassName := 'btn btn-light';
    PlaceOrderButton.ElementFont := efCSS;
    PlaceOrderButton.ElementPosition := epIgnore;
    PlaceOrderButton.HeightStyle := ssAuto;
    PlaceOrderButton.HeightPercent := 100.000000000000000000;
    PlaceOrderButton.WidthStyle := ssAuto;
    PlaceOrderButton.WidthPercent := 100.000000000000000000;
    SetEvent(PlaceOrderButton, Self, 'OnClick', 'PlaceOrderButtonClick');
    MissingNumbers.SetParentComponent(Self);
    MissingNumbers.Name := 'MissingNumbers';
    MissingNumbers.Left := 240;
    MissingNumbers.Top := 352;
    MissingNumbers.Width := 100;
    MissingNumbers.Height := 41;
    MissingNumbers.HeightStyle := ssAuto;
    MissingNumbers.WidthStyle := ssAuto;
    MissingNumbers.ChildOrder := 20;
    MissingNumbers.ElementPosition := epIgnore;
    MissingNumbers.ElementFont := efCSS;
    MissingNumbers.Role := '';
    PolicyTable.SetParentComponent(Self);
    PolicyTable.Name := 'PolicyTable';
    PolicyTable.Left := 248;
    PolicyTable.Top := 88;
    PolicyTable.Width := 300;
    PolicyTable.Height := 200;
    PolicyTable.HeightStyle := ssAuto;
    PolicyTable.WidthStyle := ssAuto;
    PolicyTable.BorderColor := clSilver;
    PolicyTable.ChildOrder := 11;
    PolicyTable.ElementFont := efCSS;
    PolicyTable.ElementHeaderClassName := 'thead-light';
    PolicyTable.ElementPosition := epIgnore;
    PolicyTable.ElementTableClassName := 'table table-striped table-bordered  table-hover';
    PolicyTable.Footer.ButtonActiveElementClassName := 'btn btn-primary';
    PolicyTable.Footer.ButtonElementClassName := 'btn btn-light';
    PolicyTable.Footer.DropDownElementClassName := 'form-control';
    PolicyTable.Footer.InputElementClassName := 'form-control';
    PolicyTable.Footer.LinkActiveElementClassName := 'link-primary';
    PolicyTable.Footer.LinkElementClassName := 'link-secondary';
    PolicyTable.Footer.ListElementClassName := 'pagination';
    PolicyTable.Footer.ListItemElementClassName := 'page-item';
    PolicyTable.Footer.ListLinkElementClassName := 'page-link';
    PolicyTable.Header.ButtonActiveElementClassName := 'btn btn-primary';
    PolicyTable.Header.ButtonElementClassName := 'btn btn-light';
    PolicyTable.Header.DropDownElementClassName := 'form-control';
    PolicyTable.Header.InputElementClassName := 'form-control';
    PolicyTable.Header.LinkActiveElementClassName := 'link-primary';
    PolicyTable.Header.LinkElementClassName := 'link-secondary';
    PolicyTable.Header.ListElementClassName := 'pagination';
    PolicyTable.Header.ListItemElementClassName := 'page-item';
    PolicyTable.Header.ListLinkElementClassName := 'page-link';
    SetEvent(PolicyTable, Self, 'OnGetCellChildren', 'PolicyTableGetCellChildren');
    PolicyTable.Columns.Clear;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'Id';
      Title := 'Id';
    end;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'SummaryText';
      Title := 'Gadget';
    end;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'NetPremium';
      Title := 'Premium';
    end;
    with PolicyTable.Columns.Add do
    begin
      DataField := 'LossCoverText';
      Title := 'Loss?';
    end;
    with PolicyTable.Columns.Add do
    begin
    end;
    PolicyTable.DataSource := PolicySource;
    DiscountLabel.SetParentComponent(Self);
    DiscountLabel.Name := 'DiscountLabel';
    DiscountLabel.Left := 376;
    DiscountLabel.Top := 302;
    DiscountLabel.Width := 100;
    DiscountLabel.Height := 24;
    DiscountLabel.HeightStyle := ssAuto;
    DiscountLabel.WidthStyle := ssAuto;
    DiscountLabel.ChildOrder := 26;
    DiscountLabel.ElementPosition := epIgnore;
    DiscountLabel.ElementFont := efCSS;
    DiscountLabel.HTML.BeginUpdate;
    try
      DiscountLabel.HTML.Clear;
      DiscountLabel.HTML.Add('DiscountLabel');
    finally
      DiscountLabel.HTML.EndUpdate;
    end;
    DiscountLabel.Role := '';
    TermsOK.SetParentComponent(Self);
    TermsOK.Name := 'TermsOK';
    TermsOK.Left := 88;
    TermsOK.Top := 492;
    TermsOK.Width := 113;
    TermsOK.Height := 22;
    TermsOK.ChildOrder := 20;
    TermsOK.ElementClassName := 'form-check-input';
    TermsOK.ElementButtonClassName := 'custom-control-input';
    TermsOK.ElementFont := efCSS;
    TermsOK.ElementPosition := epIgnore;
    TermsOK.HeightStyle := ssAuto;
    TermsOK.HeightPercent := 100.000000000000000000;
    TermsOK.WidthStyle := ssAuto;
    TermsOK.WidthPercent := 100.000000000000000000;
    SetEvent(TermsOK, Self, 'OnClick', 'TermsOKClick');
    BasketSource.SetParentComponent(Self);
    BasketSource.Name := 'BasketSource';
    BasketSource.DataSet := MainData.BasketData;
    BasketSource.Left := 24;
    BasketSource.Top := 208;
  finally
    PaybyAltLabel.AfterLoadDFMValues;
    AddMoreGadgets.AfterLoadDFMValues;
    NetPremiumLabel.AfterLoadDFMValues;
    MarketingOKPanel.AfterLoadDFMValues;
    MarketingOK.AfterLoadDFMValues;
    PlaceOrderButton.AfterLoadDFMValues;
    MissingNumbers.AfterLoadDFMValues;
    PolicyTable.AfterLoadDFMValues;
    DiscountLabel.AfterLoadDFMValues;
    TermsOK.AfterLoadDFMValues;
    BasketSource.AfterLoadDFMValues;
  end;
end;

end.
