Unit CustomerPage;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  BaseSitePage,
  Vcl.Controls,
  WEBLib.DropDown,
  WEBLib.Country,
  Data.DB,
  WEBLib.DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.DBCtrls,
  XData.Web.JsonDataset,
  XData.Web.Dataset,
  WEBLib.ExtCtrls,
  Gadget.Enumerations,
  Gadget.Web.Consts,
  WEBLib.WebCtrls,
  WEBLib.Grids;

Type
  TCustomerForm = Class(TBaseSiteForm)
    Add1Edit: TDBEdit;
    Add2Edit: TDBEdit;
    TownEdit: TDBEdit;
    PostcodeEdit: TDBEdit;
    Add3Edit: TDBEdit;
    MobileEdit: TDBEdit;
    EmailEdit: TDBEdit;
    LastNameEdit: TDBEdit;
    FirstNameEdit: TDBEdit;
    TitleEdit: TDBComboBox;
    procedure Add1EditExit(Sender: TObject);
    Procedure EmailEditExit(Sender: TObject);
    Procedure WebFormCreate(Sender: TObject);
    Procedure FirstnameEditExit(Sender: TObject);
    procedure LastNameEditExit(Sender: TObject);
    Procedure MobileEditExit(Sender: TObject);
    Procedure MobileEditKeyPress(Sender: TObject; Var Key: Char);
    procedure PostcodeEditExit(Sender: TObject);
    procedure TownEditExit(Sender: TObject);
  Private
    { Private declarations }
    FDisplayHighlighting: Boolean;
    FOriginalProcessingState: TProcessingState;
  Protected
    Function IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean; Override;
    Procedure AfterLoaded; Override;
  Public
    { Public declarations }
    [async]
    Procedure InitForm; async; Override;
  protected procedure LoadDFMValues; override; End;

Var
  CustomerForm: TCustomerForm;

Implementation

{$R *.dfm}

Uses
  System.Rtti,
  System.DateUtils,
  MainDataModule,
  XData.Dataset.Utils,
  SMX.Web.Document.Utils,
  SMX.Web.Utils,
  SMX.CRM.Utils;

procedure TCustomerForm.Add1EditExit(Sender: TObject);
begin
  if not PageLoading then
    IsValid(Add1Edit.ElementID, False);
end;

Procedure TCustomerForm.WebFormCreate(Sender: TObject);
Begin
  Inherited;
  FDisplayHighlighting := False;
End;

procedure TCustomerForm.AfterLoaded;
begin
  inherited;

end;

Procedure TCustomerForm.EmailEditExit(Sender: TObject);
Begin
  if not PageLoading then
    IsValid(EmailEdit.ElementID, False);
End;

Procedure TCustomerForm.FirstnameEditExit(Sender: TObject);
Begin
  if not PageLoading then
    IsValid(FirstNameEdit.ElementID, False);
End;

Procedure TCustomerForm.InitForm;
Begin
  Inherited;
  FOriginalProcessingState := MainData.ProcessingState;
  TXDataDSUtils.DatasetEdit(MainData.CustomerData);
  TitleEdit.SetFocus;
End;

Function TCustomerForm.IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean;
Var
  ControlValid, isLoading: Boolean;
Begin

  isLoading := PageLoading;

  Result := Inherited IsValid(AElementId, HighlightControls);

  ControlValid := MainData.CustomerDataForename.Value <> '';
  Result := Result And ControlValid;
  HighlightControl(FirstNameEdit.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := MainData.CustomerDataSurname.Value <> '';
  Result := Result And ControlValid;
  HighlightControl(LastNameEdit.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := TUtils.IsPhoneNumberValid(MobileEdit.Text);
  Result := Result And ControlValid;
  HighlightControl(MobileEdit.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := TCRMUtils.IsEmailValid(MainData.CustomerDataEmail.Value);
  Result := Result And ControlValid;
  HighlightControl(EmailEdit.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := Trim(Add1Edit.Text) <> '';
  Result := Result And ControlValid;
  HighlightControl(Add1Edit.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := Trim(TownEdit.Text) <> '';
  Result := Result And ControlValid;
  HighlightControl(TownEdit.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := Trim(PostcodeEdit.Text) <> '';
  Result := Result And ControlValid;
  HighlightControl(PostcodeEdit.ElementID, AElementId, ControlValid, HighlightControls);

End;

procedure TCustomerForm.LastNameEditExit(Sender: TObject);
Begin
  if not PageLoading then
    IsValid(LastNameEdit.ElementID, False);
end;

Procedure TCustomerForm.MobileEditExit(Sender: TObject);
Begin
  if not PageLoading then
    IsValid(MobileEdit.ElementID, False);
End;

Procedure TCustomerForm.MobileEditKeyPress(Sender: TObject; Var Key: Char);
Begin
  If Not(Key In ['0' .. '9', #32, '+', '-']) Then
    Key := #0;
End;

procedure TCustomerForm.PostcodeEditExit(Sender: TObject);
begin
  if not PageLoading then
    IsValid(PostcodeEdit.ElementID, False);
end;

procedure TCustomerForm.TownEditExit(Sender: TObject);
begin
  if not PageLoading then
    IsValid(TownEdit.ElementID, False);
end;

procedure TCustomerForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Add1Edit := TDBEdit.Create('Add1Edit');
  Add2Edit := TDBEdit.Create('Add2Edit');
  TownEdit := TDBEdit.Create('TownEdit');
  PostcodeEdit := TDBEdit.Create('PostcodeEdit');
  Add3Edit := TDBEdit.Create('Add3Edit');
  MobileEdit := TDBEdit.Create('MobileEdit');
  EmailEdit := TDBEdit.Create('EmailEdit');
  LastNameEdit := TDBEdit.Create('LastNameEdit');
  FirstNameEdit := TDBEdit.Create('FirstNameEdit');
  TitleEdit := TDBComboBox.Create('TitleEdit');

  Add1Edit.BeforeLoadDFMValues;
  Add2Edit.BeforeLoadDFMValues;
  TownEdit.BeforeLoadDFMValues;
  PostcodeEdit.BeforeLoadDFMValues;
  Add3Edit.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  FirstNameEdit.BeforeLoadDFMValues;
  TitleEdit.BeforeLoadDFMValues;
  PolicySource.BeforeLoadDFMValues;
  CustomerSource.BeforeLoadDFMValues;
  try
    Height := 715;
    Add1Edit.SetParentComponent(Self);
    Add1Edit.Name := 'Add1Edit';
    Add1Edit.Left := 72;
    Add1Edit.Top := 253;
    Add1Edit.Width := 121;
    Add1Edit.Height := 22;
    Add1Edit.ChildOrder := 6;
    Add1Edit.ElementClassName := 'form-control';
    Add1Edit.ElementFont := efCSS;
    Add1Edit.ElementPosition := epIgnore;
    Add1Edit.HeightStyle := ssAuto;
    Add1Edit.HeightPercent := 100.000000000000000000;
    Add1Edit.MaxLength := 25;
    Add1Edit.TabOrder := 3;
    Add1Edit.Text := 'Add1Edit';
    Add1Edit.WidthStyle := ssAuto;
    Add1Edit.WidthPercent := 100.000000000000000000;
    SetEvent(Add1Edit, Self, 'OnExit', 'Add1EditExit');
    Add1Edit.DataField := 'Address1';
    Add1Edit.DataSource := CustomerSource;
    Add2Edit.SetParentComponent(Self);
    Add2Edit.Name := 'Add2Edit';
    Add2Edit.Tag := 5;
    Add2Edit.Left := 72;
    Add2Edit.Top := 277;
    Add2Edit.Width := 121;
    Add2Edit.Height := 22;
    Add2Edit.ChildOrder := 7;
    Add2Edit.ElementClassName := 'form-control';
    Add2Edit.ElementFont := efCSS;
    Add2Edit.ElementPosition := epIgnore;
    Add2Edit.HeightStyle := ssAuto;
    Add2Edit.HeightPercent := 100.000000000000000000;
    Add2Edit.MaxLength := 25;
    Add2Edit.TabOrder := 4;
    Add2Edit.Text := 'Add2Edit';
    Add2Edit.WidthStyle := ssAuto;
    Add2Edit.WidthPercent := 100.000000000000000000;
    Add2Edit.DataField := 'Address2';
    Add2Edit.DataSource := CustomerSource;
    TownEdit.SetParentComponent(Self);
    TownEdit.Name := 'TownEdit';
    TownEdit.Left := 72;
    TownEdit.Top := 327;
    TownEdit.Width := 121;
    TownEdit.Height := 22;
    TownEdit.ChildOrder := 8;
    TownEdit.ElementClassName := 'form-control';
    TownEdit.ElementFont := efCSS;
    TownEdit.ElementPosition := epIgnore;
    TownEdit.HeightStyle := ssAuto;
    TownEdit.HeightPercent := 100.000000000000000000;
    TownEdit.MaxLength := 25;
    TownEdit.TabOrder := 6;
    TownEdit.Text := 'TownEdit';
    TownEdit.WidthStyle := ssAuto;
    TownEdit.WidthPercent := 100.000000000000000000;
    SetEvent(TownEdit, Self, 'OnExit', 'TownEditExit');
    TownEdit.DataField := 'Address4';
    TownEdit.DataSource := CustomerSource;
    PostcodeEdit.SetParentComponent(Self);
    PostcodeEdit.Name := 'PostcodeEdit';
    PostcodeEdit.Left := 72;
    PostcodeEdit.Top := 355;
    PostcodeEdit.Width := 121;
    PostcodeEdit.Height := 22;
    PostcodeEdit.CharCase := wecUpperCase;
    PostcodeEdit.ChildOrder := 9;
    PostcodeEdit.ElementClassName := 'form-control';
    PostcodeEdit.ElementFont := efCSS;
    PostcodeEdit.ElementPosition := epIgnore;
    PostcodeEdit.HeightStyle := ssAuto;
    PostcodeEdit.HeightPercent := 100.000000000000000000;
    PostcodeEdit.MaxLength := 9;
    PostcodeEdit.TabOrder := 5;
    PostcodeEdit.Text := 'PostcodeEdit';
    PostcodeEdit.WidthStyle := ssAuto;
    PostcodeEdit.WidthPercent := 100.000000000000000000;
    SetEvent(PostcodeEdit, Self, 'OnExit', 'PostcodeEditExit');
    PostcodeEdit.DataField := 'PostCode';
    PostcodeEdit.DataSource := CustomerSource;
    Add3Edit.SetParentComponent(Self);
    Add3Edit.Name := 'Add3Edit';
    Add3Edit.Left := 72;
    Add3Edit.Top := 302;
    Add3Edit.Width := 121;
    Add3Edit.Height := 22;
    Add3Edit.ChildOrder := 10;
    Add3Edit.ElementClassName := 'form-control';
    Add3Edit.ElementFont := efCSS;
    Add3Edit.ElementPosition := epIgnore;
    Add3Edit.HeightStyle := ssAuto;
    Add3Edit.HeightPercent := 100.000000000000000000;
    Add3Edit.MaxLength := 25;
    Add3Edit.TabOrder := 5;
    Add3Edit.Text := 'Add3Edit';
    Add3Edit.WidthStyle := ssAuto;
    Add3Edit.WidthPercent := 100.000000000000000000;
    Add3Edit.DataField := 'Address3';
    Add3Edit.DataSource := CustomerSource;
    MobileEdit.SetParentComponent(Self);
    MobileEdit.Name := 'MobileEdit';
    MobileEdit.Left := 72;
    MobileEdit.Top := 423;
    MobileEdit.Width := 121;
    MobileEdit.Height := 22;
    MobileEdit.ChildOrder := 2;
    MobileEdit.ElementClassName := 'form-control';
    MobileEdit.ElementFont := efCSS;
    MobileEdit.ElementPosition := epIgnore;
    MobileEdit.HeightStyle := ssAuto;
    MobileEdit.HeightPercent := 100.000000000000000000;
    MobileEdit.MaxLength := 25;
    MobileEdit.TabOrder := 8;
    MobileEdit.Text := 'MobileEdit';
    MobileEdit.WidthStyle := ssAuto;
    MobileEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MobileEdit, Self, 'OnExit', 'MobileEditExit');
    MobileEdit.DataField := 'Mobile';
    MobileEdit.DataSource := CustomerSource;
    EmailEdit.SetParentComponent(Self);
    EmailEdit.Name := 'EmailEdit';
    EmailEdit.Left := 72;
    EmailEdit.Top := 451;
    EmailEdit.Width := 121;
    EmailEdit.Height := 22;
    EmailEdit.ChildOrder := 3;
    EmailEdit.ElementClassName := 'form-control';
    EmailEdit.ElementFont := efCSS;
    EmailEdit.ElementPosition := epIgnore;
    EmailEdit.HeightStyle := ssAuto;
    EmailEdit.HeightPercent := 100.000000000000000000;
    EmailEdit.MaxLength := 75;
    EmailEdit.TabOrder := 9;
    EmailEdit.Text := 'EmailEdit';
    EmailEdit.WidthStyle := ssAuto;
    EmailEdit.WidthPercent := 100.000000000000000000;
    SetEvent(EmailEdit, Self, 'OnExit', 'EmailEditExit');
    EmailEdit.DataField := 'Email';
    EmailEdit.DataSource := CustomerSource;
    LastNameEdit.SetParentComponent(Self);
    LastNameEdit.Name := 'LastNameEdit';
    LastNameEdit.Left := 72;
    LastNameEdit.Top := 144;
    LastNameEdit.Width := 121;
    LastNameEdit.Height := 22;
    LastNameEdit.ChildOrder := 1;
    LastNameEdit.ElementClassName := 'form-control';
    LastNameEdit.ElementFont := efCSS;
    LastNameEdit.ElementPosition := epIgnore;
    LastNameEdit.HeightStyle := ssAuto;
    LastNameEdit.HeightPercent := 100.000000000000000000;
    LastNameEdit.MaxLength := 30;
    LastNameEdit.TabOrder := 2;
    LastNameEdit.Text := 'LastNameEdit';
    LastNameEdit.WidthStyle := ssAuto;
    LastNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(LastNameEdit, Self, 'OnExit', 'LastNameEditExit');
    LastNameEdit.DataField := 'Surname';
    LastNameEdit.DataSource := CustomerSource;
    FirstNameEdit.SetParentComponent(Self);
    FirstNameEdit.Name := 'FirstNameEdit';
    FirstNameEdit.Left := 72;
    FirstNameEdit.Top := 116;
    FirstNameEdit.Width := 121;
    FirstNameEdit.Height := 22;
    FirstNameEdit.ChildOrder := 11;
    FirstNameEdit.ElementClassName := 'form-control';
    FirstNameEdit.ElementFont := efCSS;
    FirstNameEdit.ElementPosition := epIgnore;
    FirstNameEdit.HeightStyle := ssAuto;
    FirstNameEdit.HeightPercent := 100.000000000000000000;
    FirstNameEdit.MaxLength := 25;
    FirstNameEdit.TabOrder := 1;
    FirstNameEdit.Text := 'FirstNameEdit';
    FirstNameEdit.WidthStyle := ssAuto;
    FirstNameEdit.WidthPercent := 100.000000000000000000;
    SetEvent(FirstNameEdit, Self, 'OnExit', 'FirstnameEditExit');
    FirstNameEdit.DataField := 'Forename';
    FirstNameEdit.DataSource := CustomerSource;
    TitleEdit.SetParentComponent(Self);
    TitleEdit.Name := 'TitleEdit';
    TitleEdit.Left := 72;
    TitleEdit.Top := 84;
    TitleEdit.Width := 145;
    TitleEdit.Height := 26;
    TitleEdit.ElementClassName := 'form-select form-control';
    TitleEdit.ElementFont := efCSS;
    TitleEdit.ElementPosition := epIgnore;
    TitleEdit.HeightStyle := ssAuto;
    TitleEdit.HeightPercent := 100.000000000000000000;
    TitleEdit.Text := 'TitleEdit';
    TitleEdit.WidthPercent := 100.000000000000000000;
    TitleEdit.ItemIndex := -1;
    TitleEdit.Items.BeginUpdate;
    try
      TitleEdit.Items.Clear;
      TitleEdit.Items.Add('Mr');
      TitleEdit.Items.Add('Mrs');
      TitleEdit.Items.Add('Ms');
      TitleEdit.Items.Add('Mx');
      TitleEdit.Items.Add('Dr');
    finally
      TitleEdit.Items.EndUpdate;
    end;
    TitleEdit.DataField := 'Title';
    TitleEdit.DataSource := CustomerSource;
    PolicySource.DataSet := MainData.PolicyData;
    CustomerSource.DataSet := MainData.CustomerData;
    CustomerSource.Left := 80;
    CustomerSource.Top := 24;
  finally
    Add1Edit.AfterLoadDFMValues;
    Add2Edit.AfterLoadDFMValues;
    TownEdit.AfterLoadDFMValues;
    PostcodeEdit.AfterLoadDFMValues;
    Add3Edit.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    FirstNameEdit.AfterLoadDFMValues;
    TitleEdit.AfterLoadDFMValues;
    PolicySource.AfterLoadDFMValues;
    CustomerSource.AfterLoadDFMValues;
  end;
end;

End.
