unit ErrorPage;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WebForm.Core, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TGeneralErrorForm = class(TCoreWebForm)
    ErrorMessageLabel: TLabel;
  private
    procedure SetErrorMessage(const Value: string);
    { Private declarations }
  public
    { Public declarations }
    property ErrorMessage: string write SetErrorMessage;
  protected procedure LoadDFMValues; override; end;

var
  GeneralErrorForm: TGeneralErrorForm;

implementation

{$R *.dfm}

{ TGeneralErrorForm }

procedure TGeneralErrorForm.SetErrorMessage(const Value: string);
begin
  ErrorMessageLabel.Caption := Value;
end;

procedure TGeneralErrorForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ErrorMessageLabel := TLabel.Create('ErrorMessage');

  ErrorMessageLabel.BeforeLoadDFMValues;
  try
    ErrorMessageLabel.SetParentComponent(Self);
    ErrorMessageLabel.Name := 'ErrorMessageLabel';
    ErrorMessageLabel.Left := 152;
    ErrorMessageLabel.Top := 176;
    ErrorMessageLabel.Width := 99;
    ErrorMessageLabel.Height := 15;
    ErrorMessageLabel.Caption := 'ErrorMessageLabel';
    ErrorMessageLabel.HeightPercent := 100.000000000000000000;
    ErrorMessageLabel.WidthPercent := 100.000000000000000000;
  finally
    ErrorMessageLabel.AfterLoadDFMValues;
  end;
end;

end.