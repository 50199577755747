{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit Gadget.Web.Utils;

interface

uses System.Rtti, Gadget.Enumerations;


type

TGadgetUtils = class
public
  Class Function AffiliateKindToStr(const Value: TAffiliateKind): string;
  Class Function SessionKindToStr(const Value: TSessionKind): string;

  Class function StrToBrandOfferKind(const Value: String): TBrandOfferKind;
  Class function BrandOfferKindToStr(const Value: TBrandOfferKind): String;

  Class Function PremiumKindToStr(const Value: TPremiumKind): string;
  Class Function StrToPremiumKind(const Value: String): TPremiumKind;
  Class Function GadgetTypeToStr(const Value: TGadgetType): String;
  Class Function StrToGadgetType(Value: String): TGadgetType;




  Class Function ValidateIMEI(const Value: String): Boolean;
  Class function LuhnAlgorithmCheckDigit(const s:string): integer;
  Class function isLuhnValid( const s : string ): boolean;

end;

implementation

uses System.SysUtils, SMX.Web.Utils;

{ TGadgetUtils }

class function TGadgetUtils.AffiliateKindToStr(const Value: TAffiliateKind): string;
begin
  Result := TRttiEnumerationType.GetName<TAffiliateKind>(Value);
end;

class function TGadgetUtils.BrandOfferKindToStr(const Value: TBrandOfferKind): String;
begin
  Result := TRttiEnumerationType.GetName<TBrandOfferKind>(Value);
end;

class function TGadgetUtils.GadgetTypeToStr(const Value: TGadgetType): String;
begin
  Result := TRttiEnumerationType.GetName<TGadgetType>(Value);
end;

class function TGadgetUtils.isLuhnValid(const s: string): boolean;
var
  i, l :integer;
begin
  l := length( s );
  i := LuhnAlgorithmCheckDigit( copy( s, 1, l-1) );
  result :=  i  = StrToInt( s[ l ] );
end;

class function TGadgetUtils.LuhnAlgorithmCheckDigit(const s: string): integer;
var
  l, i, sum, deo : integer;
begin
  l := length( s );
  sum := 0;
  for i := l downto 1 do
    begin
      if odd(i) then sum := sum + StrToInt(s[i])
      else
        begin
          deo :=  StrToInt(s[i]) * 2 ;
          if deo  > 9 then deo := deo mod 10 + 1;
          sum := sum + deo;
        end;
    end;
  result := 10 - sum mod 10;
end;

class function TGadgetUtils.PremiumKindToStr(const Value: TPremiumKind): string;
begin
    Result := TRttiEnumerationType.GetName<TPremiumKind>(Value);
end;

class function TGadgetUtils.SessionKindToStr(const Value: TSessionKind): string;
begin
 Result := TRttiEnumerationType.GetName<TSessionKind>(Value);
end;

class function TGadgetUtils.StrToBrandOfferKind(const Value: String): TBrandOfferKind;
begin
  Result := TRttiEnumerationType.GetValue<TBrandOfferKind>(Value);
end;

class function TGadgetUtils.StrToGadgetType(Value: String): TGadgetType;
begin
  Result := TRttiEnumerationType.GetValue<TGadgetType>(Value);
end;


class function TGadgetUtils.StrToPremiumKind(const Value: String): TPremiumKind;
begin
  Result := TRttiEnumerationType.GetValue<TPremiumKind>(Value);
end;

class function TGadgetUtils.ValidateIMEI(const Value: String): Boolean;
begin
  Result := False;
  If Value.Length > 16 then
     Exit;
  if not TUtils.IsAllDigits(Value) then
     Exit;
  Result := isLuhnValid(Value.Substring(0, 15));
end;

end.
