{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit SMX.Auth.Shared;

interface

uses
  Sphinx.Consts;

{$SCOPEDENUMS ON}

const
  CLAIM_FIRSTNAME = JwtClaimNames.GivenName; // 'given_name';
  CLAIM_LASTNAME = JwtClaimNames.FamilyName;
  CLAIM_FULLNAME = JwtClaimNames.Name; // 'name';

  //This is value in the JWT that maps to the UserId in the Sphinx User Table (a lowercase GUID)
  CLAIM_USER_ID = 'sub';

  //This is the value that maps to the Internal Id (Integer)
  CLAIM_PERSONID = 'personid';
  CLAIM_DATA_COMPLETE = 'datacomplete';
  CLAIM_LOCATIONTYPE = 'locationtype';
  CLAIM_SCOPE = 'scope';
  CLAIM_ROLE = 'role';
  CLAIM_TENANT_ID = 'tenant-id';  //DO NOT CHANGE THIS IS USED BY XData!!!//
  CLAIM_ORGANISATION_ID = 'org-id';
  CLAIM_ORGANISATION_NAME = 'org-name';
  CLAIM_ORGANISATION_TENANT_ID = 'org-tenant-id';

  ORGANISATION_NOT_CREATED = 'not-created';
  ORGANISATION_SETUP_PENDING = 'setup-pending';
  ORGANISATION_MULTI_TENANT = 'multi-tenant';

  HEADER_TENANT_ID = 'tenant-id';

  //This is added to the Id Token by Sphinx as "email"
  CLAIM_EMAIL = 'email';

  SCOPE_NONE = 'None';
  SCOPE_READ_ONLY = 'ReadOnly';
  SCOPE_USER = 'User';
  SCOPE_ADMIN_USER = 'AdminUser';
  SCOPE_ADMIN = 'Administrator';
  SCOPE_SUPERUSER = 'SuperUser';
  SCOPE_CUSTOMER = 'Customer';
  SCOPE_ALL_USERS = SCOPE_USER + ',' + SCOPE_ADMIN_USER + ',' +  SCOPE_ADMIN + ',' +  SCOPE_SUPERUSER;

  YesNo: array[Boolean] of string = ('No', 'Yes');

implementation

{$SCOPEDENUMS OFF}

end.
