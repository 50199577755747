Unit Gadget.Web.Data.Utils;

Interface

Uses
  Gadget.Enumerations,
  Gadget.ReturnTypes;

Type

  TDataUtils = Class
    Class Procedure CalcPremiumValues(Const APremiumKind: TPremiumKind; APremium: TPremium); Overload;
    Class Procedure CalcPremiumValues(Const IncludeLossCover: Boolean); Overload;
    Class Function QuantityDiscount: Double;
    class function CalcDisplayedPremium(const APremiumKind: TPremiumKind; APremium: TPremium;
      const IncludesLossCover: Boolean; const ADiscountRate: Double): Double;
  End;

Implementation

Uses
  System.Math,
  MainDataModule,
  XData.Dataset.Utils,
  Gadget.Web.Utils,
  Gadget.Web.Consts;

{ TWebDataUtils }

Class Procedure TDataUtils.CalcPremiumValues(Const APremiumKind: TPremiumKind; APremium: TPremium);
Begin
  TXDataDSUtils.DatasetEdit(MainData.PolicyData);

  MainData.PolicyDataPremiumPeriod.Value := TGadgetUtils.PremiumKindToStr(APremiumKind);

  If APremiumKind = TPremiumKind.MonthlyCover Then
    MainData.PolicyDataPremium.Value := APremium.Premium
  Else
    MainData.PolicyDataPremium.Value := APremium.AnnualPremium;

  CalcPremiumValues(MainData.PolicyDataLossCover.Value);

  MainData.PolicyData.Post;

End;

class function TDataUtils.CalcDisplayedPremium(const APremiumKind: TPremiumKind; APremium: TPremium;
  const IncludesLossCover: Boolean; const ADiscountRate: Double): Double;
begin
  case APremiumKind of
    TPremiumKind.MonthlyCover:
      Result := APremium.Premium;
    TPremiumKind.AnnualCover:
      Result := APremium.AnnualPremium;
  end;

  If IncludesLossCover then
    Result := Result + MainData.LossCoverPremium(APremium.GadgetType, APremiumKind);

  if ADiscountRate > 0 then
     Result := RoundTo(Result * (1 - ADiscountRate / 100), -2);
end;

Class Procedure TDataUtils.CalcPremiumValues(Const IncludeLossCover: Boolean);
var
  lNetPremium, lGrossPremium, lDiscountRate: Double;
Begin

  TXDataDSUtils.DatasetEdit(MainData.PolicyData);

  If IncludeLossCover and (not(MainData.PolicyGadget in Gadget_Types_Loss_Excluded)) Then
  Begin
    MainData.PolicyDataLossCover.Value := True;
    MainData.PolicyDataLossPremium.Value := MainData.LossCoverPremium(MainData.PolicyGadget, MainData.ChosenPremiumKind);
  End
  Else
  Begin
    MainData.PolicyDataLossCover.Value := False;
    MainData.PolicyDataLossPremium.Value := 0.00;
  End;

  lDiscountRate := MainData.PolicyDataDiscountPercent.Value / 100;
  lGrossPremium := MainData.PolicyDataPremium.Value + MainData.PolicyDataLossPremium.Value;

  lNetPremium := RoundTo(lGrossPremium * (1 - lDiscountRate), -2);

  MainData.PolicyDataGrossPremium.Value := lGrossPremium;
  MainData.PolicyDataNetPremium.Value := lNetPremium;
End;

class function TDataUtils.QuantityDiscount: Double;
var
  lCount: Integer;
begin
  lCount := MainData.PolicyCount;
  if lCount <= 1 then
    Result := 0
  else if (lCount > 1) and (lCount < 4) then
    Result := 10
  else
    Result := 20;
end;

End.
