{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit smx.webcore.types;

interface

uses
  System.Generics.Collections,
  WEBLib.Forms,
  MainForm.Base;

type

  TShowAPopUpProc = procedure(AFormClass: TFormClass) of object;

  TSortDirection = (sdAsc, sdDesc);
  TSortPair = TPair<integer, TSortDirection>;
  TRefStatus = (RefEmpty, RefNotChecked, RefChecking, RefUnique, RefInUse);
  TViewOption = (voNone, voReadOnly, voEdit, voCreateAndEdit);
  TValueChangeState = (vcsNoChange, vcsValid, vcsInvalid);

const
  POPUP_FORM_CLASS = 'PopUpForm PopUp';
  LIST_ITEM_CLASS: Array[boolean] of string = ('page-item disabled', 'page-item active');

var
  TheMainForm: TMainBaseForm;

implementation

end.
