{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

program MyGadgetUmbrella;

uses
  Vcl.Forms,
  WEBLib.Forms,
  WebForm.Core in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\WebForm.Core.pas'{*.html},
  MainSitePage in 'MainSitePage.pas'{*.html},
  MainDataModule in 'MainDataModule.pas',
  BaseSitePage in 'BaseSitePage.pas'{*.html},
  SMX.Web.Utils in '..\..\SMXI-LIBS-2024\Web-Core-Utils\SMX.Web.Utils.pas',
  SMX.CRM.Utils in '..\..\SMXI-LIBS-2024\Web-Core-Utils\SMX.CRM.Utils.pas',
  CustomerPage in 'CustomerPage.pas'{*.html},
  SMX.Web.Document.Utils in '..\..\SMXI-LIBS-2024\Web-Core-Utils\SMX.Web.Document.Utils.pas',
  XData.Dataset.Utils in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\XData.Dataset.Utils.pas',
  SMX.Web.Table.Simple in '..\..\SMXI-LIBS-2024\Web-Core-Utils\SMX.Web.Table.Simple.pas',
  MainForm.Base in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\MainForm.Base.pas'{*.html},
  smx.webcore.types in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\smx.webcore.types.pas',
  SMX.Auth.Shared in '..\..\SMXI-LIBS-2024\XData-Core\Auth\SMX.Auth.Shared.pas',
  Dataset.Plugins in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\Dataset.Plugins.pas',
  Grid.Plugins in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\Grid.Plugins.pas',
  Grid.Settings in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\Grid.Settings.pas',
  Paginator.Plugins in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\Paginator.Plugins.pas',
  ErrorPage in 'ErrorPage.pas'{*.html},
  Gadget.ReturnTypes in '..\Shared\Gadget.ReturnTypes.pas',
  Gadget.Enumerations in '..\Server\Entities\Gadget.Enumerations.pas',
  Gadget.Web.Consts in 'Gadget.Web.Consts.pas',
  SMX.XDataSvc.Utils in '..\..\SMXI-LIBS-2024\Web-Core-Utils\SMX.XDataSvc.Utils.pas',
  SelectPage in 'SelectPage.pas'{*.html},
  Gadget.Web.Utils in 'Gadget.Web.Utils.pas',
  GadgetPage in 'GadgetPage.pas'{*.html},
  DirectDebitPage in 'DirectDebitPage.pas'{*.html},
  CardPaymentPage in 'CardPaymentPage.pas'{*.html},
  Gadget.Web.Data.Utils in 'Gadget.Web.Data.Utils.pas',
  ThankYouPage in 'ThankYouPage.pas'{*.html},
  SMX.Web.Layout.Utils in '..\..\SMXI-LIBS-2024\Web-Core-Utils\SMX.Web.Layout.Utils.pas',
  Web.Core.Config in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\Web.Core.Config.pas',
  Web.Core.Consts in '..\..\SMXI-LIBS-2024\XData-Core\Web-Core\Web.Core.Consts.pas',
  PaymentPage in 'PaymentPage.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TMainData, MainData);
  Application.Run;
end.
