{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit Grid.Settings;

interface

uses
  WEBLib.DBCtrls,
  XData.Web.Dataset;

type
  TOrderInfo = record
    FieldName: string;
    Ascending: Boolean;
  end;

  TSortedItems = array of TOrderInfo;

  TSearchOperator = (soEQ, soNE, soGT, soLT, soGTE, soLTE, soStarts, soContains);

  TSearchField = record
    FieldName: string;
    SearchOperator: TSearchOperator;
    FieldLen: Integer;
    CaseInsensitive: Boolean;
    IsNumber: Boolean;
    /// <summary>
    ///   This is different to CaseInsensitive (and takes precedence) in that
    ///   the field is known to be uppercase and does not need the field value
    ///   to be upper cased. This is usually an indexed field
    /// </summary>
    IsUpperCase: Boolean;
    constructor Create(const AFieldName: string; const ASearchOperator: TSearchOperator = soEQ; const AFieldLen: Integer
      = 0; const ACaseInsensitive: Boolean = False; const AIsNumber: Boolean = False; const AIsUpperCase: Boolean = False);
    constructor CreateIdSearch(const AFieldName: string);

  end;

  TSearchFields = array of TSearchField;

  TGridSettings = class
  private
    FGrid: TDBTableControl;
    FSortedItems: TSortedItems;
    FFilterText: string;
    FSearchFields: TSearchFields;
    FSpecificSearch: Boolean;
    FPreparedSearch: String;
  public
    constructor Create(AGrid: TDBTableControl; ASortedItems: TSortedItems;
        AFilterText: string; const ASearchFields: TSearchFields; Const
        APreparedSearch: String);
    destructor Destroy; override;
    property Grid: TDBTableControl read FGrid;
    property SortedItems: TSortedItems read FSortedItems;
    property FilterText: string read FFilterText;
    property SearchFields: TSearchFields read FSearchFields;
    property SpecificSearch: Boolean read FSpecificSearch;
    property PreparedSearch: String read FPreparedSearch;
  end;

const
  Operator_Text: array[TSearchOperator] of string = ('eq', 'ne', 'gt', 'lt', 'ge', 'le', 'startswith', 'contains');

implementation

{ TGridSettings }

constructor TGridSettings.Create(AGrid: TDBTableControl; ASortedItems:
    TSortedItems; AFilterText: string; const ASearchFields: TSearchFields;
    Const APreparedSearch: String);
begin
  FGrid := AGrid;
  FSortedItems := ASortedItems;
  FFilterText := AFilterText;
  FSearchFields := ASearchFields;
  FSpecificSearch := (Length(ASearchFields) > 0);
  FPreparedSearch := APreparedSearch;
end;

destructor TGridSettings.Destroy;
begin
  inherited;
end;

{ TSearchField }

constructor TSearchField.Create(const AFieldName: string; const ASearchOperator: TSearchOperator = soEQ; const AFieldLen:
  Integer = 0; const ACaseInsensitive: Boolean = False; const AIsNumber: Boolean = False; const AIsUpperCase: Boolean = False);
begin
  FieldName := AFieldName;
  SearchOperator := ASearchOperator;
  FieldLen := AFieldLen;
  CaseInsensitive := ACaseInsensitive;
  IsNumber := AIsNumber;
  IsUpperCase := AIsUpperCase;
end;

constructor TSearchField.CreateIdSearch(const AFieldName: string);
begin
  Create(AFieldName, soEQ, 0, False, True);
end;

end.

