{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

Unit MainSitePage;

Interface

Uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  WEBLib.Toast,
  Web.Core.Consts,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  WEBLib.WebCtrls,
  WEBLib.Lists,
  MainForm.Base,
  Gadget.Web.Consts,
  Gadget.Enumerations,
  WEBLib.ExtCtrls;

Type
  TMainSiteForm = Class(TMainBaseForm)
    Procedure WebFormBeforeUnload(Sender: TObject; Var AMessage: String);
  Private
    { Private declarations }
    FDisplayedPage: TGadgetPage;
    FWaitDirection: TNavDirection;
    [async]
    Procedure ShowForm(AFormClass: TFormClass; Const ADisplayedPage: TGadgetPage; Const ANavDirection: TNavDirection;
      AProc: TFormCreatedProc = Nil; AlwaysClose: Boolean = false); async; Reintroduce;
    Procedure MainPageUpdate(Sender: TObject);
    Procedure ChangeThePage(Const ANewPage: TGadgetPage; Const ANavDirection: TNavDirection);
  Protected
    [async]
    Procedure InitForm; async; Override;
    Procedure HashChange(Sender: TObject; AHash: String; Var Handled: Boolean);
    Procedure HandlePopState(State: TJSObject; Const AURL, AHash: String); Override;
    function GetDebugInfo: String; override;
  Public

    { Public declarations }
    Class Procedure DisplayPage(Const AElementId: String); Override;
    Procedure CloseMainPage; Override;
  protected procedure LoadDFMValues; override; End;

Var
  MainSiteForm: TMainSiteForm;

Implementation

{$R *.dfm}

Uses
  System.Rtti,
  WebForm.Core,
  BaseSitePage,
  CustomerPage,
  MainDataModule,
  ErrorPage,
  Gadget.ReturnTypes,
  SelectPage,
  GadgetPage,
  DirectDebitPage,
  ThankYouPage,
  CardPaymentPage;

Procedure TMainSiteForm.WebFormBeforeUnload(Sender: TObject; Var AMessage: String);
Begin
  Inherited;
  CloseDisplayedForm(True);
End;

Procedure TMainSiteForm.MainPageUpdate(Sender: TObject);
Begin

  If Not Assigned(MainSiteForm) Then
    Exit;

End;

Procedure TMainSiteForm.ChangeThePage(Const ANewPage: TGadgetPage; Const ANavDirection: TNavDirection);
Begin
{$IFNDEF RELEASE}
  UpdateDebugInfo;
{$ENDIF}
  Case ANewPage Of
    gpNoChange:
      ;
    gpMyGadget:
      ShowForm(TGadgetForm, TGadgetPage.gpMyGadget, ANavDirection, Nil, false);
    gpDirectDebit:
      ShowForm(TDirectDebitForm, TGadgetPage.gpDirectDebit, ANavDirection, Nil, false);
    gpCardPayment:
      ShowForm(TCardPaymentForm, TGadgetPage.gpCardPayment, ANavDirection, Nil, false);
    gpThankYou:
      ShowForm(TThankYouForm, TGadgetPage.gpThankYou, ANavDirection, Nil, false);
  End;
End;

Procedure TMainSiteForm.CloseMainPage;
Begin
  Inherited;

End;

Class Procedure TMainSiteForm.DisplayPage(Const AElementId: String);

  Procedure AfterCreate(AForm: TObject);
  Begin
    // TMainPage(AForm).SetLabels;
  End;

Begin
  If Not Assigned(MainSiteForm) Then
  Begin
    MainSiteForm := TMainSiteForm.CreateNew(AElementId, @AfterCreate);
  End;
End;

Procedure TMainSiteForm.HandlePopState(State: TJSObject; Const AURL, AHash: String);
Var
  AGadgetPage, ACurrentPage: TGadgetPage;
  lHash: String;
Begin
  If TBaseSiteForm(FDisplayedForm).PageHash = AHash Then
    Exit;

  if AHash.StartsWith('gp') then
  begin

    if FDisplayedPage = TGadgetPage.gpThankYou then
       Exit;

    AGadgetPage := TRttiEnumerationType.GetValue<TGadgetPage>(AHash);

    if AGadgetPage = FDisplayedPage then
       Exit;

    if (FDisplayedPage in [gpDirectDebit, gpCardPayment]) and (AGadgetPage = TGadgetPage.gpMyGadget) then
       ChangeThePage(TGadgetPage.gpMyGadget, TNavDirection.ndNone);

  end;

End;

Procedure TMainSiteForm.HashChange(Sender: TObject; AHash: String; Var Handled: Boolean);
//Var
//  ABookingPage: TGadgetPage;
//  lHash: String;
Begin
End;

Procedure TMainSiteForm.InitForm;
Var
  lName: String;
Begin
  Inherited;
  MainData.ErrorMessageProc := ErrorHandler;
  if MainData.PreSelect.SelectLevel = TSelectLevel.slModel then
     Caption := 'Gadget Insurance for ' + MainData.PreSelect.Model
  else
     Caption := Application_Title;
  // Application.OnHashChange := HashChange;

  ShowWaitMessage;

  { TODO : This will depend on the link? }
  ChangeThePage(TGadgetPage.gpMyGadget, TNavDirection.ndNone);

  // Case MainData.ProcessingState Of
  // TProcessingState.psStandard:;
  // Else
  // Begin
  // End;
  // End;

End;

Procedure TMainSiteForm.ShowForm(AFormClass: TFormClass; Const ADisplayedPage: TGadgetPage;
  Const ANavDirection: TNavDirection; AProc: TFormCreatedProc = Nil; AlwaysClose: Boolean = false);

{ The Process is
  Form Created
  AForm.WebFormCreate;
  AForm.DisplayingAs
  - Set CustomerType (Primary, Secondary)
  AForm.InitForm
  AForm.LoadBooking
}

[async]
  Procedure AfterFormCreated(AForm: TObject); async;
  Begin

    If AFormClass.InheritsFrom(TCoreWebForm) Then
      TCoreWebForm(AForm).OnSelfClose := OnFormSelfClose;

    If AFormClass.InheritsFrom(TBaseSiteForm) Then
    Begin
      TBaseSiteForm(AForm).NavDirection := ANavDirection;
      TBaseSiteForm(AForm).OnSitePageChange := MainPageUpdate;
      TBaseSiteForm(AForm).DoChangeSitePage := ChangeThePage;
      TBaseSiteForm(AForm).DisplayingAs := ADisplayedPage;
      Await(TBaseSiteForm(AForm).InitForm);
      TBaseSiteForm(AForm).AfterLoaded;
    End
    Else If AFormClass.InheritsFrom(TCoreWebForm) Then
      Await(TBaseSiteForm(AForm).InitForm);

    HideWaitMessage;


    If Assigned(AProc) Then
      AProc(AForm);

  End;

Begin
  // Note that MainPanel is no longer a component. This now just uses a div on the
  // template with that id.
  If Assigned(FDisplayedForm) And (FDisplayedForm.ClassType = AFormClass) And (AlwaysClose = false) Then
    Exit;

  If Not CloseDisplayedForm(True) Then
    Exit;

  FDisplayedPage := ADisplayedPage;

  Application.CreateForm(AFormClass, 'MainPanel', FDisplayedForm, @AfterFormCreated);

End;

function TMainSiteForm.GetDebugInfo: String;
begin
  Result := MainData.DebugInfo;
end;

procedure TMainSiteForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Cursor := crHandPoint;
  finally
  end;
end;

End.
