{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit WebForm.Core;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Web.Core.Consts;

type
  TCoreWebForm = class(TForm)
  private
    { Private declarations }
    FOnSelfClose: TNotifyEvent;
  protected
    FPopUpForm: TForm;
    procedure SelfClose;
    /// <summary>
    /// This calls the routine in MainForm
    /// </summary>
    /// <param name="AIcon">
    /// A FontAwesome icon such as 'fad fa-bug'
    /// </param>
    procedure ShowAToast(const ATitle, AMessage, AIcon: string);
    /// <summary>
    /// Calls the DisplayMessage method on the MainForm
    /// </summary>
    procedure DisplayMessage(const AMessage: string; DlgType: TMsgDlgType = mtInformation);
    /// <summary>
    /// Calls the ConfirmationRequest method on the MainForm
    /// </summary>
    [async]
    function ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons): TModalResult; async;
    [async]
    procedure ShowAPopUp(AFormClass: TFormClass); async;
    procedure ShowWaitMessage;
    procedure HideWaitMessage;
    Function IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean; Virtual;
    Function IsValidEx(Const AElementId: String; Const Highlight: THighlightOption): Boolean; Virtual;
    Procedure HighlightControl(Const ThisElementId, TargetElementId: String; Const AValid, Highlight: Boolean;
      Const UseAltScheme: Boolean = False);
    Procedure HighlightControlEx(Const ThisElementId, TargetElementId: String; Const AValid: Boolean; const Highlight: THighlightOption;
      Const UseAltScheme: Boolean = False);
    Procedure UpdateDebugInfo;
    Procedure LogToConsole(const Value: string);
  public
    { Public declarations }
     [async]
    Procedure InitForm; async; Virtual;
    function CanClose: boolean; virtual;
    procedure ClosePopUp; virtual;
    procedure TearDown; virtual;
    procedure AfterCreated; virtual;
    function PageHashName: string; virtual;
    property OnSelfClose: TNotifyEvent write FOnSelfClose;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses
  System.StrUtils,
  smx.webcore.types,
  MainDataModule, SMX.Web.Document.Utils;

{ TCoreWebForm }

procedure TCoreWebForm.AfterCreated;
begin

end;

function TCoreWebForm.CanClose: boolean;
begin
  Result := True;
end;

procedure TCoreWebForm.ClosePopUp;
begin
  if Assigned(FPopUpForm) then
  begin
    FPopUpForm.Close;
    FPopUpForm.Free;
    FPopUpForm := nil;
  end;
end;

function TCoreWebForm.ConfirmationRequest(const AMessage: string; DlgType: TMsgDlgType; Buttons: TMsgDlgButtons)
  : TModalResult;
begin
  Result := Await(TheMainForm.ConfirmationRequest(AMessage, DlgType, Buttons));
end;

procedure TCoreWebForm.DisplayMessage(const AMessage: string; DlgType: TMsgDlgType);
begin
  TheMainForm.DisplayMessage(AMessage, DlgType);
end;

procedure TCoreWebForm.HideWaitMessage;
begin
  TheMainForm.HideWaitMessage;
end;

procedure TCoreWebForm.HighlightControl(const ThisElementId, TargetElementId: String; const AValid, Highlight, UseAltScheme: Boolean);
Var
  lValidityState: TValidityState;
Begin
  If Highlight Or (ThisElementId = TargetElementId) Then
  Begin
    If UseAltScheme Then
      lValidityState := Alt_Valid_Check[AValid]
    Else
      lValidityState := Valid_Check[AValid];
    TDocUtils.setControlValidity(ThisElementId, lValidityState);
  End;
end;

procedure TCoreWebForm.HighlightControlEx(const ThisElementId, TargetElementId: String; const AValid: Boolean; const Highlight: THighlightOption;
  const UseAltScheme: Boolean);
Var
  lValidityState: TValidityState;
Begin
  If (Highlight = THighlightOption.hoAll) Or ((Highlight = THighlightOption.hoSelected) and (ThisElementId = TargetElementId)) Then
  Begin
    If UseAltScheme Then
      lValidityState := Alt_Valid_Check[AValid]
    Else
      lValidityState := Valid_Check[AValid];
    TDocUtils.setControlValidity(ThisElementId, lValidityState);
  End;
end;

procedure TCoreWebForm.InitForm;
begin
//
end;

function TCoreWebForm.IsValid(const AElementId: String; const HighlightControls: Boolean): Boolean;
begin
  Result := True;
end;

function TCoreWebForm.IsValidEx(const AElementId: String; const Highlight: THighlightOption): Boolean;
begin
  Result := True;
end;

procedure TCoreWebForm.LogToConsole(const Value: string);
begin
  TheMainForm.LogToConsole(Value);
end;

function TCoreWebForm.PageHashName: string;
begin
  Result := Self.ClassName.Substring(1);
end;

procedure TCoreWebForm.SelfClose;
begin
  if Assigned(FOnSelfClose) then
    FOnSelfClose(Self);
end;

procedure TCoreWebForm.ShowAPopUp(AFormClass: TFormClass);
{$IFDEF DEBUG}
var
  lClass: string;
begin
  lClass := AFormClass.ClassName;
{$ELSE}
begin
{$ENDIF}
  FPopUpForm := AFormClass.Create(Self);
  try
    FPopUpForm.Popup := True;
    Await(TForm, FPopUpForm.Load());
    FPopUpForm.ElementClassName := POPUP_FORM_CLASS;
    FPopUpForm.PopupOpacity := 0.5;
    Await(TModalResult, FPopUpForm.Execute);
  finally
    FPopUpForm.Free;
    FPopUpForm := nil;
  end;
end;

procedure TCoreWebForm.ShowAToast(const ATitle, AMessage, AIcon: string);
begin
  TheMainForm.ShowAToast(ATitle, AMessage, AIcon);
end;

procedure TCoreWebForm.ShowWaitMessage;
begin
  TheMainForm.ShowWaitMessage;
end;

procedure TCoreWebForm.TearDown;
begin
  // for descendants
end;

procedure TCoreWebForm.UpdateDebugInfo;
begin
  {$IFDEF DEBUG}
  TheMainForm.UpdateDebugInfo;
  {$ENDIF}
end;

procedure TCoreWebForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'CoreWebForm';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    ElementPosition := epIgnore;
  finally
  end;
end;

end.
