{**********************************************************************************
 * Copyright (C) 1990-2024 Systematic Marketing Ltd (SMXi Software)
 *
 * All code contained within this file is copyrighted and may only be used by
 * third parties with written permission from Systematic Marketing Ltd.
 *
 * For permissions, please contact us at: info@smxi.com
 * Visit our website for more information: https://smxi.com/codecopyright.html
 **********************************************************************************}

unit DirectDebitPage;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  PaymentPage,
  Data.DB,
  WEBLib.DB,
  Vcl.StdCtrls,
  WEBLib.StdCtrls,
  Vcl.Controls,
  WEBLib.DBCtrls,
  WEBLib.ExtCtrls,
  Vcl.Mask,
  WEBLib.Mask,
  WEBLib.WebCtrls,
  WEBLib.Grids;

type
  TDirectDebitForm = class(TPaymentForm)
    AccountName: TEdit;
    AccountNumber: TEdit;
    SortCode: TEdit;
    procedure AccountNameExit(Sender: TObject);
    procedure AccountNumberChange(Sender: TObject);
    procedure PaybyAltLabelClick(Sender: TObject);
    procedure SortCodeChange(Sender: TObject);
  private
    { Private declarations }

    [async]
    Procedure RecordDirectDebit; async;

  protected
    Function IsValid(Const AElementId: String; Const HighlightControls: Boolean): Boolean; Override;
    [async]
    Procedure PayNow; async; override;
  public
    { Public declarations }
    [async]
    Procedure InitForm; async; override;
  protected procedure LoadDFMValues; override; end;

var
  DirectDebitForm: TDirectDebitForm;

implementation

{$R *.dfm}

uses
  MainDataModule,
  WEBLib.WebTools,
  Gadget.Web.Consts,
  SMX.XDataSvc.Utils,
  XData.Dataset.Utils,
  SMX.Web.Document.Utils,
  SMX.Web.Utils;

procedure TDirectDebitForm.AccountNameExit(Sender: TObject);
begin
  if not PageLoading then
    IsValid(AccountName.ElementID, False);
end;

procedure TDirectDebitForm.AccountNumberChange(Sender: TObject);
var
  lLen: Integer;
begin
  lLen := Length(AccountNumber.Text);
  if (lLen = 8) and (not PageLoading) then
    IsValid(AccountNumber.ElementID, False);
end;


{ TDirectDebitForm }

procedure TDirectDebitForm.InitForm;
begin
  LogToConsole('Init DirectDebit Start');
  inherited;
  LogToConsole('Init DirectDebit End');
end;

function TDirectDebitForm.IsValid(const AElementId: String; const HighlightControls: Boolean): Boolean;
var
  ControlValid: Boolean;
begin

  LogToConsole('IsValid called from ' + AElementId);

  Result := inherited IsValid(AElementId, HighlightControls);

  ControlValid := TUtils.IsAccountNumberValid(AccountNumber.Text);
  Result := Result and ControlValid;
  HighlightControl(AccountNumber.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := TUtils.IsSortCodeValid(SortCode.Text);
  Result := Result and ControlValid;
  HighlightControl(SortCode.ElementID, AElementId, ControlValid, HighlightControls);

  ControlValid := Trim(AccountName.Text) <> '';
  Result := Result and ControlValid;
  HighlightControl(AccountName.ElementID, AElementId, ControlValid, HighlightControls);

end;

procedure TDirectDebitForm.PaybyAltLabelClick(Sender: TObject);
begin
  ChangeSitePage(TGadgetPage.gpCardPayment);
end;

procedure TDirectDebitForm.PayNow;
begin
  inherited;
  RecordDirectDebit;
end;

procedure TDirectDebitForm.RecordDirectDebit;
begin
  TXDataDSUtils.DatasetEdit(MainData.DirectDebit);

  MainData.DirectDebitAccountName.Value := AccountName.Text;
  MainData.DirectDebitAccountNumber.Value := AccountNumber.Text;
  MainData.DirectDebitSortCode.Value := TUtils.CleanSortCode(SortCode.Text);
  MainData.DirectDebit.Post;
  Await(MainData.SaveDirectDebit);

  ChangeSitePage(TGadgetPage.gpThankYou);
end;

procedure TDirectDebitForm.SortCodeChange(Sender: TObject);
var lText: string;
begin
  lText := SortCode.Text;
  lText := lText.Replace(' ', '', [rfReplaceAll]).Replace('-', '', [rfReplaceAll]);
  if (lText.Length = 6) and (not PageLoading) then
     IsValid(SortCode.ElementID, False);
end;

procedure TDirectDebitForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AccountName := TEdit.Create('AccountName');
  AccountNumber := TEdit.Create('AccountNumber');
  SortCode := TEdit.Create('SortCode');

  PaybyAltLabel.BeforeLoadDFMValues;
  AddMoreGadgets.BeforeLoadDFMValues;
  AccountName.BeforeLoadDFMValues;
  AccountNumber.BeforeLoadDFMValues;
  MobileEdit.BeforeLoadDFMValues;
  EmailEdit.BeforeLoadDFMValues;
  LastNameEdit.BeforeLoadDFMValues;
  MarketingOKPanel.BeforeLoadDFMValues;
  MarketingOK.BeforeLoadDFMValues;
  PlaceOrderButton.BeforeLoadDFMValues;
  TermsOK.BeforeLoadDFMValues;
  SortCode.BeforeLoadDFMValues;
  try
    Caption := 'False';
    PaybyAltLabel.Left := 232;
    PaybyAltLabel.Top := 583;
    PaybyAltLabel.Width := 131;
    PaybyAltLabel.Caption := 'Pay by Card instead';
    SetEvent(PaybyAltLabel, Self, 'OnClick', 'PaybyAltLabelClick');
    AddMoreGadgets.Left := 248;
    AddMoreGadgets.Top := 640;
    AccountName.SetParentComponent(Self);
    AccountName.Name := 'AccountName';
    AccountName.Left := 72;
    AccountName.Top := 491;
    AccountName.Width := 121;
    AccountName.Height := 22;
    AccountName.ChildOrder := 9;
    AccountName.ElementClassName := 'form-control';
    AccountName.ElementFont := efCSS;
    AccountName.ElementPosition := epIgnore;
    AccountName.HeightStyle := ssAuto;
    AccountName.HeightPercent := 100.000000000000000000;
    AccountName.TabOrder := 10;
    AccountName.WidthStyle := ssAuto;
    AccountName.WidthPercent := 100.000000000000000000;
    SetEvent(AccountName, Self, 'OnExit', 'AccountNameExit');
    AccountNumber.SetParentComponent(Self);
    AccountNumber.Name := 'AccountNumber';
    AccountNumber.Left := 71;
    AccountNumber.Top := 551;
    AccountNumber.Width := 121;
    AccountNumber.Height := 22;
    AccountNumber.ChildOrder := 9;
    AccountNumber.ElementClassName := 'form-control';
    AccountNumber.ElementFont := efCSS;
    AccountNumber.ElementPosition := epIgnore;
    AccountNumber.HeightStyle := ssAuto;
    AccountNumber.HeightPercent := 100.000000000000000000;
    AccountNumber.MaxLength := 8;
    AccountNumber.TabOrder := 12;
    AccountNumber.WidthStyle := ssAuto;
    AccountNumber.WidthPercent := 100.000000000000000000;
    SetEvent(AccountNumber, Self, 'OnChange', 'AccountNumberChange');
    SetEvent(MobileEdit, Self, 'OnExit', 'nil');
    SetEvent(EmailEdit, Self, 'OnExit', 'nil');
    SetEvent(LastNameEdit, Self, 'OnExit', 'nil');
    MarketingOKPanel.Left := 303;
    MarketingOKPanel.Top := 468;
    MarketingOK.Left := -1;
    MarketingOK.TabOrder := 13;
    PlaceOrderButton.Left := 232;
    PlaceOrderButton.Top := 552;
    PlaceOrderButton.TabOrder := 15;
    TermsOK.Left := 302;
    TermsOK.Top := 507;
    TermsOK.TabOrder := 14;
    SortCode.SetParentComponent(Self);
    SortCode.Name := 'SortCode';
    SortCode.Left := 72;
    SortCode.Top := 523;
    SortCode.Width := 121;
    SortCode.Height := 22;
    SortCode.ChildOrder := 9;
    SortCode.ElementClassName := 'form-control';
    SortCode.ElementFont := efCSS;
    SortCode.ElementPosition := epIgnore;
    SortCode.HeightStyle := ssAuto;
    SortCode.HeightPercent := 100.000000000000000000;
    SortCode.MaxLength := 8;
    SortCode.TabOrder := 10;
    SortCode.WidthStyle := ssAuto;
    SortCode.WidthPercent := 100.000000000000000000;
    SetEvent(SortCode, Self, 'OnChange', 'SortCodeChange');
  finally
    PaybyAltLabel.AfterLoadDFMValues;
    AddMoreGadgets.AfterLoadDFMValues;
    AccountName.AfterLoadDFMValues;
    AccountNumber.AfterLoadDFMValues;
    MobileEdit.AfterLoadDFMValues;
    EmailEdit.AfterLoadDFMValues;
    LastNameEdit.AfterLoadDFMValues;
    MarketingOKPanel.AfterLoadDFMValues;
    MarketingOK.AfterLoadDFMValues;
    PlaceOrderButton.AfterLoadDFMValues;
    TermsOK.AfterLoadDFMValues;
    SortCode.AfterLoadDFMValues;
  end;
end;

end.
