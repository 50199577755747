Unit Gadget.Web.Consts;

Interface

Uses
  Gadget.Enumerations;

Type

  TGadgetPage = (gpNoChange, gpMyGadget, gpDirectDebit, gpCardPayment,
    gpThankYou);
  TSelectLevel = (slNone, slGadget, slMake, slModel);

  TButtonState = (wbsEnabled, wbsDisabled, wbsHidden, wbsNoChange);
  TNavDirection = (ndNone, ndForward, ndBackwards);
  TsmxRecordState = (rsNotApplicable, rsLoading, rsExisting, rsNew);
  TCanProgress = (ccNo, ccImmediate, ccWait);
  TProcessingState = (psStandard, psSaveForLater, psLoadingPolicy, psUpdatingPolicy, psSwitchToPayment,
    psPaymentProcessing, psOpeningData, psSavingData, psDeletingPolicy);
  TMainAppPage = (mpNone, mpSales, mpMyAccount);

  TOnReadyToProgress = Procedure(Const CanProgress: Boolean) Of Object;
  TOnSitePageChange = Procedure(Sender: TObject) Of Object; // really a TNotifyEvent, but may want to extend it
  TChangeSitePage = Procedure(Const ANewPage: TGadgetPage; Const ANavDirection: TNavDirection) Of Object;
  TAfterPaymentProcessed = Procedure(Const PaymentState: TPaymentState) Of Object;

  TAllDataRequired = Record
    Basket: Boolean;
    Customer: Boolean;
    Policy: Boolean;
    Procedure Clear;
    Function AllDone: Boolean;
  End;

  TSessionData = Record
    SessionId: Integer;
    PreviousSessionId: Integer;
    AffiliateId: Integer;
    AffiliateKind: TAffiliateKind;
    AffilateDiscount: Double;
    BrandCode: String;
    BrandOfferKind: TBrandOfferKind;
    BrandOffer: Double;
    BrandOfferStatus: TOfferStatus;
    BasketCode: String;
    BasketId: Integer;
    BasketSessionKind: TSessionKind;
    utm_source: String;
    utm_medium: String;
    utm_campaign: String;
    utm_content: String;
    utm_term: String;
    function NeedsData: Boolean;
    function CreateAtStart: Boolean;
    function SessionKind: TSessionKind;
  End;

  TSelectData = Record
    Gadget: TGadgetType;
    Make: String;
    Model: String;
    Outcome: String;
    Function SelectLevel: TSelectLevel;
    Function AsString: string;
  End;

Const
  Application_Title = 'Buy Gadget Insurance - My Gadget Umbrella';

  Crumb_No_Change = -1;
  Crumb_Page_One = 0;
  Crumb_Customer_Details = 2;

  Loss_Premium_Percent = 0.2;

  Affiliate_Id_Query_Field = 'src';
  Type_Query_Field = 'type';
  Make_Query_Field = 'make';
  Model_Query_Field = 'model';

Implementation

uses
  System.SysUtils,
  Gadget.Web.Utils;

{ TGadgetData }

Function TAllDataRequired.AllDone: Boolean;
Begin
  Result := Customer and Basket and Policy;
End;

{ TSelectData }

function TSelectData.AsString: string;
begin
  Result := '';
  if Gadget <> TGadgetType.None then
    Result := Result + 'gd=' + TGadgetUtils.GadgetTypeToStr(Gadget) + ';';
  if Make <> '' then
    Result := Result + 'mk=' + Make + ';';
  if Model <> '' then
    Result := Result + 'md=' + Model + ';';
  Result := Result.Trim([';']);
end;

Function TSelectData.SelectLevel: TSelectLevel;
Begin
  If Model <> '' Then
    Result := TSelectLevel.slModel
  Else If Make <> '' Then
    Result := TSelectLevel.slMake
  Else If Gadget <> TGadgetType.None Then
    Result := TSelectLevel.slGadget
  Else
    Result := TSelectLevel.slNone;
End;

{ TSessionData }

function TSessionData.CreateAtStart: Boolean;
begin
  Result := NeedsData or (BasketCode <> '') or (utm_source <> '') or (utm_medium <> '') or (utm_campaign <> '') or
    (utm_content <> '') or (utm_term <> '');
end;

function TSessionData.NeedsData: Boolean;
begin
  Result := (AffiliateId > 0) or (BrandCode <> '');
end;

procedure TAllDataRequired.Clear;
begin
  Basket := False;
  Customer := False;
  Policy := False;
end;

function TSessionData.SessionKind: TSessionKind;
begin
  if BasketCode <> '' then
    Result := BasketSessionKind
  else if PreviousSessionId > 0 then
    Result := TSessionKind.SessionContinued
  else
    Result := TSessionKind.New;
end;

End.
